.btn {
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 10px;
  transition: 0.3s;
  border-radius: 6px;
  border: 1px solid var(--white, #FFF);

  @include hover-focus {
    opacity: 0.9;
    color: inherit;
  }

  &--primary {
    background: #E66366;
    border: unset;

    @include hover-focus {
      color: inherit;
      background: #CA4D4F;
    }
  }

  &__label {
  }

  &__icon {
    margin-left: 7px;
  }
}
