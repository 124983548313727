html,
body {
  height: 100%;
  scroll-behavior: unset;
  scroll-padding-top: 20px;
}

@media (max-width: 768px) {
  html,
  body {
    scroll-padding-top: 120px;
  }
}

.btn-custom {
  display: inline-block;
  color: #4d2ede !important;
  background: #fefe54;
  border: none !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  padding: 12px 35px;
  line-height: 1;
  border-radius: 60px;
  box-shadow: 0 5px 17px 0px #120c2d;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  text-align: center;
  outline: none !important;
}
.js-feature-template {
  display: none;
}
.error-input {
  border: 1px solid red !important;
}
.struct-block-ins ul {
  padding: 0 0 0 14px;
}
.text-medium {
  font-size: 15px;
  margin: 7px 0;
}
.js-tfiles-render > div {
  position: relative;
  padding-right: 57px;
}
.cookie-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
  background: #6f46e0;
  z-index: 501;
  padding: 10px 0;
  color: #fff;
  border-top: 1px solid #fdf754;
}
a.course-block-sm.action-p-main:after {
  content: "Акция";
  position: absolute;
  right: -57px;
  top: -17px;
  color: rgb(255, 255, 255);
  transform: rotate(45deg);
  z-index: 10;
  background: rgb(249 57 155);
  padding: 51px 54px 7px;
  box-shadow: 0px 0px 5px 1px #000;
}
a.course-block-sm.action-p-main.action-p-soon:after {
  content: "Скоро";
  background: #fefe54;
  color: #6d30df;
}
::after {
}

a.course-block-sm.action-p-main {
  overflow: hidden;
}
.cookie-wrap > div {
  margin-top: 5px;
}

.cookie-wrap a {
  color: #fff !important;
  text-decoration: underline;
}
a.u-link {
  color: #fff;
  text-decoration: underline;
  text-align: right;
  -ms-word-break: break-all;
  word-break: break-all;
  display: block;
}

.js-tfiles-render .short-link {
  position: absolute;
  right: 0;
  color: #ffe100 !important;
  top: 50%;
  margin-top: -6px;
  cursor: pointer;
}

.struct-block-ins {
  padding: 0 15px 0 5px !important;
  font-size: 17px;
}

.short-link {
  color: #cf42ef;
  font-style: italic;
  font-size: 11px;
}

.sale-cart {
  color: red;
  font-weight: 600;
  margin-bottom: 7px;
}

.disabled-chat * {
  opacity: 0.4;
  color: #8e8c8c !important;
}

.disabled-chat {
  background: transparent !important;
  cursor: not-allowed;
}

.disabled-chat:before {
  content: "Доступ к чату истек";
  color: #cd4ddb;
  font-size: 12px;
  display: block;
}

input[name="PackageName"]::-webkit-input-placeholder,
input.pl-italic::-webkit-input-placeholder {
  font-style: italic !important;
}

input[name="PackageName"]::-moz-placeholder,
input.pl-italic::-moz-placeholder {
  font-style: italic !important;
}

input[name="PackageName"]:-moz-placeholder,
input.pl-italic:-moz-placeholder {
  font-style: italic !important;
}

input[name="PackageName"]:-ms-input-placeholder,
input.pl-italic:-ms-input-placeholder {
  font-style: italic !important;
}

.btn-custom.btn-custom-invert:hover {
  color: #4d2ede !important;
  background: #fefe54;
}

.plus-btn.plus-btn-white {
  color: #fff;
  text-decoration: none !important;
}

.start-date-alert {
  color: #8fffd6 !important;
}

.plus-btn.plus-btn-white:before {
  background: #fff;
  color: #7e32e0;
  box-shadow: none;
  font-weight: 400;
  border: 1px solid #fff;
  box-sizing: content-box;
  line-height: 43px;
  text-decoration: none !important;
}

.plus-btn.plus-btn-grey.plus-btn-archive:before {
  content: "\f07b";
  font-family: "Font Awesome 5 Free";
  font-size: 21px;
  padding-top: 1px;
  height: 44px;
}

.trs,
.btn-custom:hover {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  outline: none !important;
}

.plate-item-body a.js-fav-static.faved {
  background-image: url(../img/svg/bookmarked.svg) !important;
}

.plate-item__duration {
  display: flex;
  align-items: center;
  gap: 7px;
  /* position: absolute;
    top: 180px; */
}

.plate-item__duration span {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #272727;
}

.plate-item__tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 7px;
}

.plate-item .title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 76px;
  margin-bottom: 16px;
}

.plate-item .bot-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  margin-bottom: 20px;
}

.plate-item__tag {
  background: #f5f3f1;
  border-radius: 3px;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777777;
}

.plate-item-body a.js-fav-static {
  background-image: url(../img/svg/bookmark.svg) !important;
}

.no-trs {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.f-block {
  display: inline-block;
  min-width: 250px;
  background: #f2f2f2;
  text-align: center;
  padding: 45px 55px 60px;
  border-radius: 12px;
  box-shadow: 1px 2px 10px 0px #681fa7;
}

.f-block-body {
  color: #888888;
  font-family: "Conv_ofont.ru_Helvetica Neue";
  letter-spacing: 1px;
  font-weight: 700;
}

.js-slider-v-main .owl-stage,
.js-slider-v .owl-stage {
  display: flex;
  flex-direction: row;
  padding: 7px 0;
}

.js-slider-v-main.owl-carousel .owl-item {
  display: inline-flex !important;
  flex-direction: column;
}

.course-block-sm {
  display: flex;
  width: 243px;
  background: #eeeeee;
  padding: 12px;
  vertical-align: top;
  margin: 0 auto;
  border-radius: 7px;
  box-shadow: 0px 0 7px 1px #2d2d2d;
  color: #222222;
  position: relative;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  flex: 1 1 auto;
  flex-direction: column;
}

.course-preview {
  height: 145px;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 8px;
}

.f-block img {
  width: 76px;
}

.f-block-title {
  color: #444444;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 22px;
  margin: 15px 0 15px;
}

.f-block .btn-custom {
  box-shadow: 0px 2px 4px 0px #52238a;
  width: 200px;
  margin: 45px auto -79px;
  padding: 16px 0;
}

a.static-link {
  color: #fff;
  font-size: 15px;
}

.tab-block {
  display: none;
}

.tab-block.active {
  display: block;
}

.tabs-wrapper {
  font-size: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.tab-item {
  font-size: 15px;
  text-align: center;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  background: #ad54e5;
  color: #fff;
  font-size: 16px;
  padding: 11px 0;
  cursor: pointer;
  letter-spacing: 1px;
  opacity: 0.7;
}

.tab-item.active {
  background: #fefe54;
  color: #6d30df;
  opacity: 1;
}

.course-block-sm-name > div {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  white-space: inherit;
  text-overflow: ellipsis;
  width: 100%;
  color: #222222;
  font-size: 19px;
  letter-spacing: 0.5px;
  margin: 0;
  line-height: 1.2;
}

.course-block-sm:hover {
  color: #222222;
  text-decoration: none;
}

.scroll-content {
  -ms-overflow-style: none;
}

.scrollbar-inner > .scroll-element.scroll-x {
  opacity: 0;
}

.course-preview:before {
  content: "";
  position: absolute;
  width: 90%;
  height: 5px;
  background: #ccc;
  left: 5%;
  z-index: 0;
  border-radius: 0px 0px 15px 15px;
  bottom: -5px;
}

.modal-open .page-wrapper:after {
  border-right: 17px solid;
}

.course-preview:after {
  content: "";
  position: absolute;
  width: 80%;
  height: 5px;
  background: #dedede;
  left: 10%;
  z-index: 0;
  border-radius: 0px 0px 15px 15px;
  bottom: -10px;
}

.course-block-sm-name {
  padding: 0 6px;
  position: relative;
  overflow: hidden;
  margin: 23px 0 0;
  flex: 1 0 auto;
}

.nobg:after {
  display: none;
}

.course-block-sm-name > span {
  display: inline-block;
  width: 20px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding-left: 16px;
  z-index: 10;
  display: none;
}

.course-block-sm-name > span div {
  height: 4px;
  background: #888888;
  margin: 3px 0;
  border-radius: 11px;
}

.course-block-sm-name > span:hover + div {
  transform: translateX(-110%);
  overflow: visible;
  padding-right: 34px;
  -webkit-transition: all 5s;
  -o-transition: all 5s;
  transition: all 5s;
}

.form-control {
  background: #fff;
  font-size: 14px;
  outline: none !important;
  padding: 13px 15px;
  height: auto;
  border-radius: 45px;
}

a:hover,
a:active,
a:focus {
  outline: none;
}

.btn-custom:hover,
.btn-custom.btn-custom-invert {
  color: #fefe54 !important;
  background: #4d2ede;
  outline: none;
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.modal-body .table td,
.modal-body .table th {
  color: #fff;
}

main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 0;
}

body {
  /*font-family: 'Conv_HelveticaNeueCyr-Medium';*/
  font-family: "Futura PT";
  font-size: 16px;
}

.page-wrapper {
  position: relative;
  background-size: cover;
  background-position: left center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a.logo-link {
  display: block;
  height: 40px;
  width: 159px;
  background-image: url(../img/svg/logo.svg);
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.logo-container {
  margin-right: 71px;
}

.header__container {
  z-index: 3000;
  position: absolute;
  width: 100%;
  background: white;
}

.header__left {
  display: flex;
  width: 100%;
}

.header__contents {
  display: flex;
  max-width: 1360px;
  gap: 30px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
}

.header_controls-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  gap: 30px;
}

.header__flex .header__ratings {
  padding: 0;
}

.header__courses {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 177px;
  min-height: 40px;
  border: 1px solid #e66366;
  border-radius: 3px;
  margin-right: 30px;
  position: relative;
  font-weight: 450;
  line-height: 18px;
  outline: none;
  background: white;
  color: #e66166;
}

.header__courses:hover {
  background: #e66166;
  color: white;
}

.header__courses:hover .header__courses-burger {
  background-image: url(../img/svg/burger.svg);
}

.header__courses > .header__dropdown > li:first-of-type {
  font-weight: 450 !important;
  color: #e66165 !important;
}

.header__courses > .header__dropdown > li:first-of-type::after {
  content: "";
  width: 8px;
  height: 8px;
  border: solid #777777;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(135deg);
  position: absolute;
  left: 92%;
  top: 50%;
}

.header__courses > .header__dropdown > li:first-of-type::before {
  content: "";
  width: 8px;
  height: 8px;
  border: solid #777777;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(135deg);
  position: absolute;
  left: 94%;
  top: 50%;
}

.header__dropdown {
  padding-left: 0 !important;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 58px;
  left: 0px;
  z-index: 20;
  border: 1px solid #dbdbdb;
  background: white;
  width: 360px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.header__dropdown li:not(:first-of-type)::before {
  content: url(../img/svg/gradient.svg);
  position: absolute;
  left: 0px;
  top: 0px;
}

.header__dropdown > li:not(.mobile__back-btn) {
  width: 100%;
  display: flex !important;
  align-items: center;
  position: relative;
  line-height: 24px !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #272727 !important;
}

.header__dropdown > li > .header__dropdown {
  height: 0;
}

.header__dropdown > li > a {
  padding-left: 15px;
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.header__dropdown > li:hover {
  background-color: #f4f4f4;
}

.header__dropdown span {
  color: #777777 !important;
  font-weight: 400;
  font-size: 14px;
}

.mobile__back-btn {
  text-align: left;
  padding: 10px 0 20px 32px;
  color: #777777 !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 16px;
  position: static !important;
}

.mobile__back-btn::before {
  content: "";
  width: 6px;
  height: 6px;
  border: solid #777777;
  border-width: 1px 0 0 1px;
  transform: translate(-40%, -40%) rotate(315deg);
  position: absolute;
  left: 6%;
  top: 28px;
}

.header__dropdown::-webkit-scrollbar {
  display: none;
}

.header__dropdown > li .header__dropdown {
  padding-left: 40px !important;
}

@media (min-width: 768px) {
  .header__dropdown > li:hover > .header__dropdown,
  .header__dropdown > li.imitate-hover > .header__dropdown {
    visibility: visible;
    opacity: 1;
    position: absolute;
    left: 358px;
    width: 400px;
    max-height: 90vh;
    height: 100%;
    top: 80px !important;
    position: fixed;
  }

  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    top: -1px;
    left: 398px;
    display: none;
  }

  .catalog-container > .catalog-contents > .header__dropdown {
    height: 100%;
    overflow-y: auto;
    flex-wrap: nowrap !important;
  }
}

.header__dropdown
  > li
  > .header__dropdown
  > li
  > .header__dropdown
  > li
  > .header__dropdown {
  top: 34px !important;
  left: -1px !important;
}

@media (max-width: 1856px) {
  .header__dropdown {
    left: 0px;
  }

  .header__dropdown > li > .header__dropdown {
    left: 0px;
    width: 400px;
  }

  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    left: 398px;
  }

  .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown {
    left: -1px !important;
    top: 34px !important;
  }
}

@media (max-width: 1725px) {
  .header__dropdown {
    left: 0;
  }

  .header__dropdown > li > .header__dropdown {
    left: 0px;
    width: 400px;
  }

  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    left: 398px;
  }

  .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown {
    left: -1px !important;
    top: 34px !important;
  }
}

@media (max-width: 1520px) {
  .header__dropdown {
    left: 0px;
  }

  .header__dropdown > li > .header__dropdown {
    left: 0px;
    width: 400px;
  }

  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    left: 398px;
    top: 0px;
  }

  .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown {
    left: 0px !important;
    top: 34px !important;
  }
}

@media (max-width: 1440px) {
  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    width: 340px;
    top: -1px;
  }
  .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown {
    left: -1px !important;
    top: 34px !important;
  }
}

@media (max-width: 1440px) and (min-width: 1320px) {
  .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown {
    left: -1px !important;
  }
}

@media (max-width: 1320px) {
  .header__dropdown {
    left: 0;
  }

  .header__dropdown > li > .header__dropdown {
    left: 0px;
    width: 400px;
  }

  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    left: 398px;
    width: 266px;
  }
}

@media (max-width: 1200px) and (min-width: 1100px) {
  .header__dropdown {
    left: 0px;
  }
}

@media (max-width: 1200px) {
  .js-list-table > a::before {
    top: 38px !important;
  }

  .js-list-table > a::after {
    top: 38px !important;
  }
}

@media (max-width: 1100px) and (min-width: 768px) {
  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    left: 0;
    top: 34px;
  }
}

.header__courses
  > .header__dropdown
  > li
  > .header__dropdown
  > li.has-children::after,
.header__courses > .header__dropdown > li.root-has-children::after,
.header__courses
  > .header__dropdown
  > li
  > .header__dropdown
  > li
  > .header__dropdown
  > li.has-children::after {
  content: "";
  width: 8px;
  height: 8px;
  border: solid #777777;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(135deg);
  position: absolute;
  left: 94%;
  top: 50%;
}

.mobile-search-header {
  display: none;
  flex-direction: column;
  justify-content: center;
  background: white;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
}

#dropdown__close-btn {
  display: none !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  margin: 25px 0 80px;
  text-align: center;
  color: #272727;
}

.search__all > ul,
.search__courses > ul,
.search__schools > ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search__all > ul > li,
.search__courses > ul > li,
.search__schools > ul > li {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.search__all > ul > li > a,
.search__courses > ul > li > a,
.search__schools > ul > li > a {
  color: #272727 !important;
}

.search__all > ul > li:first-child {
  font-weight: 450 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #e66166 !important;
}

@media (max-width: 425px) {
  .js-list-table {
    width: 100vw !important;
    left: 0px !important;
    height: 90vh !important;
  }
}

@media (max-width: 998px) {
  .js-list-table {
    top: 61px !important;
    padding-left: 20px;
  }

  .search_mobile {
    display: block !important;
    width: 30px;
    height: 30px;
  }

  .header__search-wrapper {
    display: none;
  }

  .header__contents {
    width: 100%;
  }

  .header_controls-container {
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: -1px 6px 10px rgb(0 0 0 / 15%);
  }

  .btns {
    flex: 1;
  }

  .header__flex {
    align-items: center;
  }
}

@media (max-width: 768px) {
  .js-list-table {
    width: 100vw !important;
    left: 0;
    max-height: 100vh !important;
  }

  .js-list-table > a::after {
    top: 38px;
  }

  .js-list-table > a::before {
    top: 38px;
  }

  .header__container {
    position: fixed !important;
  }

  .header__courses
    > .header__dropdown
    > li
    > .header__dropdown
    > li.has-children::after,
  .header__courses > .header__dropdown > li.root-has-children::after,
  .header__courses
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li.has-children::after {
    left: 96%;
  }
}

@media (max-width: 460px) {
  .mobile__back-btn::before {
    position: absolute;
    left: 5.8% !important;
    top: 3.7%;
  }
}

.mobile-catalogue-border {
  border-bottom: 2px solid #e66165 !important;
  border-radius: 0px;
  padding-top: 2px !important;
}

/* .mobile-pages-border {
    border-bottom: 2px solid #E66165 !important;
    border-radius: 0px;
    padding-top: 9px !important;
    height: 40px;
} */

@media (max-width: 768px) {
  .js-list-table > ul > span {
    color: #777777 !important;
  }

  .header__dropdown {
    top: 150px !important;
    left: 0 !important;
    z-index: 200 !important;
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px;
    position: fixed !important;
    width: 100vw !important;
    height: 100%;
    max-height: calc(100% - 150px) !important;
    overflow-y: auto !important;
    box-shadow: inset 0px 4px 10px rgb(0 0 0 / 15%) !important;
  }

  .header__dropdown > li > a {
    padding-left: 0px;
  }

  .header__dropdown > li:first-of-type > a {
    font-weight: 600 !important;
  }

  .catalog-container .header__dropdown > li:first-of-type::before {
    top: 50%;
    left: 96%;
  }

  .catalog-container .header__dropdown > li:first-of-type::after {
    top: 50%;
    left: 94%;
  }

  .mobile__back-btn::before {
    position: absolute;
    left: 2.8%;
    top: 40px !important;
  }

  .header__dropdown li:not(:first-of-type)::before {
    content: none;
  }

  .header__dropdown li:not(:first-of-type),
  .header__dropdown li:not(:last-of-type) {
    border-bottom: 1px solid #dbdbdb8a;
  }

  .header__dropdown li:not(:first-of-type)::after {
    top: 50%;
  }

  .header__dropdown > li {
    width: 100%;
    line-height: 21px !important;
    font-size: 19px !important;
    font-weight: 400 !important;
  }

  .header__dropdown > li > a {
    line-height: 21px !important;
    font-size: 19px !important;
    font-weight: 400 !important;
  }

  .header__courses > .header__dropdown > li:first-of-type {
    font-weight: 450 !important;
    line-height: 18px !important;
  }

  .header__dropdown > li > a > span {
    line-height: 21px !important;
    font-size: 17px !important;
  }

  .header__dropdown > li > .header__dropdown {
    top: 100px;
    z-index: 200;
    padding-top: 0px;
    position: fixed;
    width: 100vw;
    height: 90vh;
    left: 0;
    padding-left: 20px !important;
  }

  .catalog-container .catalog-contents ul.header__dropdown {
    max-width: unset !important;
  }

  .catalog-container .header__dropdown > li:hover {
    background: none !important;
  }

  .header__search-wrapper form {
    margin: 0;
    background: #f3f3f3;
    border-radius: 5px;
    align-items: center;
    height: 40px;
    border: 0;
    flex-direction: unset;
  }

  .header__search-wrapper form input {
    background: #f3f3f3;
    font-size: unset;
    font-weight: 400;
  }

  .header__search-wrapper .search__btn {
    background: #f3f3f3 !important;
  }

  .header__search-wrapper .search__btn img {
    width: 15px;
    height: 20px;
    margin-bottom: 2px;
  }

  .header__search-wrapper.flex {
    display: block !important;
  }

  .header__search-wrapper .js-list-table {
    position: absolute !important;
    height: 100vh !important;
  }

  .js-list-table > ul {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header__search-wrapper .js-list-table > a {
    padding-left: 0px !important;
  }

  .header__dropdown > li > .header__dropdown > li > .header__dropdown {
    top: 100px;
    z-index: 200;
    padding-top: 0px;
    position: fixed;
    width: 100vw;
    height: 90vh;
    left: 0;
  }

  .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown {
    position: fixed;
    top: 100px !important;
    left: 0 !important;
  }

  #dropdown__close-btn {
    display: block !important;
    position: relative;
  }

  #dropdown__close-btn::after {
    content: "";
    width: 8px;
    height: 8px;
    border: solid #777777;
    border-width: 2px 0 0 2px;
    transform: translate(-40%, -40%) rotate(45deg);
    position: absolute;
    left: 64%;
    top: 14px;
  }

  .js-list-table > div > ul > li > a {
    font-size: 20px;
    line-height: 24px;
  }

  .js-list-table > div > ul > li > span {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  .header-menu > li > button:hover {
    border: none;
  }

  .header__courses > .header__dropdown > li:first-of-type::after {
    top: 50%;
    left: 94%;
  }

  .header__courses > .header__dropdown > li:first-of-type::before {
    top: 50%;
    left: 96%;
  }

  .ratings-dropdown-active ~ .ratings__dropdown {
    display: flex !important;
  }

  .toggle-submenu-btn {
    width: 100%;
    height: 46px;
    position: absolute;
    top: 0px;
    right: 0;
  }

  .toggle-submenu-btn:after {
    content: "";
    width: 10px;
    height: 10px;
    border: solid #777777;
    border-width: 2px 0 0 2px;
    transform: translate(-40%, -40%) rotate(225deg);
    position: absolute;
    right: 5%;
    top: 44%;
  }

  .hide-ratings-dropdown-btn {
    font-weight: 450;
    font-size: 19px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #777777;
    background: white;
    text-align: center;
    padding: 15px 0 85px;
  }

  .arrow-up.toggle-submenu-btn:after {
    transform: translate(-40%, -40%) rotate(45deg);
    top: 50%;
  }
}

@media (max-width: 500px) {
  .header__flex {
    align-items: unset;
  }

  .header_controls-container {
    justify-content: center;
    gap: 15px;
  }

  .header__left .header-menu {
    gap: 15px;
  }

  .header__left .header-menu.btns {
    padding-left: 0 !important;
  }

  .header__dropdown {
    height: 100%;
    max-height: calc(100% - 101px) !important;
  }

  #header__courses {
    border: none;
    background: white;
    color: #333333;
  }

  .user-link img {
    width: 30px !important;
    height: 30px !important;
  }

  .header__flex {
    align-items: center;
  }

  header .user-link a {
    margin-bottom: 0 !important;
  }

  .header__courses {
    border: none;
    gap: 5px;
    margin-right: 0px;
    min-width: unset;
    padding: 0;
  }

  .header__left .header-menu {
    justify-content: center !important;
    height: 40px !important;
    margin: 0;
  }

  html body main {
    margin-top: 70px !important;
  }

  .header__flex .header-menu {
    display: none;
  }

  .header {
    height: 60px;
    padding: 10px 20px !important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    margin-bottom: 41px;
    border-bottom: 0.5px solid #7373730f !important;
  }

  .header__courses-burger {
    background: url(../img/svg/burger-mobile.svg) !important;
  }

  .header__contents {
    gap: 0;
  }
}

main.margin-short {
  margin-top: 60px !important;
}

header ul.header-menu .ratings__dropdown li {
  width: auto;
}

.header__search-wrapper,
.header__form,
.header__form input {
  width: -webkit-fill-available;
}

.header__form .search__btn {
  pointer-events: none;
}

.dropdown-visibility-state {
  visibility: hidden !important;
}

.btn-active {
  border: 1.5px solid #e66366;
}

.dropdown-active {
  visibility: visible;
  opacity: 1 !important;
}

.catalog-container {
  position: fixed;
  background: white;
  width: 100vw;
  height: auto;
  left: 0px;
  top: 80px;
  bottom: 0;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  cursor: default;
  z-index: -1;
  overflow: auto;
}

.catalog-close-btn {
  position: absolute;
  top: 6%;
  right: 10%;
  cursor: pointer;
  z-index: 50;
}

.catalog-container > .catalog-contents > .header__dropdown {
  visibility: visible;
  opacity: 1;
  position: static;
  background: #f4f4f4;
  width: 100%;
  padding-left: 80px !important;
}

.catalog-container
  .header__dropdown
  > li
  > .header__dropdown
  > li
  > .header__dropdown
  > li
  > .header__dropdown {
  top: 0 !important;
  left: 395px !important;
}

@media (max-width: 1250px) and (min-width: 1100px) {
  .catalog-container
    .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown
    > li
    > .header__dropdown {
    left: 0px;
    top: 150px !important;
  }
}

@media (max-width: 1140px) and (min-width: 998px) {
  .header__search-wrapper {
    position: static !important;
    margin: 0 10px;
  }

  .header__search-wrapper .js-list-table {
    position: absolute !important;
    max-width: fit-content !important;
    border: 1px solid #333333 !important;
  }
}

.catalog-container .catalog-contents ul.header__dropdown {
  border: none;
  width: 100% !important;
  left: 395px !important;
  max-width: 395px;
  padding-top: 29px;
  padding-bottom: 29px;
  flex-wrap: wrap;
}

@media (max-height: 750px) {
  .catalog-container .catalog-contents ul.header__dropdown {
    max-width: 265px;
    left: 265px !important;
    padding-left: 20px !important;
  }

  /* .catalog-container > .catalog-contents > .header__dropdown > li > a {
        max-width: 225px;
    } */
}

@media (max-width: 768px) {
  div.catalog-container .catalog-contents ul.header__dropdown {
      max-width: unset !important;
      left: 0 !important;
      overflow-y: auto !important;
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
  }
}

.catalog-container .catalog-contents ul.header__dropdown:not(:first-of-type) {
  height: 90vh;
}

.catalog-container > .catalog-contents > .header__dropdown > li > a {
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  padding-right: 15px;
}

.catalog-container .header__dropdown a {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.catalog-container .header__dropdown > li:hover {
  background: white;
}

.catalog-container .header__dropdown > li:hover > a {
  color: #e66166;
}

.catalog-container .parent-li-with-all-courses::after {
  content: none;
}

.catalog-container .header__dropdown li {
  position: static;
}

.catalog-container .header__dropdown li:not(:first-of-type)::before {
  content: none;
}

.catalog-container .header__dropdown:not(:first-of-type) {
  position: fixed;
}

.catalog-container.active {
  display: block;
  max-height: 90vh;
  height: 100%;
}

.dropdown-hide > ul {
  visibility: hidden;
  opacity: 0;
}

.dropdown-expand > ul {
  visibility: visible;
  opacity: 1;
}

.dropdown-hidden {
  visibility: hidden;
  opacity: 0;
}

.header__courses:hover,
.header__courses:active,
.header__courses:focus {
  outline: none;
}

.header__schools {
  margin-right: 15px;
  position: relative;
  font-weight: 450;
}

.header__ratings {
  position: relative;
  min-width: auto;
  border: none;
  background: none;
  outline: none;
  font-weight: 450;
}

.header__ratings:hover,
.header__ratings:active,
.header__ratings:focus {
  border: none;
  outline: none;
}

.ratings__dropdown-container {
  display: none;
  position: fixed;
  width: 100vw;
  height: 654px;
  background: white;
  left: 0px;
  top: 80px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  cursor: default;
  max-height: 100vh;
  z-index: -1;
}

.container-visible ~ .ratings__dropdown-container {
  display: block;
  background: white;
}

.ratings__dropdown {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  padding-top: 29px;
  padding-bottom: 29px;
  height: 654px;
  max-height: 100vh;
}

.ratings__dropdown > li {
  font-weight: 450;
  font-size: 20px !important;
  line-height: 26px !important;
  position: relative;
  height: 44px;
  display: flex !important;
  align-items: center;
}

.ratings__dropdown > li > a {
  padding-left: 20px;
  height: 100%;
  width: 100%;
}

.ratings__dropdown > li:hover {
  background: white;
}

.ratings__dropdown > li.imitate-hover {
  background: white;
}

.ratings__dropdown > li.imitate-hover > a {
  color: #e66166 !important;
}

.ratings__dropdown > li.imitate-hover > .ratings__dropdown {
  visibility: visible;
  opacity: 1;
}

.ratings__dropdown > li:hover > a {
  color: #e66166 !important;
}

.ratings__dropdown > li > a:hover {
  color: #e66166 !important;
}

.ratings__dropdown > li > .ratings__dropdown {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  width: max-content;
  height: 653px;
  left: 395px;
  top: 80px;
  background: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.ratings-close-btn {
  position: absolute;
  top: 6%;
  right: 6%;
  cursor: pointer;
}

.ratings__dropdown > li > .ratings__dropdown > li > a {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.ratings__dropdown > li:hover > .ratings__dropdown {
  visibility: visible;
  opacity: 1;
}

.ratings__dropdown > li > .ratings__dropdown > li {
  width: 100%;
}

.ratings__dropdown > li > .ratings__dropdown:hover {
  visibility: visible;
  opacity: 1;
}

.ratings__dropdown-container > .ratings__dropdown {
  max-width: 395px;
  padding-left: 80px;
}

@media (max-width: 1600px) {
  .ratings__dropdown > li > .ratings__dropdown {
    left: 395px;
  }
}

@media (max-width: 1100px) {
  .ratings__dropdown-container > .ratings__dropdown {
    max-width: 295px;
    padding-left: 80px;
  }

  .ratings__dropdown > li > .ratings__dropdown {
    left: 295px;
  }
}

@media (max-width: 998px) {
  .catalog-container {
    margin-top: 70px;
  }
}

@media (max-width: 960px) {
  .ratings__dropdown-container > .ratings__dropdown {
    max-width: 405px;
    padding-left: 40px;
  }

  .ratings__dropdown > li > .ratings__dropdown {
    left: 345px;
  }
}

@media (max-width: 768px) {
  .ratings__dropdown-container {
    top: 62px;
    height: auto;
  }

  .ratings__dropdown > li > .ratings__dropdown {
    height: auto;
    width: 100vw;
    padding: 16px 0 20px;
    position: initial;
    display: none;
    visibility: visible;
    opacity: 1;
    gap: 12px;
    flex-wrap: nowrap;
    max-height: max-content;
  }

  .ratings__dropdown > li > .ratings__dropdown > li {
    border-bottom: none;
  }

  .ratings__dropdown-container > .ratings__dropdown > li:hover {
    background: unset;
  }

  .ratings__dropdown-container > .ratings__dropdown > li > a {
    height: 46px;
    width: 100%;
    margin-right: auto;
  }

  .ratings__dropdown {
    gap: 0;
    position: initial;
    height: auto;
  }

  .ratings-close-btn {
    top: -48px;
    right: 2%;
    width: 40px;
    height: 40px;
  }

  .ratings-close-btn > img {
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .ratings__dropdown > li {
    height: auto;
    border-bottom: 1px solid #dbdbdb;
    font-weight: 450 !important;
    font-size: 19px !important;
    flex-direction: column;
  }

  .ratings__dropdown-container > .ratings__dropdown {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: auto;
    max-width: unset;
  }

  .ratings__dropdown-container > .ratings__dropdown > li > a {
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 500px) {
  .ratings__dropdown-container,
  .catalog-container {
    margin-top: 0;
  }

  .header__dropdown {
    top: 100px !important;
  }
}

.header__schools::after,
.header__ratings::after {
  display: block;
  position: absolute;
  left: 0;
  top: 26px;
  width: 0;
  height: 2px;
  background-color: #e66165;
  content: "";
  transition: width 0.2s ease-out;
}

.header__schools:hover:after,
.header__ratings:hover:after,
.header__schools:focus:after,
.header__ratings:focus:after {
  width: 100%;
}

.header__ratings:hover:after,
.header__ratings:focus:after {
  width: 100%;
}

.header__courses-burger {
  width: 16.19px;
  height: 20px;
  background-image: url(../img/svg/burger-red.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.header__row {
  display: flex;
}

ul.header-menu {
  padding: 0;
  text-align: center;
}

ul.header-menu li {
  display: inline-block;
}

ul.header-menu li a {
  color: #333333;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
}

ul.header-menu li.right-postition {
  float: right;
  padding: 0;
  margin: 9px 23px 0 -8px;
  position: relative;
}

ul.header-menu li.right-postition a {
  width: 37px;
  height: 37px;
  background-size: contain;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
}

.header {
  position: relative;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  max-height: 80px;
}

.user-link {
  position: relative;
  display: flex;
}

a.logout-link {
  /* display: none; */
  position: absolute;
  bottom: -35px;
  background: #6d30df;
  width: 80px;
  text-align: center;
  padding: 8px 0;
  color: #fefe00;
  left: 50%;
  margin-left: -40px;
  opacity: 0;
  z-index: -1000;
  border-radius: 8px;
}

.user-link:hover .logout-link {
  opacity: 1;
  z-index: 10;
}

a.logout-link:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #6d30df;
  position: absolute;
  top: -10px;
  left: 29px;
}
@media (min-width: 768px) {
  .footer-col-last {
    padding: 0;
  }
}
.categories__btn.cat-page {
  box-shadow: 0px 0px 0px 1px #6582fd;
}

.category__subcategories-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
}

.all-categories-link {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #272727;
}

.all-categories-link:hover {
  color: #272727;
}

.category-tag.bold .category__category {
  font-weight: 500 !important;
}

a.footer-logo {
  height: 66px;
  background-image: url(../img/svg/logo.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 5px;
  display: inline-block;
  width: 200px;
  max-width: 100%;
}

.footer-links-wrap a {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 1;
  display: block;
  margin-bottom: 9px;
}

.copyright {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 16px;
  margin-top: 35px;
}

ul.footer-list {
  padding: 0;
  margin: 0;
}

ul.footer-list li {
  display: block;
  margin-top: 11px;
}

ul.footer-list li a {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 15px;
}

.payments-list img {
  display: inline-block;
  height: 30px;
}

.payments-list {
  text-align: justify;
  text-align-last: justify;
  margin-top: 35px;
}

.social-links {
  margin: 22px 0;
}

span.js-cart-count {
  text-align: center;
  font-size: 11px;
  position: absolute;
  line-height: 1;
  border: 1px solid #fff;
  padding: 3px 5px;
  border-radius: 17px;
  right: -24px;
  top: -8px;
  letter-spacing: 0;
}

.social-links a {
  color: #fff;
  font-size: 27px;
  display: inline-block;
  vertical-align: top;
  margin-right: 18px;
}

.footer-sm-link a {
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

footer {
  padding: 50px 15px 60px;
  border-top: 1px solid #c8a3f3;
  position: relative;
}

.footer-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
}

.error {
  color: #fefe54;
  font-size: 13px;
  text-align: center;
  margin: 10px 0;
}

.loading {
  position: relative;
}

.tag {
  display: inline-block;
  background: #4c2ede;
  color: #fff;
  padding: 6px 12px;
  cursor: pointer;
  margin: 2px;
  font-size: 11px;
  letter-spacing: 1px;
  border-radius: 30px;
}

.main-sidebar .sidebar-menu li a span sup {
  display: inline-block;
  background: red;
  color: #fff;
  padding: 4px 5px 2px;
  border-radius: 18px;
  line-height: 1;
}

.school-sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 768px) {
  .price-filter-wrapper {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .filters__checkbox span:before {
    background: #f5f3f1 !important;
  }
}

.category-tag[data-level="3"] {
  display: none !important;
}

.school-sidebar .filters__checkbox span {
  font-weight: 400 !important;
  line-height: 24px !important;
  display: flex;
  cursor: pointer;
}

.loading:after {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 119, 239, 0.53);
  z-index: 10000;
  background-image: url(../img/svg/loader.gif);
  background-size: 100px;
  background-position: center;
  background-repeat: no-repeat;
}

.copy-notification {
  background: #007bff;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 150px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

.checkbox-wrap,
.checkbox-wrap + span {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

.checkbox-wrap + span {
  font-size: 13px;
  line-height: 1;
}

label.label-italic {
  font-family: "Conv_ofont.ru_Helvetica Neue" !important;
  font-style: italic;
  font-weight: 600;
  font-size: 12px !important;
}

.js-interval-wrap {
  margin: 10px 0;
  background: #efefef;
  padding: 10px;
  border-radius: 7px;
}

.checkbox-container {
  white-space: nowrap;
}

.checkbox-container a {
  color: #fff !important;
  text-decoration: underline;
}

.custom-file-input {
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.checkbox-wrap {
  position: relative;
  width: 21px;
  height: 21px;
  border: 1px solid #ccc;
  margin-right: 9px;
}

.checkbox-wrap input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

.checkbox-wrap input:checked + span {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #d839f6;
  left: 2px;
  top: 2px;
}

.js-section-row-template {
  display: none;
}

.js-section-hide {
  /*display: none;*/
}

.text-small {
  font-size: 12px;
}

.btn.btn-xs {
  font-size: 12px;
  padding: 4px 13px;
  margin: 2px;
  border-radius: 45px;
  color: #fff !important;
  text-decoration: none !important;
  opacity: 1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  white-space: nowrap;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  letter-spacing: 2px;
  box-shadow: 0 0px 6px 0px #616161;
}

#progress {
  display: none;
}

.progress {
  position: fixed;
  bottom: 10px;
  left: 2%;
  width: 96%;
  z-index: 1000000;
}

.preview {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-size: cover;
  position: relative;
  background-position: center;
  margin: 5px;
}

.preview a {
  position: absolute;
  right: -7px;
  background: black;
  color: #fff;
  line-height: 1;
  padding: 2px 6px 4px;
  border-radius: 12px;
  top: -6px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 1;
}

.js-sub-section-row {
  background: #fff;
  border: none !important;
  box-shadow: 0px 0px 9px 0px #bdbdbd;
  width: 100%;
  margin: 15px auto 0;
  border-radius: 20px;
  padding: 19px 15px !important;
}

.js-section-row.show-content {
  padding-bottom: 150px;
}

.js-section-row {
  background: #f3f3f3;
  border: none !important;
  box-shadow: 0px 0px 9px 0px #bdbdbd;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  padding: 19px 15px 19px !important;
}

.js-section-row > *,
.js-sub-section-row > * {
  display: none;
  position: fixed;
  opacity: 0;
}

.stay-visible {
  display: block;
  position: relative;
  opacity: 1;
}

.js-section-row.show-content > *,
.js-sub-section-row.show-content > * {
  display: block;
  position: static;
  opacity: 1;
}

.struct-wrap .plus-btn.js-section-toggle {
  min-width: 100%;
  position: relative;
  opacity: 1;
}

.show-content .plus-btn.js-section-toggle {
  margin-bottom: 25px;
}

a.plus-btn.plus-btn-purp:before {
  background: #9333e0;
  border: 1px solid #9333e0;
}

.struct-wrap .plus-btn {
  color: #3e3d3d;
  font-family: "Conv_HelveticaNeueCyr-Medium";
}

.js-sub-category-btns {
  position: static;
  flex: 1;
  justify-content: flex-end;
  display: block !important;
  opacity: 1 !important;
  padding: 0;
  margin-top: 12px;
}

.js-sub-category-btns .btn-custom {
  float: right;
  margin-top: 9px;
}

.js-interval-start-info {
  display: none;
  color: #e29bf3 !important;
  margin: 4px 0;
}

.fly-form .struct-wrap .form-control:disabled {
  background: #d2d2d2 !important;
}

.bg-wrap {
  background-size: cover;
  background-position: center;
}

.user-slider-item {
  height: 0;
  padding-bottom: 37%;
  background-size: cover;
  background-position: center;
}

.select2-container .select2-selection--single {
  height: auto;
  border: none;
  background-color: transparent !important;
  line-height: 1;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding: 5px 15px 4px;
  height: auto;
  border: none;
  background: none;
  border-bottom: 1px solid #a9a9a9;
  font-family: "Conv_HelveticaNeueCyr-Roman";
  letter-spacing: 1px;
  font-size: 14px;
  min-height: 48px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 100%;
}

.avatar-preview {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
  margin: 0 auto;
}

.nav-link {
  position: relative;
}

.nav-link sup {
  background: red;
  color: #fff;
  padding: 1px 3px 3px;
  border-radius: 15px;
  position: absolute;
  right: 0;
  line-height: 1;
  top: 0;
  font-size: 9px;
}

label sup {
  color: red;
  font-weight: bold;
  font-size: 13px;
}

.reminder {
  color: red;
}

.packages-wrap {
  margin: 12px 0;
  background: #e8e8e8;
  padding: 10px;
}

.package-item {
  height: 200px;
  border: 1px solid #949494;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
}

.package-name {
  font-weight: bold;
  padding: 10px 0;
}

.package-add a:hover {
  color: #000;
  text-decoration: none;
}

.package-add a {
  font-weight: bold;
  font-size: 50px;
}

.package-add.package-add-sm a {
  font-size: 20px;
  line-height: 1;
  margin: 20px 0;
  display: block;
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-item-sm {
  width: 75px;
  height: 75px;
  background-size: cover;
  background-position: center;
  display: inline-block;
  margin: 5px;
  position: relative;
  margin-bottom: 17px;
}

.preview-item-sm input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}

.preview-item-sm input:checked + span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  box-shadow: 0px 0px 6px 2px #a32aef;
}

.preview-item-sm a {
  position: absolute;
  bottom: -19px;
  font-size: 12px;
  text-align: center;
  left: 0;
  width: 100%;
  color: #d23ce6;
  line-height: 1;
}

ul.tag-editor {
  height: 100px;
  background: none;
  border: none;
  border-bottom: 1px solid #a9a9a9;
  padding: 5px;
  overflow: auto;
}

.tag-editor li {
  overflow: visible;
}

.tag-editor .tag-editor-delete {
  background: #cd3dea;
  color: #fff;
  padding: 0 2px;
  line-height: 1 !important;
  border-radius: 28px;
}

.tag-editor .tag-editor-delete i:before {
  color: #fff;
  font-size: 13px;
}

.tag-editor .tag-editor-delete i {
  line-height: 1;
}

.tag-item,
.tag-editor .tag-editor-tag {
  background: #4c2ede;
  border: none;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 16px;
  padding: 7px 11px;
  font-family: "Conv_HelveticaNeueCyr-Roman";
  font-weight: 100;
  margin: 3px;
  box-shadow: 0px 0px 7px 0px #4c4c4c;
  font-size: 12px;
  line-height: 1;
}

.fly-form-header-add.fly-form-header-add-sm {
  font-size: 20px;
}

.preview-item-sm input[type="text"] {
  z-index: -3;
}

ul.course-section-wrap {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
}

.course-section-name {
  position: relative;
  border: 1px dashed;
  margin-bottom: 8px;
  font-size: 20px;
  padding: 3px 0 3px 42px;
  cursor: pointer;
}

.course-section-name:before {
  content: "+";
  position: absolute;
  left: 6px;
  top: 1px;
  font-weight: 900;
  font-size: 28px;
  line-height: 1;
}

.course-section-name.opened:before {
  content: "-";
}

.cabinet-left {
  width: 235px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 10;
  border-right: 2px solid;
}

.cabinet-wrapper {
  position: relative;
}

.bg-wrap > * {
  font-size: 0;
}

.cabinet-right {
  padding-left: 289px;
  margin-left: -235px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-size: 14px;
  line-height: 1.3;
}

.person-name {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  margin: 10px 0 6px;
  font-size: 21px;
  line-height: 1.1;
}

.avatar-wrap {
  text-align: center;
}

.person-a-info {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  margin-bottom: 20px;
}

.btn-custom.btn-custo-sm {
  padding: 10px 20px;
  font-size: 14px;
}

ul.aside-menu {
  padding: 0 0;
  margin: 45px 0 0;
}

ul.aside-menu li {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 23px;
}

ul.aside-menu li a {
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
}

ul.aside-menu li a.active {
  color: #fefe54;
  text-decoration: underline;
  /* font-family: 'Conv_HelveticaNeueCyr-Bold'; */
}

ul.aside-menu li a sup {
  display: inline-block;
  background: #fefe54;
  line-height: 1;
  text-align: center;
  padding: 3px 5px;
  font-size: 12px;
  color: #6d30df;
}

ul.aside-menu li i {
  position: absolute;
  left: 0;
  color: #fefe54;
  font-size: 21px;
  width: 37px;
  text-align: center;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  top: 50%;
  margin-top: -11px;
}

ul.aside-menu li a.active + i {
  color: #db39fa;
}

ul.aside-menu li a.active + i + ul {
  display: block;
}

table.table {
  color: #fff;
}

ul.aside-menu > li.has-children > i {
  top: 12px;
}

ul.aside-menu li ul {
  padding: 0;
  margin: 9px 0 -10px 0;
  display: none;
}

ul.aside-menu li ul li {
  border: none !important;
  margin: 0 0 0 0 !important;
  padding: 9px 0 !important;
}

ul.aside-menu li ul li i {
  display: none;
}

ul.aside-menu li ul li:before {
  content: "\00B7";
  font-size: 15px;
  margin-right: 6px;
}

.table td,
.table th {
  vertical-align: middle;
  border: none;
  color: #5b5b5b;
}

.license-wrap {
  height: 700px;
  padding-right: 25px !important;
}

.table td a {
  color: #fff;
}

.purp-link {
  display: inline-block;
  color: #df78e9 !important;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  background: none;
  display: inline-block;
  border: none;
  padding: 0;
}

table.table.table-plan td {
  border: none;
}

.rejected {
  position: absolute;
  color: #fdfd53;
  z-index: 10;
  width: 0;
  height: 0;
  border-top: 40px solid #ea14e4;
  border-right: 42px solid transparent;
  top: 0;
  left: 0;
  width: 40px;
  height: 42px;
  display: none;
}

table.table.table-plan tr td:first-child {
  /*padding-left: 40px;*/
  position: relative;
}

.package-c-item.course-fly-wrap:before {
  background: linear-gradient(
    180deg,
    rgb(109, 48, 223) 0%,
    rgb(225, 64, 255) 100%
  );
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.9;
  border-radius: 7px;
}

.modal-content .package-c-item.course-fly-wrap {
  box-shadow: none !important;
  border: 1px solid #b741e5;
}

.package-c-item.course-fly-wrap * {
  color: #fff;
  position: relative;
  z-index: 10;
}

.package-c-item.course-fly-wrap:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://i.ytimg.com/vi/hDrhfwqT6bE/maxresdefault.jpg);
  background-size: cover;
  background-position: center;
  z-index: 0;
  border-radius: 9px;
}

.package-c-item.course-fly-wrap .package-price img {
  filter: brightness(0) invert(1);
  width: 15px;
  vertical-align: baseline;
  margin-left: 2px;
}

.course-fly-wrap {
  background: #efefef;
  box-shadow: 0px 8px 10px 0px #dedede;
  border-radius: 13px;
  margin-top: 26px;
  position: relative;
  padding: 20px 26px 31px;
}

.p-link {
  display: block;
  background: rgb(178, 65, 168);
  background: -moz-linear-gradient(
    0deg,
    rgba(178, 65, 168, 1) 0%,
    rgba(215, 63, 168, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(178, 65, 168, 1) 0%,
    rgba(215, 63, 168, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(178, 65, 168, 1) 0%,
    rgba(215, 63, 168, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b241a8", endColorstr="#d73fa8", GradientType=1);
  color: #fff !important;
  z-index: 10;
  position: relative;
  text-decoration: none !important;
  text-align: center;
  width: 88%;
  margin: -31px auto 0;
  padding: 17px 20px;
  letter-spacing: 1px;
  font-family: "HelveticaLight";
  border-radius: 47px;
}

.package-c-item.course-fly-wrap {
  margin: 0;
  min-height: 290px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  /* overflow: hidden; */
  box-shadow: 0px 8px 10px 0px #b1b0b0;
  padding-bottom: 0;
  flex: 1 1 auto;
}

video {
  background: rgb(220, 57, 251);
  background: -moz-linear-gradient(
    270deg,
    rgba(220, 57, 251, 1) 0%,
    rgba(198, 54, 226, 1) 38%,
    rgba(109, 48, 223, 1) 60%,
    rgba(109, 48, 223, 1) 80%,
    rgba(109, 48, 223, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(220, 57, 251, 1) 0%,
    rgba(198, 54, 226, 1) 38%,
    rgba(109, 48, 223, 1) 60%,
    rgba(109, 48, 223, 1) 80%,
    rgba(109, 48, 223, 1) 100%
  );
  background: linear-gradient(
    270deg,
    rgba(220, 57, 251, 1) 0%,
    rgba(198, 54, 226, 1) 38%,
    rgba(109, 48, 223, 1) 60%,
    rgba(109, 48, 223, 1) 80%,
    rgba(109, 48, 223, 1) 100%
  );
  max-width: 100%;
}

.video-wrap {
  display: inline-block;
  position: relative;
}

.video-wrap:before {
  /*    content: '';
position: absolute;
width: 70px;
height: 30px;
background-image: url(../img/svg/logo.png);
background-size: contain;
right: 3px;
top: 4px;
z-index: 1000;
display: inline-block;
background-repeat: no-repeat;*/
}

.package-c-item.course-fly-wrap .p-link {
  margin-bottom: -29px;
  box-shadow: 0px 4px 8px 0px #444;
  background: linear-gradient(0deg, rgb(254, 254, 0) 0%, rgb(252, 253, 0) 100%);
  color: #793dd8 !important;
  font-family: "Conv_Montserrat-SemiBold";
  letter-spacing: 0;
}

.package-advantages {
  flex-grow: 1;
  padding-bottom: 40px;
  font-family: "Conv_Montserrat-SemiBold";
  font-size: 15px;
  padding-top: 19px;
}

.package-advantages div {
  margin-bottom: 3px;
}

.package-c-item.course-fly-wrap .h3 {
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 1px;
  color: #fff;
  text-shadow: 1px 1px 1px #616161;
  line-height: 1;
}

.package-price {
  color: #fefe00 !important;
  font-size: 27px;
  letter-spacing: 1px;
}

.packages-block h2 {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  font-size: 30px;
  margin: 10px 0 21px;
}

.packages-block {
  margin-top: 35px;
  margin-bottom: 0;
}

.rejected i {
  position: absolute;
  top: -33px;
  left: 6px;
}

table.table.table-plan tr td:first-child {
  white-space: nowrap;
  color: #fff;
}

.page-title {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  margin: 0 0 25px;
  font-size: 28px;
  line-height: 1;
}

.page-title a,
.page-title span {
  color: #fefe54;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 17px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.sw-row .btn-custom {
  width: 195px;
  white-space: nowrap;
  letter-spacing: 0px !important;
}

.course-add label sub {
  font-family: "Conv_ofont.ru_Helvetica Neue";
  font-size: 14px;
  margin: 0 3px;
  vertical-align: middle;
  bottom: 1px;
  line-height: 1;
  display: inline-block;
  font-weight: bold;
}

.package-price span.old-price {
  color: #fff;
  font-size: 16px;
  text-decoration: line-through;
}

.select2-container {
  display: block;
  width: 100% !important;
}

.fly-form {
  background: #fafafa;
  color: #444;
  padding: 28px;
  border-radius: 15px;
  margin-bottom: 30px;
}

.fly-form-header {
  font-size: 21px;
  position: relative;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.fly-form-body {
  padding: 30px 0 0;
  display: none;
}

.fly-form-header:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  line-height: 1;
  font-weight: 900;
  font-size: 17px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px;
}

.fly-form.opened .fly-form-body {
  display: block;
}

.fly-form.opened .fly-form-header:after {
  content: "\f077";
}

.modal-header {
  border: none;
  font-family: "Conv_HelveticaNeueCyr-Bold";
  display: block;
  text-align: center;
}

.modal-content {
  background: rgba(109, 48, 223, 0.81);
  color: #fff;
}

.modal-title {
  font-size: 19px;
  margin-bottom: 0;
}

.modal-header .close {
  opacity: 2;
  color: #fff;
  padding: 2px 7px;
  background: rgb(220, 57, 251);
  line-height: 1;
  margin: 0;
  border-radius: 33px;
  position: absolute;
  right: -8px;
  top: -8px;
}

.plus-btn {
  display: inline-block;
  text-align: left;
  color: #ce37eb;
  position: relative;
  line-height: 1;
  font-size: 13px;
  letter-spacing: 1px;
  background: transparent;
  border: none;
  text-decoration: none !important;
}

.full-img-school {
  display: flex;
  flex: 1 1 auto;
  min-height: 300px;
  height: 665px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.ins-teacher-desc {
  height: 335px;
  padding-right: 13px !important;
}

.ins-teacher-desc-sm {
  height: 260px;
  padding-right: 13px !important;
}

.full-img-school .checked-single {
  right: -10px;
  position: absolute;
  top: -12px;
}

.full-img-school .mail-link {
  position: absolute;
  margin: 0;
  right: 10px;
  bottom: 11px;
}

.plus-btn:before {
  content: "+";
  font-family: "Open Sans", sans-serif;
  text-decoration: none !important;
  font-size: 37px;
  font-weight: 100;
  background: #4c2ede;
  color: #fff;
  line-height: 40px;
  height: 45px;
  display: inline-block;
  padding: 0 12px;
  border-radius: 40px;
  left: 0;
  vertical-align: middle;
  margin-right: 28px;
  white-space: nowrap;
  letter-spacing: 0;
  box-shadow: 0px 0px 7px 1px #1c0f58;
  border: 1px solid #4c2ede;
  text-decoration: none !important;
}

.checkbox-block {
  display: inline-block;
  white-space: nowrap;
}

.checkbox-block label {
  display: inline-block;
  font-size: 13px;
  letter-spacing: 1px;
  margin-right: 15px;
  margin-bottom: 0;
  vertical-align: middle;
  white-space: normal;
}

.checkbox-wrapper {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  border: none !important;
}

.checkbox-wrapper input {
  left: 0;
  width: 100%;
  z-index: 100;
  top: 0;
  height: 100%;
}

.checkbox-wrapper input:checked + span,
.checkbox-wrapper input:checked + div + span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  background-image: url(../img/svg/checked.png);
  background-size: 100% 100%;
  background-position: center;
  font-size: 0;
  background-repeat: no-repeat;
}

.checkbox-wrapper input + span,
.checkbox-wrapper input + div + span {
  color: #fff;
  text-align: center;
  background-image: url(../img/svg/check.png);
  background-size: contain;
  font-size: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.fly-form .form-control {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #a9a9a9;
  font-family: "Conv_HelveticaNeueCyr-Roman";
  letter-spacing: 1px;
  box-shadow: none !important;
}

.fly-form .form-control option {
  background: transparent;
}

select:focus::-ms-value {
  background: transparent !important;
}

.form-alert.form-alert-sm {
  font-size: 12px;
  font-family: "Conv_ofont.ru_Helvetica Neue" !important;
  margin: 4px 0 0;
  line-height: 1;
  font-weight: bold;
}

div.tagsinput input {
  min-width: 70px;
}

.struct-wrap .plus-btn.js-section-toggle:before {
  font-size: 0;
  width: 20px;
  height: 20px;
  padding: 0;
  box-shadow: none;
}

input[readonly] {
  pointer-events: none;
  background-color: #dfdfdf !important;
}

.custom-file-label::after {
  content: "Ð’Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ";
}

.custom-file-wrap {
  position: relative;
}

.custom-file-wrap input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.custom-file-wrap label {
  color: #4c2ede;
  margin: 0;
  display: block;
  border-bottom: 1px solid #a9a9a9;
  padding: 13px 10px;
  font-family: "Conv_HelveticaNeueCyr-Bold";
  letter-spacing: 1px;
}

.form-control.form-control-msr {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 14px;
  letter-spacing: 0;
  color: #8c8c8c;
}

.select2-results__option {
  color: #000;
}

div.tagsinput span.tag {
  background: #4c2ede;
  border: none;
  letter-spacing: 1px;
  font-weight: 600;
  border-radius: 16px;
  padding: 7px 11px;
  font-family: "Conv_HelveticaNeueCyr-Roman";
  font-weight: 100;
  margin: 1px 6px;
  box-shadow: 0px 0px 7px 0px #888;
  font-size: 12px;
}

.select-teachers {
  height: 147px;
}

.fly-form-body .form-group .select-teachers + .js-error {
  position: static;
  margin-top: 0px;
}

select.form-control.select-teachers {
  height: 113px !important;
}

select.form-control.scrollbar-inner {
  width: 96% !important;
  margin: 0 0 15px !important;
}

div.tagsinput {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #a9a9a9;
}

.fly-form .form-control:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

span.select2.select2-container.select2-container--default.select2-container--disabled {
  background: #fafafa;
  opacity: 0.7;
}

.dataTables_wrapper .dataTables_length {
  float: right;
  font-family: "Conv_HelveticaNeueCyr-Roman";
  font-size: 14px;
  margin-bottom: 25px;
}

.dataTables_wrapper .dataTables_length select {
  background: none;
  width: 68px;
  border: none;
  border-bottom: 1px solid #7b7b7b;
  padding: 6px 0;
}

.fly-form table.table {
  color: #444444;
  width: 100% !important;
}

.fly-form .table th {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  /*text-transform: uppercase;*/
  font-size: 15px;
  font-weight: 100;
  vertical-align: middle;
  border-color: #b7b7b7;
  text-align: center;
}

.fly-form .table td a {
  color: #444444;
  text-decoration: underline;
}

table.dataTable tbody th,
table.dataTable tbody td {
  background: transparent !important;
  vertical-align: middle;
  padding: 15px 10px;
  border-bottom: 1px solid #b7b7b7;
}

table.dataTable tbody tr {
  background-color: transparent;
  font-family: "Conv_HelveticaNeueCyr-Roman";
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #6d30df;
  border: none;
  border-radius: 50px;
}

.table td {
  padding: 15px 10px;
  border-bottom: 1px solid #ccc;
  font-size: 13px;
}

.fly-form .js-error {
  color: #000;
}

.checkbox-wrapper .js-error {
  width: auto;
  position: absolute;
  white-space: nowrap;
  bottom: -16px;
  margin: 0;
  left: 0;
}

ul.aside-menu li a:hover {
  color: #fefe54;
  text-decoration: none;
}

ul.aside-menu li a:hover + i {
  color: #db39fa;
}

ul.aside-menu li a.js-toggle-active.active {
  /* color: #fff; */
}

ul.aside-menu li:last-child {
  padding-top: 30px;
  border-top: 1px solid;
  margin-right: 40px;
  margin-top: 35px;
  padding-bottom: 30px;
}

.btn-danger,
.btn-danger:hover {
  background: #f04170;
  border-color: #f04170;
}

.btn-success,
.btn-success:hover {
  background: #00a743;
  border-color: #00a743;
  color: #fff !important;
}

.btn-primary,
.btn-primary:hover {
  background: #4d2ede;
  border-color: #4d2ede;
}

.btn.btn-xs:hover {
  opacity: 0.6;
}

.btn-dark,
.btn-dark:hover {
  background: #a2a2a2;
  border-color: #a2a2a2;
}

.plus-btn.plus-btn-grey {
  color: #fff;
}

.plus-btn.plus-btn-grey:before {
  background: #ffffff;
  color: #6d30df;
  font-weight: 400;
  box-shadow: none;
  border: 1px solid #fff;
  line-height: 43px;
  box-sizing: content-box;
}

.btn-index-wrap {
  padding-bottom: 40px;
  border-bottom: 1px solid;
  margin-bottom: 50px;
  margin-top: 40px;
}

.checkbox-wrap-preview {
  position: relative;
  margin: 5px;
}

.card-body {
  color: #000;
}

.checkbox-wrap-preview input {
  position: absolute;
  z-index: 10;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

table .preview {
  width: 50px;
  height: 50px;
}

.checkbox-wrap-preview input:checked + div {
  border: 4px solid #dc39fb;
}

.message-list-wrap {
  background: #fafafa;
  color: #3e3737;
  padding: 0 30px 46px;
  border-radius: 17px;
  overflow: hidden;
  margin-top: -496px;
  position: relative;
  box-shadow: 0px 0px 9px 0px #b1b1b1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
}

.message-list-title {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  font-size: 24px;
  line-height: 1;
  padding: 25px 21px 9px;
  border: none;
  margin: 0 -30px 0px;
  color: #9e9e9e;
  cursor: pointer;
  text-align: left;
  border-radius: 17px 17px 0 0;
  background: #e8e8e8;
  letter-spacing: 0.5px;
  /*    -webkit-transition: all 0.5s;
-o-transition: all 0.5s;
transition: all 0.5s;*/
  position: relative;
}

.message-list {
  height: 400px;
  /* z-index: 0; */
  /* opacity: 0; */
  position: relative;
  -ms-overflow-style: none;
  /*margin: 0 -22px 45px;*/
  margin: 0 -22px 45px !important;
  padding-right: 10px !important;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  /*margin: 0 !important;*/
  transition: all 0.5s;
}

.message-list .message-list {
  margin: 0 0 !important;
}

.scroll-wrapper.message-list {
  margin: 0 -22px 45px !important;
}

.message-list-wrappers .message-list-wrap:first-child {
  box-shadow: none;
  margin-top: 0;
}

.message-list-wrappers {
  overflow: hidden;
  height: 690px;
  border-radius: 16px;
  box-shadow: 0px 0px 14px 1px #280e4e;
  overflow: hidden;
}

.message-list-wrap.active .message-list {
  /* height: 250px; */
  opacity: 1;
  margin-bottom: 238px !important;
}

.message-list-wrap .message-list {
  padding-right: 0 !important;
}

.message-list-wrap:last-child {
  padding-bottom: 0;
}

.message-list-wrap.active:last-child {
  padding-bottom: 60px;
}

.message-list-wrap .plus-btn {
  position: absolute;
  right: 12px;
  top: 495px;
  z-index: 100;
}

.btn-fixed {
  position: fixed;
  right: 9px;
  bottom: 10px;
  z-index: 100;
}

.message-list-wrap .plus-btn:before {
  margin: 0;
  padding: 0 11px;
}

.message-list-wrap:last-child .message-list-title {
  margin-bottom: 0;
  border-radius: 16px;
}

.message-list-wrap.active .message-list-title {
  /* padding-bottom: 20px; */
  border-bottom: none;
  border-left: none;
  border-right: none;
  background: #fafafa;
  margin-bottom: 0;
  color: #444;
}

.thread-item {
  position: relative;
  padding: 14px 9px;
  text-decoration: none !important;
  padding-left: 80px;
}

.thread-item:after {
  display: block;
  content: "";
  width: 90%;
  height: 1px;
  background: #d8d8d8;
  position: absolute;
  bottom: 0;
  right: 5%;
}

.thread-date {
  color: #c93fd9;
  font-size: 11px;
}

.thread-item.active,
.thread-item:hover {
  background: rgba(219, 46, 222, 0.18);
}

.thread-photo {
  position: absolute;
  width: 43px;
  height: 43px;
  left: 23px;
  top: 50%;
  margin-top: -21px;
  background-size: cover;
  background-position: center;
  border-radius: 14px;
  box-shadow: 1px 1px 9px 0px #b9b9b9;
}

.message-list-title:after {
  content: "";
  display: block;
  height: 1px;
  background: #dadada;
  margin-top: 16px;
}

.message-list-wrap .scrollbar-inner > .scroll-element.scroll-y {
  width: 5px;
}

.message-list-wrap.active .message-list-title:after {
  opacity: 1;
}

.message-list-title:after {
  opacity: 0;
}

.thread-name {
  margin-top: 0;
  padding-top: 4px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
  color: #4e4e4e;
  font-size: 14px;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.thread-info {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #808080;
  font-size: 12px;
  letter-spacing: 2px;
  margin-top: 2px;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 17px !important;
  height: 603px;
  overflow: auto;
  -ms-overflow-style: none;
  margin: 0 !important;
}

.replyMsg.js-replyMsg {
  border-left: 2px solid #d13ce6;
  line-height: 1;
  padding-left: 5px;
  margin: 0 0 5px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-item.notified {
  background: #ccc !important;
}

.message-item.notified:before {
  border-top-color: #ccc !important;
}

.message-item {
  align-self: flex-end;
  background: #fafafa;
  color: #1f1f1f;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  width: 380px;
  padding: 10px 25px 10px 20px;
  margin-bottom: 20px;
  border-radius: 13px;
  box-shadow: 0px 0px 9px 0px #7f22b9;
  position: relative;
  letter-spacing: 0.5px;
  -webkit-transition: ease-in-out 0.25s;
  -o-transition: ease-in-out 0.25s;
  transition: ease-in-out 0.25s;
  /*word-break: break-all;*/
  flex: 0 0 auto;
  max-width: 95%;
}

.message-item .fa-reply {
  position: absolute;
  right: 6px;
  bottom: 5px;
  cursor: pointer;
}

.message-item .fa-thumbtack {
  position: absolute;
  right: 7px;
  bottom: 21px;
  cursor: pointer;
}

.message-item .fa-trash {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 12px;
  cursor: pointer;
  color: black;
}

.message-item .fa-edit {
  position: absolute;
  right: 5px;
  top: 25px;
  font-size: 12px;
  cursor: pointer;
  color: black;
}

.message-item.pinned.notified {
  background: #dedede !important;
}

.message-item.pinned {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  width: 100%;
  border-radius: 0;
  z-index: 11;
  background: #fff !important;
  max-width: 100%;
}

.search-wrap {
  position: relative;
  text-align: right;
}

.search_mobile {
  display: none;
}

.search-wrap input {
  width: 220px;
  max-width: 100%;
  padding: 6px 32px 6px 10px;
  border: 1px solid #fff;
  font-size: 13px;
  border-radius: 28px;
  outline: none !important;
  box-shadow: none !important;
}

.search-wrap button {
  position: absolute;
  height: 100%;
  right: 0;
  width: 30px;
  border: none;
  border-radius: 0px 28px 28px 0px;
  background: #fefe54;
  background-image: url(../img/svg/search.png);
  background-size: 16px;
  outline: none !important;
  box-shadow: none !important;
  background-position: center;
  background-repeat: no-repeat;
}

.finded > span {
  color: red !important;
}

div#chat-wrap {
  position: static !important;
}

.message-item.pinned:before {
  display: none;
}

.message-item.pinned .fa-thumbtack:before {
  content: "\f057";
}

.message-item.pinned .fa-thumbtack {
  right: 6px;
  color: #ca1a1a;
}

.message-item.reply-alert {
  margin-right: 24px;
  box-shadow: 0px 0px 9px 3px white;
}

.message-item.message-item-school {
  align-self: flex-start;
  background: #fefe54;
  color: #4d2ede;
}

.message-item:before {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 19px solid transparent;
  border-top: 31px solid #fafafa;
  content: "";
  position: absolute;
  right: -7px;
  top: 0;
  -webkit-transition: ease-in-out 0.25s;
  -o-transition: ease-in-out 0.25s;
  transition: ease-in-out 0.25s;
}

.message-item.message-item-school:before {
  right: auto;
  left: -8px;
  border-right: none;
  border-left: 19px solid transparent;
  border-top-color: #fefe54;
}

.message-info {
  color: #978dc3;
  font-family: "Conv_HelveticaNeueCyr-Roman";
  font-size: 10px;
  margin-top: 11px;
}

.thread-item.unread {
  background: #db77ef;
  color: #fff;
}

.thread-item.unread * {
  color: #fff;
}

.chat-form-wrap {
  white-space: normal;
  font-size: 0;
  position: relative;
}

.chat-form {
  margin-top: 40px;
}

.chat-form-wrap input,
.chat-form-wrap textarea {
  letter-spacing: 1px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  padding-right: 40px;
}

.chat-form-wrap button,
.chat-form-wrap button:hover {
  background: #ffff1a !important;
  border: none;
  width: 60px;
  height: 60px;
  position: absolute;
  right: -4px;
  bottom: -6px;
  border-radius: 41px;
  background-image: url(../img/svg/send.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  box-shadow: none !important;
  outline: none !important;
}

.scrollbar-inner > .scroll-element .scroll-bar {
  opacity: 1;
  background: #ffff1a;
}

.btn-custom.btn-custom-xs {
  font-size: 12px;
  padding: 8px 16px;
  box-shadow: 0 0px 6px 0px #120c2d;
  white-space: nowrap;
}

div#chat .chat-form-wrap input,
div#chat .chat-form-wrap textarea {
  width: 100% !important;
  border-radius: 17px;
  /*padding: 15px 15px ;*/
  padding: 0;
  /*line-height: 1;*/
  border: 15px solid transparent !important;
}

.chat-form-wrap textarea {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.chat-form-wrap textarea::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.message-item:first-child {
  margin-top: 200px;
}

.emojiPickerIconWrap {
  width: 100%;
}

.emojiPickerIcon {
  right: 54px;
  background-color: transparent !important;
  bottom: 1px;
  top: auto;
}

div#chat .chat-form-wrap .input-file {
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 0;
  background-color: #fff !important;
  background-image: url(../img/svg/file.png);
  background-position: center;
  background-repeat: no-repeat;
  border: none !important;
  right: 99px;
  bottom: 8px;
  cursor: pointer;
  font-size: 0;
  outline: none !important;
  box-shadow: none !important;
  letter-spacing: 0;
}

div#chat .chat-form-wrap .input-file input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
  z-index: 10;
}

div#chat .chat-form-wrap .input-file.active {
  background-color: #8531e0 !important;
  border-radius: 27px;
}

.message-list-wrap.active {
  margin-bottom: 283px;
}

.replace {
  animation-duration: 1s;
  /*-webkit-animation-fill-mode: forwards;*/
  animation-name: replace;
}

.thread-alert {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #dc39fb;
  vertical-align: middle;
  border-radius: 8px;
  margin-left: 3px;
}

.message-list-wrappers .scrollbar-inner > .scroll-element .scroll-bar {
  background: #cccccc;
}

.chat-placeholder {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

h1.single-title {
  font-size: 21px;
  font-family: "Conv_HelveticaNeueCyr-Bold";
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 36px;
}

.form-control.form-control-single {
  background: rgba(255, 255, 255, 0.16);
  color: #fff;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid;
  box-shadow: none;
}

.form-control.form-control-single option {
  color: #6d30df;
}

.form-control.form-control-single:focus {
  background: transparent;
}

control-label::before {
  color: #fff;
  border-color: #6d30df;
  background-color: #db39fa;
}

.course-block-sm-price {
  font-family: "Conv_Montserrat-SemiBold";
  font-size: 20px;
  text-align: right;
  letter-spacing: 1px;
  white-space: nowrap;
  width: 90px;
  margin-right: -10px;
}

.course-block-sm-price sub {
  font-size: 10px;
  position: static;
}

.course-block-sm-footer {
  margin: 15px 0 10px;
}

.course-block-sm-price div {
  font-family: "Conv_HelveticaNeueCyr-Roman";
  font-size: 11px;
  letter-spacing: 1px;
}

.course-block-sm-length {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 12px;
  padding: 0 5px;
  height: 15px;
}

.course-info {
  border-top: 1px solid #696969;
  width: 108px;
  text-align: center;
  padding: 10px 0 0;
  margin: 8px 0px 0;
  display: inline-block;
}

.course-media {
  font-family: "Conv_Montserrat-SemiBold";
  font-size: 12px;
  color: #696969;
  display: inline-block;
  padding-left: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0 5px;
  line-height: 1.2;
}

.owl-controls {
  text-align: right;
}

.owl-nav > * {
  font-size: 0;
  margin: 0 7px;
  width: 60px;
  height: 60px;
  background: #6d30df;
  font-family: "Font Awesome 5 Free";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  text-align: center;
  padding: 17px 0 0 5px;
  vertical-align: top;
  border-radius: 35px;
}

.owl-next:before {
  content: "\f054";
  font-size: 25px;
  line-height: 1;
}

.owl-prev:before {
  content: "\f053";
  font-size: 25px;
  line-height: 1;
}

.owl-prev {
  padding-left: 0;
}

.owl-nav {
  margin-top: 20px;
}

.course-block-sm:hover {
  background: rgb(198, 80, 221);
  background: -moz-linear-gradient(
    0deg,
    rgba(198, 80, 221, 1) 0%,
    rgba(235, 170, 248, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(198, 80, 221, 1) 0%,
    rgba(235, 170, 248, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(198, 80, 221, 1) 0%,
    rgba(235, 170, 248, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c650dd", endColorstr="#ebaaf8", GradientType=1);
  color: #fff;
}

.course-block-sm:hover * {
  color: #fff;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #d938f7;
  background-color: #d438f2;
  box-shadow: none !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d838f7;
  box-shadow: none !important;
}

.table-course-name,
.table-course-name a {
  font-size: 14px;
  max-width: 350px;
  line-height: 1.2;
  margin-bottom: 4px;
  color: #fff;
}

.table-course-date {
  font-size: 14px;
  margin-bottom: 6px;
  max-width: 250px;
  color: #fefe54;
}

.table-course-date a {
  border-bottom: 1px dashed;
}

.table-course-date a:hover {
  text-decoration: none !important;
}

.table-course-date span {
  color: #c5c5c5;
}

.table-expire {
  color: black;
  font-size: 12px;
  font-style: italic;
}

.media-custom-img {
  position: absolute;
  width: 45px;
  height: 45px;
  background-size: cover;
  border-radius: 30px;
  background-position: center;
  left: 0;
  top: 50%;
  margin-top: -23px;
}

.media-custom {
  display: block;
  position: relative;
  padding-left: 55px;
  text-decoration: none !important;
}

.media-custom-body {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 14px;
  color: #fff;
}

.table-a-info {
  color: #ccc;
  font-family: "Conv_HelveticaNeueCyr-Roman";
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 4px;
}

.plan-table-wrap {
  /*height: 600px;*/
  padding-right: 10px !important;
}

a.btn-check {
  display: inline-block;
  padding: 5px 0 5px 32px;
  background-size: contain;
  background-position: left center;
  font-size: 14px;
  letter-spacing: 1px;
  background-image: url(../img/svg/check.png);
  background-repeat: no-repeat;
  text-decoration: none !important;
  min-width: 129px;
}

a.btn-check.btn-check-danger {
  background-image: url(../img/svg/checked.png);
}

.btn-custom.btn-custom-dark {
  background: #dedede;
  letter-spacing: 1px;
}

.btn-custom.btn-custom-danger {
  background: #ff2e82;
  color: #fff !important;
  letter-spacing: 1px;
}

.btn-custom.btn-custom-success {
  background: #00a743;
  color: #fff !important;
  letter-spacing: 1px;
}

.btn-custom.btn-custom-dark:hover {
  color: #000 !important;
}

.btn-custom[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.table-expire.table-expire-alert {
  color: #fefe47;
  white-space: nowrap;
  margin-bottom: 5px;
}

.table-expire.table-expire-alert:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0f3";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

input.single-check {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  background-position: center;
  /* opacity: 0; */
  position: relative;
  background: transparent;
}

input.single-check:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../img/svg/check.png);
  background-color: transparent;
  background-size: cover;
}

input.single-check::-ms-check {
  background-image: url(../img/svg/check.png);
  background-color: transparent;
  background-size: cover;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  background-position: center;
  /* opacity: 0; */
  position: relative;
}

input.single-check:checked:before {
  background-image: url(../img/svg/checked.png);
  color: transparent;
}

input.single-check:checked::-ms-check {
  background-image: url(../img/svg/checked.png);
  color: transparent;
}

.fly-form-body .js-error {
  color: #e29bf3 !important;
  font-size: 10px;
  letter-spacing: 1px;
  margin-top: 1px;
  margin: 0;
  font-style: italic;
}

.fly-form-body .form-group .custom-file .js-error {
  position: static;
  margin-top: 43px;
}

.fly-form-body .form-group {
  margin-bottom: 25px;
}

.fly-form-body .form-group .js-error {
  position: absolute;
  line-height: 1;
  letter-spacing: 0;
}

.course-add .form-alert {
  font-style: normal;
  font-family: "Conv_HelveticaNeueCyr-Medium";
}

.form-alert.form-alert-success {
  color: 52a30a;
}

.fly-form-header-add {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 26px;
  margin: 25px 0 22px;
}

.course-add label {
  font-family: "Conv_HelveticaNeueCyr-Medium";
}

table.table.table-plan .btn-custom.btn-custom-xs {
  font-style: normal;
  box-shadow: 0 5px 17px 0px #120c2d;
}

input.form-control.form-control-sm.js-copy-link {
  font-family: "HelveticaLight";
  font-size: 12px;
}

.fly-form-header.fly-form-header-empty:after {
  display: none;
}

.js-link-wrap {
  position: relative;
  white-space: nowrap;
  padding-right: 48px;
}

input::-ms-clear {
  display: none;
}

.js-link-wrap span {
  width: 30px;
  height: 100%;
  position: absolute;
  width: 48px;
  font-size: 0;
  text-decoration: none !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  display: flex;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  background: #6d30df !important;
  border-color: #6d30df !important;
  box-shadow: none !important;
  outline: none !important;
}

.js-link-wrap span:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0c5";
  font-size: 20px;
  color: #ff0;
  text-decoration: none;
}

.js-link-wrap span.copied:before {
  content: "\f164";
}

.gift-item {
  width: 380px;
  margin: -48px auto 22px;
  text-align: center;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  box-shadow: 0 8px 19px 1px #2f2f2f;
  position: relative;
  z-index: 27;
  max-height: 80px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  top: 0;
}

.gift-item:first-child {
  margin-top: 0;
}

.gift-header {
  background-image: url(../img/svg/gift-bg.jpg);
  background-size: 100%;
  background-position: center bottom;
  color: #fff;
  position: relative;
  padding: 0;
  min-height: 255px;
  margin-bottom: -30px;
}

.gift-header:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(181, 28, 171);
  background: -moz-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(109, 31, 196, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(109, 31, 196, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(109, 31, 196, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b51cab", endColorstr="#6d1fc4", GradientType=1);
  left: 0;
  top: 0;
  z-index: 0 !important;
  opacity: 0.85;
}

.gift-item:nth-child(5n + 1) .gift-header:before {
  background: rgb(181, 28, 171);
  background: -moz-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(96, 4, 198, 1) 56%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(96, 4, 198, 1) 56%
  );
  background: linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(96, 4, 198, 1) 56%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b51cab", endColorstr="#6004c6", GradientType=1);
}

.gift-item:nth-child(5n + 2) .gift-header:before {
  background: rgb(181, 28, 171);
  background: -moz-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(129, 71, 194, 1) 56%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(129, 71, 194, 1) 56%
  );
  background: linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(129, 71, 194, 1) 56%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b51cab", endColorstr="#8147c2", GradientType=1);
}

.gift-item:nth-child(5n + 3) .gift-header:before {
  background: rgb(181, 28, 171);
  background: -moz-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(197, 92, 192, 1) 56%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(197, 92, 192, 1) 56%
  );
  background: linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(197, 92, 192, 1) 56%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b51cab", endColorstr="#c55cc0", GradientType=1);
}

.gift-item:nth-child(5n + 4) .gift-header:before {
  background: rgb(181, 28, 171);
  background: -moz-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(154, 40, 191, 1) 56%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(154, 40, 191, 1) 56%
  );
  background: linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(154, 40, 191, 1) 56%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b51cab", endColorstr="#9a28bf", GradientType=1);
}

.gift-item:nth-child(5n + 5) .gift-header:before {
  background: rgb(181, 28, 171);
  background: -moz-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(208, 28, 196, 1) 56%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(208, 28, 196, 1) 56%
  );
  background: linear-gradient(
    0deg,
    rgba(181, 28, 171, 1) 0%,
    rgba(208, 28, 196, 1) 56%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b51cab", endColorstr="#d01cc4", GradientType=1);
}

.gift-header * {
  position: relative;
}

.gift-name {
  padding: 20px 10px 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  margin-bottom: 0;
  cursor: pointer;
}

.gift-logo {
  display: inline-block;
  width: 83px;
  height: 88px;
  background-color: #5527f1;
  background-image: url(../img/svg/loader.gif);
  background-size: 64px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  box-shadow: 0px 0px 21px 1px #272727;
  position: relative;
}

.gift-confirm {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #f5f5f5;
  background-image: url(../img/svg/check-icon.png);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  box-shadow: -4px 4px 7px 1px #1d1d1d;
  right: -14px;
  bottom: 16px;
}

.gift-info {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  font-size: 21px;
  letter-spacing: 1px;
  margin: 15px 0 3px;
}

.gift-info-d {
  text-transform: uppercase;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 11px;
  letter-spacing: 2px;
  color: #ccc;
}

.gift-title {
  width: 90%;
  margin: 65px auto 0;
  background: #fff;
  font-family: "Conv_HelveticaNeueCyr-Bold";
  color: #d42f6e;
  text-transform: capitalize;
  font-size: 27px;
  line-height: 1;
  padding: 16px 10px;
  z-index: 2 !important;
  border-radius: 26px;
  position: relative;
  box-shadow: 0px 3px 7px 1px #888;
}

.gift-body {
  background-image: url(../img/svg/gift-body.png);
  padding-top: 60px;
  margin-top: 0;
  position: relative;
  background-color: #fff;
  padding-bottom: 24px;
}

.gift-prefooter {
  padding: 25px 10px;
  background: #fff;
}

.gift-footer {
  height: 65px;
  background-image: url(../img/svg/gift-footer.jpg);
  background-size: cover;
  background-position: center;
}

.giver-info div {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  color: #191919;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 6px;
}

.giver-info span {
  text-transform: uppercase;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 10px;
  letter-spacing: 1px;
  color: #808080;
}

.gift-list a {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  color: #191919;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 6px;
  display: inline-block;
  letter-spacing: 2px;
}

.gift-item.active {
  max-height: 1500px !important;
  border-radius: 20px;
}

.gift-item:hover {
  top: -10px;
}

.gift-item.active .gift-name {
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.gift-item:last-child {
  max-height: 80px;
  margin-bottom: 30px;
  border-radius: 20px;
}

.fly-form-body .error {
  color: #000;
}

.form-alert {
  color: #e29bf3;
  /*font-family: 'Conv_HelveticaNeueCyr-Medium';*/
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 10px;
  font-style: italic;
}

.filters-row select.form-control.form-control-single {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  background-image: url(../img/svg/arrow-d.png);
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer;
}

.filters-row select.form-control.form-control-single::-ms-expand {
  display: none;
}

.filters-row .form-control.form-control-single option {
  color: #6d30df;
}

.opacity-0 {
  opacity: 0;
}

.fly-form .table.table-xs th {
  font-size: 13px;
  padding: 8px 5px;
  text-transform: none;
}

.fly-form .table.table-xs td {
  padding: 16px 7px;
}

.form-control.form-control-single:focus {
  /*color: #212121;*/
  background: traparent;
}

.form-control.form-control-single::-webkit-input-placeholder {
  color: #fff;
}

.form-control.form-control-single::-moz-placeholder {
  color: #fff;
}

.form-control.form-control-single:-moz-placeholder {
  color: #fff;
}

.form-control.form-control-single:-ms-input-placeholder {
  color: #fff;
}

.filters-row .form-control.form-control-single {
  width: 165px;
  background: none;
  line-height: 1;
}

select.form-control.form-control-single::-ms-value {
  background: transparent;
  color: #fff;
}

select.form-control.form-control-single::-ms-value {
  background: transparent;
  color: #fff;
}

.filters-row .form-control.form-control-single::-ms-expand {
  color: #fff;
}

.filters-row .plus-btn:before {
  margin-right: 10px;
}

.table video {
  width: 150px !important;
}

a.file-link {
  display: inline-block;
  width: 65px;
  height: 43px;
  background-repeat: no-repeat;
  background-position: center;
  background: rgb(220, 57, 251);
  background: -moz-linear-gradient(
    270deg,
    rgba(220, 57, 251, 1) 0%,
    rgba(109, 48, 223, 1) 80%,
    rgba(109, 48, 223, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(220, 57, 251, 1) 0%,
    rgba(109, 48, 223, 1) 80%,
    rgba(109, 48, 223, 1) 100%
  );
  background: linear-gradient(
    270deg,
    rgba(220, 57, 251, 1) 0%,
    rgba(109, 48, 223, 1) 80%,
    rgba(109, 48, 223, 1) 100%
  );
  position: relative;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 1px #444;
}

a.file-link:before {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  content: "";
  background-image: url(../img/svg/document.png);
  background-size: 28px;
  background-position: center;
  background-repeat: no-repeat;
}

.file-upload-wrap {
  width: 200px;
  position: relative;
  padding: 12px;
  border: 1px dashed;
  cursor: pointer;
}

.file-upload-wrap input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 100;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0;
}

.file-upload-wrap div {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

tr.row-alert {
  background-color: rgba(204, 0, 255, 0.19) !important;
}

.fly-form .error {
  color: #000;
}

.thin-wrap {
  font-family: "Conv_ofont.ru_Helvetica Neue";
  font-size: 15px;
  letter-spacing: 1px;
  margin-bottom: 40px;
}

.fly-form .toggler-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 45px;
  font-size: 16px;
  line-height: 1;
  color: #ccc;
  align-items: center;
  padding-right: 50px;
}

.fly-form .toggler-block {
  display: inline-block;
  order: 2;
  height: 18px;
  width: 60px;
  background: rgb(81, 69, 221);
  background: -moz-linear-gradient(
    90deg,
    rgba(81, 69, 221, 1) 0%,
    rgba(117, 65, 225, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(81, 69, 221, 1) 0%,
    rgba(117, 65, 225, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(81, 69, 221, 1) 0%,
    rgba(117, 65, 225, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5145dd", endColorstr="#7541e1", GradientType=1);
  border-radius: 11px;
  position: relative;
  vertical-align: middle;
}

.fly-form .toggler-block + a + a {
  order: 1;
  margin-left: 9px;
}

.fly-form .toggler-wrap button {
  color: #444444;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  background: none;
  border: none;
  padding: 0;
  vertical-align: middle;
  margin-right: 9px;
  position: relative;
}

.fly-form .toggler-block + a {
  order: 3;
}

.fly-form .toggler-block input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

.fly-form .toggler-block span {
  position: absolute;
  height: 28px;
  width: 28px;
  background: rgba(255, 255, 255, 1);
  border-radius: 24px;
  box-shadow: 1px 0px 7px 1px #5d5d5d;
  left: 0;
  top: -5px;
  cursor: pointer;
}

.fly-form .toggler-block input:checked + span {
  left: 41px;
}

.fly-form .toggler-wrap a {
  color: #444444;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  margin: 0 25px;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
}

a[disabled],
.toggler-wrap button[disabled] {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.fav-list-wrap {
  margin-top: 90px;
}

.static-page-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 20px 0 77px;
}

.static-page-wrap > .container {
  position: relative;
}

.articles-render {
  flex-wrap: wrap;
  height: 680px;
  height: auto !important;
  display: flex;
  flex-direction: row;
  padding-top: 2px !important;
  padding-right: 10px !important;
  /*padding-bottom: 150px !important;*/
}

.aside-items-wrap {
  height: 600px;
  margin: 0;
  padding: 0 15px 0 0 !important;
}

.article-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  color: #fff;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  margin: 0 12px 24px;
  justify-content: space-between;
  padding: 14px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 9px 0px #0c0519;
  min-width: 250px;
}
a.article-item.article-item-main {
  min-width: 0;
}

.article-item:last-child {
}

.articles-render .article-item:nth-child(7n + 0) {
  min-width: 250px;
}

.articles-render .article-item:nth-child(7n + 1) {
  min-width: 300px;
}

.articles-render .article-item:nth-child(7n + 2) {
  min-width: 350px;
}

.articles-render .article-item:nth-child(7n + 3) {
  min-width: 210px;
}

.articles-render .article-item:nth-child(7n + 4) {
  min-width: 450px;
}

.articles-render .article-item:nth-child(7n + 7) {
  min-width: 200px;
}

.articles-wrap {
  margin: 20px -10px 0;
}

.article-preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.article-body {
  position: relative;
  z-index: 10;
  text-align: right;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: inherit;
}

.article-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(93, 56, 226);
  background: -moz-linear-gradient(
    22deg,
    rgba(93, 56, 226, 1) 0%,
    rgba(198, 54, 226, 1) 100%
  );
  background: -webkit-linear-gradient(
    22deg,
    rgba(93, 56, 226, 1) 0%,
    rgba(198, 54, 226, 1) 100%
  );
  background: linear-gradient(
    22deg,
    rgba(93, 56, 226, 1) 0%,
    rgba(198, 54, 226, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d38e2", endColorstr="#c636e2", GradientType=1);
  z-index: 1;
  opacity: 0.5;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.page-title-static {
  color: #272727;
  font-size: 60px;
  font-weight: 450;
  line-height: 77px;
  margin-bottom: 0 !important;
}

h1.page-title-static a {
  color: inherit !important;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  line-height: 1;
}

.article-item:hover {
  color: #4a4a4a;
  text-decoration: none;
}

.article-item:hover:before {
  background: rgb(188, 84, 210);
  background: -moz-linear-gradient(
    22deg,
    rgba(188, 84, 210, 1) 0%,
    rgba(251, 248, 85, 1) 100%
  );
  background: -webkit-linear-gradient(
    22deg,
    rgba(188, 84, 210, 1) 0%,
    rgba(251, 248, 85, 1) 100%
  );
  background: linear-gradient(
    22deg,
    rgba(188, 84, 210, 1) 0%,
    rgba(251, 248, 85, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc54d2", endColorstr="#fbf855", GradientType=1);
  opacity: 0.9;
}

.article-info {
  font-size: 13px;
  font-family: "Conv_Montserrat-SemiBold";
}

.article-owner {
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 13px;
  position: relative;
}

.article-owner:before {
  content: "";
  background-image: url(../img/svg/loader.gif);
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: 26px;
  background-position: center;
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  border-radius: 24px;
  vertical-align: middle;
}

.article-title {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  text-align: left;
  font-size: 25px;
}

.static-page-wrap .owl-stage-outer {
  white-space: nowrap;
}

.static-page-wrap .owl-stage-outer .owl-item {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.aside-items {
  margin-bottom: 0;
  background: #e1e1e1;
  background: #fff;
  padding: 15px;
  box-shadow: 0px 0px 9px 0px #8f8f8f;
}

.aside-header {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  text-align: right;
}

.aside-item {
  display: flex;
  color: #444 !important;
  position: relative;
  padding-left: 60px;
  margin-bottom: 23px;
  min-height: 53px;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none !important;
}

.aside-item-preview {
  position: absolute;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0px #565656;
  left: 0;
  top: 0;
}

.aside-item-title {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  font-size: 15px;
  line-height: 1;
  margin-bottom: 8px;
  letter-spacing: 0px;
}

.aside-item-info {
  font-size: 13px;
  color: #848282;
}

.aside-item-info-b {
  font-size: 12px;
  color: #848282;
  text-align: right;
  font-family: "Conv_Montserrat-SemiBold";
}

.a-preview-wrap {
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  position: relative;
  padding: 18px;
  margin-bottom: 55px;
}

.a-preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.a-preview-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(188, 84, 210);
  background: -moz-linear-gradient(
    22deg,
    rgba(188, 84, 210, 1) 0%,
    rgba(251, 248, 85, 1) 100%
  );
  background: -webkit-linear-gradient(
    22deg,
    rgba(188, 84, 210, 1) 0%,
    rgba(251, 248, 85, 1) 100%
  );
  background: linear-gradient(
    22deg,
    rgba(188, 84, 210, 1) 0%,
    rgba(251, 248, 85, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc54d2", endColorstr="#fbf855", GradientType=1);
  z-index: 1;
  opacity: 0.8;
}

.a-info {
  position: relative;
  z-index: 10;
}

.a-preview-wrap h1 {
  position: relative;
  z-index: 10;
  font-family: "Conv_HelveticaNeueCyr-Bold";
  font-size: 38px;
}

.a-date {
  font-family: "Conv_Montserrat-SemiBold";
  font-size: 13px;
}

.a-hashtags {
  text-align: right;
}

.alert-m {
  color: #ce43f0;
  font-style: italic;
  font-size: 15px;
  margin: 13px 0 0;
}

.alert-m a {
  color: inherit !important;
  text-decoration: underline;
}

.a-hashtags a {
  color: #fff !important;
  text-decoration: none !important;
  font-family: "HelveticaLight";
  font-size: 18px;
}

.a-text {
  /*font-family: 'HelveticaLight';*/
  font-size: 18px;
}

.a-text img {
  max-width: 100%;
  margin: 10px;
}

a.video-link {
  position: absolute;
  z-index: 10;
  color: #fff;
  bottom: -40px;
  width: 80px;
  height: 80px;
  background: #8a32e0;
  font-size: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 93px;
  border: 2px solid;
  left: 50%;
  margin-left: -40px;
  text-decoration: none !important;
}

a.video-link i {
  position: relative;
  right: -3px;
}

.popular-wrap.same-articles-wrap {
  background: none;
  padding-top: 0;
  margin-top: 10px;
}

.popular-wrap.same-articles-wrap:before {
  display: none;
}

.popular-wrap.same-articles-wrap .owl-nav > * {
  border: 1px solid;
}

.popular-wrap.same-articles-wrap h2 {
  color: #464646;
}

.popular-wrap.same-articles-wrap .article-item {
  min-width: auto;
}

.popular-wrap.same-articles-wrap .article-item .article-title {
  font-size: 21px;
  white-space: normal;
  line-height: 1;
}

.school-item {
  display: flex;
  width: 23%;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 3px 8px 10px 0px #8a8585;
  border-radius: 13px;
  padding: 0 0 30px;
  /* border: 15px solid transparent; */
  margin: 1%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  overflow: hidden;
}

.schools-wrap {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  margin: 0 -13px;
  /*justify-content: center;*/
}

.checked-single {
  background: #6d30df;
  border-radius: 35px;
  font-size: 18px;
  color: #fefe00 !important;
  line-height: 1;
  vertical-align: middle;
  position: relative;
  top: -2px;
  text-decoration: none !important;
  width: 35px;
  height: 35px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checked-single.checked-single-na {
  background: #fefe00;
  color: #6d30df !important;
}

h2.add-title i {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 7px;
}

.school-avatar {
  width: 120px;
  height: 120px;
  background-size: cover;
  position: relative;
  border-radius: 20px;
  box-shadow: 1px 1px 6px 0px #444;
  background-repeat: no-repeat;
  margin-bottom: 14px;
}

.school-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(93, 56, 226);
  background: -moz-linear-gradient(
    22deg,
    rgb(62, 26, 191) 0%,
    rgb(219, 57, 250) 100%
  );
  background: -webkit-linear-gradient(
    22deg,
    rgb(62, 26, 191) 0%,
    rgb(219, 57, 250) 100%
  );
  background: linear-gradient(
    22deg,
    rgb(62, 26, 191) 0%,
    rgb(219, 57, 250) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d38e2", endColorstr="#c636e2", GradientType=1);
  opacity: 0.4;
}

.school-item > * {
  position: relative;
  color: #fff;
}

.fly-form .static-text {
  font-family: "HelveticaLight";
  font-size: 17px;
}

.school-avatar a {
  color: #fefe54;
  position: absolute;
  background: #6d30df;
  padding: 5px;
  border-radius: 35px;
  right: -10px;
  top: -12px;
}

/* .static-page-wrap:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/svg/pattern1.png);
    background-size: 53px;
    opacity: .4;
} */

.school-name {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
  padding: 13px 10px;
  background: rgb(254, 254, 84);
  color: #340a79;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.school-info-block {
  margin-bottom: 20px;
  font-size: 18px;
}

.schhol-c-info {
  margin-bottom: 15px;
  text-align: center;
  font-family: "HelveticaLight";
}

.schhol-c-info i {
  color: #fcf00f;
}

h2.add-title {
  font-family: "HelveticaLight";
  font-size: 23px;
  margin-bottom: 25px;
}

.school-desc {
  margin: 20px 0 55px;
}

.school-slider-imgs {
  height: 100%;
  box-shadow: 3px 8px 10px 0px #dedede;
  max-height: 800px;
}

.school-slide {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  border-radius: 4px;
  max-height: 800px;
}

.noSearchResultsWrapper {
  display: flex;
  align-items: center;
  gap: clamp(10px, 1.47vw, 20px);
}

.resetSearchButton {
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 600;
  color: #e66166;
}

.resetSearchButton:hover {
  text-decoration: underline;
}

.resetSearchButton:active {
  text-decoration: underline;
  transform: scale(1.03);
}

.school-desc .fly-form {
  border-radius: 6px;
  box-shadow: 3px 8px 10px 0px #dedede;
}

.fly-form h2.add-title {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  font-size: 22px;
}

.teacher-desc {
  font-family: "Conv_Montserrat-SemiBold";
  white-space: normal;
  color: #6d30df;
  font-size: 14px;
}

.fly-header-sm {
  font-family: "Conv_HelveticaNeueCyr-Medium";
  text-transform: uppercase;
  color: #3a3a3a;
  margin: 30px 0 11px;
  line-height: 1.2;
  white-space: normal;
}

.page-title-static .school-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  height: 80px;
  font-size: 13px;
}

.js-teachers-slider.owl-carousel.owl-theme.owl-loaded .owl-nav {
  color: #fff;
}

.teacher-slide {
  display: block;
  text-decoration: none !important;
}

.teacher-img {
  width: 90px;
  height: 90px;
  background-size: cover;
  background-position: center;
  border-radius: 6px;
}

.teacher-name {
  text-decoration: none !important;
  color: #505050;
  font-family: "Conv_HelveticaNeueCyr-Medium";
  white-space: normal;
  line-height: 1.4;
  font-size: 20px;
  margin-bottom: 6px;
}

.js-teachers-slider.owl-carousel.owl-theme.owl-loaded .owl-nav > div:before {
  font-size: 17px;
}

.js-teachers-slider.owl-carousel.owl-theme.owl-loaded .owl-nav > div {
  width: 45px;
  height: 45px;
  padding: 14px 0 0 0;
}

.school-info-row b {
  font-family: "Futura PT";
  font-weight: 400;
}

.school-info-row {
  margin-bottom: 5px;
  font-family: "Futura PT";
  font-weight: 400;
  font-size: 18px;
  color: #737373;
}

a.help-modal-link {
  position: fixed;
  left: 7px;
  bottom: 8px;
  z-index: 500;
}

a.js-legal-info {
  vertical-align: middle;
  color: #272727;
  text-decoration: underline;
  display: inline-block;
  margin: 0;
}

.fixed-btn-del {
  position: fixed;
  background: #fefe54 !important;
  z-index: 100;
  left: 50%;
  margin-left: -60px;
  bottom: 48px;
}

.school-info-row a {
  color: #6d30df;
  text-decoration: underline;
}

.thim-text {
  font-family: "Conv_ofont.ru_Helvetica Neue";
  font-size: 15px;
  letter-spacing: 1px;
  margin-bottom: 40px;
  font-weight: bold;
}

.js-faq-body {
  display: none;
}

.thim-text.tf-row.js-faq-item {
  font-size: 0;
  margin: 0;
}

.js-faq-title {
  margin-bottom: 3px;
  padding: 12px 40px 12px 10px;
  border-bottom: 1px solid #bcbcbc;
  cursor: pointer;
  position: relative;
  font-size: 17px;
  display: block;
}

.js-faq-title:before {
  content: "\f00d";
  position: absolute;
  right: 0;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  transform-origin: center center;
  font-family: "Font Awesome 5 Free";
  top: 14px;
}

.opened .js-faq-title:before {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transform-origin: center center;
}

.map-wrap > ymaps {
  width: 100% !important;
  height: 100% !important;
}

.map-wrap {
  height: 100%;
  min-height: 250px;
  position: relative;
}

.fly-form.fly-form-sm {
  width: 90%;
  margin: 0 auto;
}

.fly-form.fly-form-sm .thim-text {
  font-size: 16px;
}

.js-faq-body {
  margin-top: 12px;
  font-size: 16px;
}

.prom-img {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 283px;
  margin: 43px 0;
}

h3.thim-text-title {
  font-family: "Conv_HelveticaNeueCyr-Bold";
  font-size: 17px;
  /*text-transform: uppercase;*/
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.thim-text a {
  color: #6d30df;
  font-family: "Conv_HelveticaNeueCyr-Bold";
  font-size: 14px;
  display: block;
  text-decoration: underline;
}
.breadcrumb-item *,
.breadcrumb-item {
  font-size: 18px;
}
@media (max-width: 768px) {
  .custom-switch {
    padding-left: 1.15rem;
  }
  .opened-menu .header__search,
  .opened-menu .user-link {
    display: none;
  }
  .fly-form.fly-form-sm {
    width: 100%;
  }

  .fly-form.fly-form-sm .thim-text,
  .js-faq-title {
    font-size: 15px;
  }

  ul.header-menu li.active a:after,
  ul.header-menu li:hover a:after {
    display: none;
  }

  .custom-switch-indicator {
    min-width: 36px;
  }
}


@media (max-width: 1400px) {
  .cabinet-left {
    width: 190px;
  }

  .full-img-school {
    height: 540px;
  }

  .ins-teacher-desc {
    height: 185px;
  }

  .school-item {
    width: 31%;
  }

  .plate-item-body h2 {
    white-space: normal;
    line-height: 1;
  }

  .cabinet-right {
    margin-left: -190px;
    padding-left: 215px;
  }
}

ul.aside-menu li {
  padding-left: 36px;
  padding-right: 2px;
}

ul.aside-menu li a {
  font-size: 13px;
  line-height: 1.3;
  letter-spacing: 0;
}

.person-name {
  font-size: 19px;
  padding: 0 5px;
}

.message-list-title {
  font-size: 20px;
  padding: 25px 13px 9px;
}

.thread-name {
  font-size: 13px;
}

.message-list-wrappers {
  height: 680px;
}

.chat-wrapper {
  height: 593px;
}

.person-a-info {
  margin-top: 14px;
}

h1.page-title {
  font-size: 24px;
  margin-bottom: 31px;
}

.filters-row .form-control.form-control-single {
  width: 145px;
  font-size: 13px;
  padding: 13px 10px;
}

.filters-row .btn-custom.btn-custo-sm {
  font-size: 12px;
  padding: 11px 13px;
}

.fly-form .table th {
  font-size: 13px;
  padding: 13px 7px;
}

.message-list-wrap .plus-btn {
  right: 8px;
  top: 493px;
}

@media (max-width: 1200px) {
  .js-list-table {
    width: 100vw;
    padding-top: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    overflow-y: auto;
  }

  .js-list-table > div > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .js-list-table > div > ul > li > a {
    text-decoration: none;
  }

  .btn-custom {
    font-size: 15px;
    padding: 10px 30px;
  }

  main .header__form {
    display: flex !important;
  }

  ul.header-menu li.active a:after,
  ul.header-menu li:hover a:after {
    bottom: -16px;
  }
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 13px;
}

.full-img-school {
  height: 450px;
}

.ins-teacher-desc {
  height: 100px;
  font-size: 13px;
}

.select-teachers {
  height: 137px;
}

.fly-form .table th {
  font-size: 12px;
  padding: 9px 8px;
}

.articles-render .article-item {
  min-width: 48% !important;
  max-width: 48%;
  width: 48%;
  margin: 1%;
}

.file-upload-wrap {
  width: 120px;
}

h1.page-title {
  font-size: 20px;
}

.fly-form {
  padding: 20px;
}

.fly-form .form-control {
  font-size: 13px;
  line-height: 1.2;
}

.avatar-preview {
  width: 80px;
  height: 80px;
}

.person-name {
  font-size: 17px;
}

ul.aside-menu li i {
  font-size: 19px;
}

ul.footer-list li {
  margin-top: 7px;
}

ul.footer-list li a {
  font-size: 13px;
}

.social-links a {
  font-size: 22px;
}

a.footer-logo {
  width: 100%;
  background-position: left center;
  height: 50px;
}

.payments-list img {
  height: 24px;
}

.fly-form-header.fly-form-header-empty {
  font-size: 20px;
}

.form-alert {
  font-size: 12px;
  line-height: 1;
}

.scroll-wrapper.scrollbar-inner.scrollbar-inner-table {
  padding-bottom: 20px !important;
}

.filters-row .file-upload-wrap {
  width: 200px;
  text-align: center;
}

.filters-row .file-upload-wrap div {
  text-align: center;
  display: block;
  width: 100%;
}

.table-course-name,
.table-course-name a {
  font-size: 14px;
}

.table-course-date {
  font-size: 13px;
}

.media-custom {
  padding-left: 38px;
}

.media-custom-img {
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

.media-custom-body {
  font-size: 13px;
}

a.btn-check {
  font-size: 13px;
}

.course-block-sm {
  width: 240px;
  display: block;
  margin: 0 auto;
}
.media-main-t {
  min-height: 90px;
}
.course-block-sm-price {
  width: 75px;
}

.course-info {
  width: 90px;
  white-space: nowrap;
}

@media (max-width: 992px) {
  header {
    padding: 10px 0 8px;
  }

  .teacher-img {
    margin-bottom: 25px;
  }

  .school-item {
    width: 48%;
  }

  .full-img-school {
    margin: 0 auto 25px;
    height: 400px;
    width: 400px;
  }

  .ins-teacher-desc {
    height: 200px;
  }

  .articles-render {
    margin-bottom: 25px;
  }

  ul.header-menu li.right-postition {
    margin-top: 6px;
    margin-right: 0;
  }
}

span.js-cart-count {
  right: -22px;
}

ul.header-menu li.right-postition a {
  width: 30px;
  height: 30px;
  margin-left: 13px;
}

ul.header-menu li a {
  letter-spacing: 0;
}

.cabinet-left {
  position: fixed;
  left: -270px;
  height: 100%;
  top: 0;
  background: #6d30df;
  z-index: 100;
  padding: 23px 15px 104px;
  width: 270px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.opened-left-menu .cabinet-left {
  left: 0;
  overflow: auto;
}

.cabinet-right {
  padding: 0;
  margin: 0;
}

.mobile-cabinet-menu {
  position: fixed;
  left: 0;
  width: 46px;
  top: 54px;
  margin-top: 0;
  background: #6d30df;
  padding: 5px 7px;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}

body.opened-left-menu .mobile-cabinet-menu {
  left: 268px;
  top: 27px;
}

.mobile-cabinet-menu div {
  height: 4px;
  background: #fefe54;
  margin: 6px 0;
}

.filters-row .form-inline {
  align-items: center;
  justify-content: center;
}

h1.page-title {
  text-align: center;
}

.chat-placeholder {
  text-align: center;
  margin: 10px 0 30px;
  font-size: 22px;
  height: 80px;
}

.message-item {
  padding: 6px 24px 6px 10px;
}

.chat-wrapper {
  height: 350px;
}

.chat-form {
  margin-top: 20px;
  margin-bottom: 35px;
}

.plus-btn:before {
  margin-right: 15px;
}

h2.page-title {
  font-size: 20px;
}

.owl-nav > * {
  width: 50px;
  height: 50px;
  padding-top: 16px;
  text-align: center;
}

.owl-prev:before,
.owl-next:before {
  font-size: 17px;
}

.package-c-item.course-fly-wrap {
  height: auto;
}

@media (max-width: 360px) {
  .logo-container {
    margin-right: 0px !important;
  }
}

.filters-mobile-btn {
  display: none;
}

.mobile-sorting-params {
  display: none;
}

.filters-mobile-wrapper {
  display: none;
}

@media (max-width: 769px) {
  .header__schools {
    justify-content: center !important;
  }

  .filters-wrapper {
    display: none;
  }

  .active > .filters-wrapper {
    display: block;
  }

  .filters-mobile-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    float: right;
  }

  .filters-mobile__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .categories__show-all.mobile {
    display: block !important;
  }

  .categories-gradient {
    display: none;
  }

  .filters-mobile__contents {
    margin-top: 48px;
    position: relative;
  }

  .filters-mobile-wrapper.active {
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    padding: 16px 20px 90px;
    overflow-y: auto;
  }

  .filters-mobile__header::after {
    content: url("https://kurs-market.com/images/mob-cat-gr.svg");
    position: absolute;
    top: 62px;
  }

  .reset-filters {
    font-weight: 450;
    font-size: 18px;
    line-height: 15px;
    color: #e66366;
  }

  .filters-mobile__contents .filters__block {
    display: block;
    margin-bottom: 40px;
  }

  .filters-mobile__contents .filters__block:not(:first-of-type) {
    margin-top: 70px;
    margin-bottom: 0;
  }

  .filters__title {
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
  }

  .filters__checkbox {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .filters-mobile__contents .filters__block::after {
    content: url("https://kurs-market.com/images/mob-cat-gr.svg");
    position: absolute;
    bottom: 25px;
  }

  .filters-mobile__contents .filters.categories__modificators {
    display: flex;
    position: relative;
    margin-bottom: 56px;
    margin-top: 40px;
  }

  .mobile-filters-wrapper {
    padding-top: 14px;
  }

  .filters__checkbox span:before {
    background: #f5f3f1 !important;
  }

  .filters__checkbox span {
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  .mobile-filters-wrapper > .school-sidebar {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    display: none;
  }

  .mobile-filters-wrapper > .filters-dropdown-btn.active ~ .school-sidebar {
    display: flex;
  }

  .filters-dropdown-btn {
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 12px;
    color: #272727;
    position: relative;
    display: block;
  }

  .filters-dropdown-btn::after {
    content: "";
    width: 10px;
    height: 10px;
    border: solid #272727;
    border-width: 2px 0 0 2px;
    transform: translate(-40%, -40%) rotate(225deg);
    position: absolute;
    left: 76px;
    top: 44%;
  }

  .filters-dropdown-btn.active::after {
    content: "";
    width: 10px;
    height: 10px;
    border: solid #272727;
    border-width: 2px 0 0 2px;
    transform: translate(-40%, -40%) rotate(45deg);
    position: absolute;
    left: 76px;
    top: 60%;
  }

  .filters-mobile__contents .filters.categories__modificators::after {
    content: url("https://kurs-market.com/images/mob-cat-gr.svg");
    position: absolute;
    top: -35px;
  }

  .filters-mobile__contents .filters__price input {
    background: #f5f3f1;
    color: #272727;
    width: 150px;
    height: 50px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  .filters-mobile__contents .filters__price input::placeholder {
    color: #bdbdbd;
  }

  .filters-mobile__contents .categories__modificators label {
    height: 35px;
    font-size: 18px;
    padding: 7px 9px;
  }

  .title-filters {
    font-weight: 450;
    font-size: 22px;
    line-height: 28px;
    color: #272727;
    margin-right: 40px;
  }

  .close-filters {
    display: inline-block;
  }

  .close-filters img {
    max-width: 20px;
    max-height: 20px;
  }

  .sorting__options {
    visibility: hidden;
    opacity: 0;
    background: #ffffff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #777777;
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 190px;
  }

  .sorting-mobile-btn,
  .sorting-mobile-btn:hover,
  .sorting-mobile-btn:active,
  .sorting-mobile-btn:focus {
    border: none;
    outline: none;
    background: none;
    position: relative;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #777777;
  }

  .sorting__options > div > input {
    display: none;
  }

  label {
    margin-bottom: 0;
  }

  .filters-mobile-btn > span {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #777777;
  }

  .categories__sorting {
    display: none;
  }

  .mobile-sorting-params {
    display: block;
    margin-bottom: 16px;
  }

  .header-menu a {
    color: #333333;
    font-size: 18px;
    text-decoration: none;
  }

  .header__courses-burger {
    height: 11px;
  }

  .header__courses:hover {
    border: none;
  }

  .header__schools:hover::after,
  .header__ratings:hover::after {
    display: none;
  }

  .header__schools:focus::after,
  .header__ratings:focus::after {
    display: block;
    position: absolute;
    left: 0;
    top: 22px;
    width: 0;
    height: 2px;
    background-color: #e66165;
    content: "";
  }

  .header__courses:focus::after {
    display: block;
    position: absolute;
    left: 0;
    top: 22px;
    width: 0;
    height: 2px;
    background-color: #e66165;
    content: "";
  }

  .header-menu li {
    background-image: none !important;
    list-style: none;
    font-family: "Futura PT", Arial, sans-serif !important;
    font-weight: 400;
  }

  .logo-container {
    margin-right: 61px;
  }

  .mobile-menu-toggler {
    opacity: 1;
  }

  .page-title a,
  .page-title span {
    font-size: 14px;
  }

  .page-title-static {
    font-size: 35px;
  }

  span.js-cart-count {
    position: static;
    border: none;
    font-size: inherit;
  }

  .mobile-menu-toggler {
    float: right;
    width: 36px;
    cursor: pointer;
  }

  .mobile-menu-toggler div {
    height: 4px;
    background: rgb(255, 255, 26);
    border-radius: 13px;
    margin: 5px 0;
  }

  body.opened-menu ul.header-menu {
    right: 0;
  }

  body.opened-menu {
    overflow: hidden;
  }

  ul.header-menu li a {
    padding-top: 0;
  }

  ul.header-menu li {
    text-align: left;
    position: relative;
    z-index: 110;
  }

  body.opened-menu .mobile-menu-toggler {
    position: fixed;
    right: 200px;
    z-index: 109;
    top: 7px;
  }

  body.opened-menu .mobile-menu-toggler div:first-child {
    display: none;
  }

  body.opened-menu .mobile-menu-toggler div:nth-child(2) {
    transform: rotate(45deg);
    top: 9px;
    position: relative;
  }

  body.opened-menu .mobile-menu-toggler div:nth-child(3) {
    transform: rotate(-45deg);
  }

  body.opened-menu .header-menu:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #6d30df66;
    z-index: 107;
  }

  body.opened-menu .mobile-cabinet-menu {
    display: none;
  }

  footer {
    text-align: center;
    padding: 15px;
  }

  a.footer-logo {
    height: 41px;
    background-position: center;
    margin-bottom: 5px;
  }

  .copyright {
    margin-top: 0;
    font-size: 14px;
  }

  .footer-title {
    font-size: 15px;
    margin-top: 3px;
  }

  ul.footer-list li a {
    text-decoration: underline;
  }

  .payments-list {
    text-align: center;
    text-align-last: center;
    margin-top: 20px;
  }

  .payments-list img {
    margin: 0 5px;
  }

  .social-links {
    margin: 12px 0;
  }

  main {
    padding: 15px 0;
  }

  h1.page-title {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .fly-form .table.table-xs td {
    padding: 10px 5px;
    font-size: 12px;
  }

  .fly-form .table th {
    font-size: 12px;
    padding: 6px 6px;
    text-transform: none;
  }

  table.dataTable tbody td {
    padding: 10px 7px;
    font-size: 11px;
  }

  .btn.btn-xs {
    letter-spacing: 1px;
    font-size: 11px;
    padding: 3px 10px;
  }

  a.file-link {
    width: 50px;
    height: 37px;
  }

  .btn-custom.btn-custom-xs {
    font-size: 11px;
    padding: 6px 12px;
  }

  .fly-form .form-control {
    min-width: 150px;
    resize: none;
  }

  body.opened-left-menu .cabinet-wrapper:before {
    content: "";
    position: fixed;
    background: rgba(109, 48, 223, 0.65);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  h1.single-title {
    font-size: 16px;
  }

  a.static-link {
    display: block;
    text-align: center;
    font-size: 14px;
  }

  .btn-custom {
    font-size: 15px;
    padding: 10px 30px;
  }

  .btn-index-wrap {
    padding-bottom: 14px;
    margin: 0 0 9px;
  }

  .btn-index-wrap .plus-btn {
    font-size: 12px;
    margin-bottom: 7px;
  }

  .btn-index-wrap .plus-btn:before {
    line-height: 38px;
    height: 39px;
    padding: 0 12px;
    font-size: 26px;
    margin-right: 11px;
  }

  .plus-btn.plus-btn-grey.plus-btn-archive:before {
    font-size: 15px;
    height: 38px;
  }

  table.table.table-plan tr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid;
    padding: 12px 0;
  }

  table.table.table-plan td {
    padding: 7px 15px;
    width: 100%;
    display: block;
  }

  table.table.table-plan td > * {
    text-align: left !important;
  }

  .plan-table-wrap input.single-check {
    margin: 0 5px 0 !important;
  }

  .table td,
  .table th {
    font-size: 13px;
    padding: 5px;
  }

  .thin-wrap {
    font-size: 15px;
  }

  .text-center-sm {
    text-align: center;
  }

  .fly-form .toggler-wrap a {
    font-size: 13px;
    margin: 0 0;
  }

  .fly-form .toggler-wrap button {
    font-size: 13px;
    margin-right: 3px;
  }

  .fly-form .toggler-wrap {
    font-size: 13px;
    align-items: center;
    padding: 0;
  }

  .fly-form .toggler-block + a + a {
    margin-left: 5px;
  }

  .fly-form .toggler-block {
    width: 40px;
    margin: 0 8px 0;
  }

  .fly-form .toggler-block input:checked + span {
    left: 24px;
  }

  .fly-form .toggler-block span {
    width: 18px;
    height: 18px;
    top: 0px;
  }

  ul.header-menu li.right-postition {
    float: none;
    margin-bottom: 16px;
  }

  ul.header-menu li.right-postition a {
    font-size: 14px;
    width: 100%;
    background-position: left center;
    padding: 0 0 0 39px;
    background-size: 26px auto;
  }

  .fav-list-wrap {
    margin-top: 55px;
  }

  .article-title {
    font-size: 20px;
    line-height: 1.2;
  }

  .filters-wrap,
  .market-wrap {
    width: auto !important;
  }

  .categories__container > .container {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  h1.page-title {
    font-family: "Conv_HelveticaNeueCyr-Medium";
    font-size: 16px;
  }

  .school-item {
    width: 100%;
  }

  .full-img-school {
    height: 290px;
    width: 290px;
    max-width: 100%;
  }

  .message-item {
    width: 200px;
  }

  .fly-form-header.fly-form-header-empty {
    font-size: 18px;
  }

  .modal-title {
    font-size: 17px;
    margin-bottom: 0;
  }

  .school-desc {
    padding: 0 15px;
  }

  .page-title-static {
    font-size: 24px;
    font-size: 35px;
    line-height: 35px;
  }

  .page-title-static .school-avatar {
    width: 50px;
    height: 50px;
  }

  .checked-single {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  .fly-form .static-text {
    font-size: 15px;
  }

  .cropit-preview {
    width: 150px;
    height: 150px;
    margin: 15px auto;
  }

  .btn-custom.btn-custo-sm {
    padding: 9px 17px;
    font-size: 12px;
  }

  .fly-form-header {
    font-size: 18px;
  }

  .filters-row .form-control.form-control-single {
    width: 250px;
    margin: 0 auto 6px !important;
  }

  .filters-row .form-inline {
    display: block;
    text-align: center;
  }

  .filters-row .form-inline * {
    margin: 4px !important;
  }

  .mobile-cabinet-menu {
    top: 115px;
    width: 40px;
    right: -40px;
  }

  .mobile-cabinet-menu div {
    height: 3px;
  }

  .dataTables_wrapper .dataTables_length {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .filters-row .file-upload-wrap {
    padding: 7px;
    margin: 0 auto !important;
    width: 250px;
  }

  .fly-form {
    padding: 10px;
    margin: 0 -15px 15px;
    border-radius: 0;
  }

  .filters-row {
    margin-bottom: 15px;
  }

  .filters-row .btn-custom.btn-custo-sm {
    font-size: 11px;
    padding: 8px 13px;
  }

  .preview {
    width: 78px;
    height: 78px;
  }

  .fly-form-body {
    padding: 20px 5px 0;
  }

  .gift-item {
    width: 290px;
    margin-top: -53px;
  }

  .gift-name {
    font-size: 11px;
    padding: 14px 10px 50px;
  }

  .gift-item:last-child {
    max-height: 80px;
  }

  .gift-item.active .gift-name {
    margin-bottom: 7px;
  }

  .gift-logo {
    width: 70px;
    height: 70px;
    margin-top: 15px;
  }

  .gift-info {
    font-size: 17px;
  }

  .gift-title {
    font-size: 20px;
    margin-top: 24px;
  }

  .js-section-row {
    /*padding: 12px 9px 117px !important;*/
  }

  .fly-form .js-section-row .form-control {
    font-size: 12px;
    padding: 9px 7px;
  }

  .course-add .struct-wrap label {
    font-size: 13px;
  }

  .js-sub-section-row {
    /*padding: 13px 4px 64px !important;*/
  }

  .js-sub-category-btns .btn-custom {
    float: none;
    width: 150px;
    margin: 11px auto 0;
    display: block;
  }

  .aside-items-wrap {
    height: 335px;
  }

  .articles-render {
    height: 365px;
  }

  .article-item {
    min-height: 170px;
    margin: 0 0 15px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    flex: 1 1 auto;
  }
  a.article-item.article-item-main {
    min-height: 280px;
  }
  .article-title {
    margin-bottom: 20px;
  }

  .gift-item:hover {
    top: 0;
  }

  .message-list-wrappers {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  .packages-block h2 {
    font-size: 17px;
  }

  .package-c-item.course-fly-wrap .h3 {
    font-size: 16px;
  }

  .package-price {
    font-size: 22px;
  }

  .package-advantages div {
    font-size: 14px;
    margin-bottom: 0;
  }

  .course-block-sm {
    height: auto;
  }

  .articles-render {
    padding-left: 10px !important;
  }
}

@media (min-width: 769px) {
  .categories__container {
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  li.plate-item {
    display: flex;
  }
  .plate-item-body {
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
  }

  .course-desc {
    margin-bottom: auto;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.header-menu {
  align-items: center;
  display: flex;
}

.header-menu li {
  background-image: none !important;
}

header ul.header-menu li {
  font-family: "Futura PT", Arial, sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 23px;
  color: #333333;
  width: max-content;
}

ul.header-menu {
  justify-content: space-around;
}

header ul.header-menu li:hover a {
  text-decoration: none;
}

.course__tab.alone.active {
  color: #000;
}
.course__tab.alone:before {
  display: none;
}

header .user-link {
  margin: 0;
  padding: 0;
}

header .user-link a {
  font-weight: 450;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
  display: flex;
  align-self: center;
  gap: 5px;
}

.login-btn .black {
  display: block;
}

.login-btn .red {
  display: none;
}

.login-btn:hover .black {
  display: none;
}

.login-btn:hover .red {
  display: block;
}

.user-link img {
  width: 30px;
  height: 30px;
}

header .user-link a:hover {
  text-decoration: none;
}

.select-label {
  width: 100%;
}

.search__btn {
  border: none;
  padding: 7px 0;
  width: 40px;
}

.search__btn img {
  width: 20px;
  height: 20px;
  display: inline;
}

.header__form {
  max-height: 40px;
  max-width: 400px;
  margin: 0;
  border: 1px solid #333333;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.header__form-mobile {
  display: none;
  margin: 0;
  background: #f3f3f3;
  border-radius: 5px;
  align-items: center;
  width: 245px;
  height: 40px;
}

.header__form-mobile > button {
  padding-left: 15px;
  background: none;
  width: auto;
}

.header__form-mobile > button > img {
  width: 15px;
}

.header__tabs {
  height: 48px;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 0;
  margin: 0;
  border-bottom: 0.5px solid #bdbdbd21;
}

.header__tabs > li {
  width: 95px;
  text-align: center;
  padding-bottom: 9px;
}

.header__tabs > li > a {
  text-decoration: none;
  color: #777777;
  width: 100%;
}

.header__tabs > li > a:hover,
.header__tabs > li > a:active,
.header__tabs > li > a:focus {
  text-decoration: none;
}

.search-results-visible {
  display: flex !important;
  flex-direction: column;
}

.search-results-hidden {
  display: none !important;
}

.search__courses {
  display: none;
}

.search__schools {
  display: none;
}

.header__form input {
  border: none;
  width: calc(100% - 35px);
  outline: none;
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  color: #272727;
}

@media (max-width: 988px) {
  .header__courses {
    margin: 0;
  }

  .header-menu li:first-of-type {
    font-weight: 450;
  }

  body main {
    margin-top: 150px !important;
  }

  header .user-link a {
    margin-bottom: -5px;
  }

  ul.header-menu {
    margin-bottom: 0;
  }
}

.header__contents .header-menu.btns {
  margin-left: auto;
  padding-top: 6px;
}

/* @media (min-width: 500px) {

} */

.header__form button {
  background: none;
  border: none;
}

.close-search img {
  width: 38px;
}

.header-search-container-mobile {
  display: flex;
  justify-content: center;
  gap: 11px;
  padding: 10px 20px;
  border-bottom: 0.5px solid #7373730f !important;
}

.header-search-container-mobile > form > input {
  border: none;
  outline: none;
  padding-left: 10px;
  background: #f3f3f3;
}

.header-search-container-mobile > form > input:focus {
  border: none;
}

.header-search-container-mobile > button {
  color: #e66165;
  border: none;
  background: none;
}

body main {
  margin-top: 80px;
}

.header__flex {
  display: flex;
  gap: 30px;
  align-items: center;
  min-height: 100%;
  padding-top: 4px;
}

.js-categories-main-scroll {
  display: grid;
  column-gap: 20px;
  row-gap: 50px;
  grid-template-columns: repeat(4, 300px);
  grid-template-rows: 110px;
  grid-auto-rows: 110px;
}

.categories-item .category-item {
  height: 100%;
  padding: 0;
  border-radius: none;
  margin: 0;
  align-items: center;
  background: none !important;
  border-radius: none;
  overflow: visible;
  flex-direction: row;
  justify-content: flex-start;
}

.categories-item .category-item img {
  margin-right: 13px;
  flex-shrink: 0;
  width: 88px !important;
  opacity: 1;
}

.categories-item .category-item:before {
  content: none;
}

.categories__subtitle {
  font-weight: 450 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #272727 !important;
}

.category__category {
  font-weight: 400 !important;
  font-size: 19px !important;
  line-height: 21px !important;
  color: #272727 !important;
}

.category-item .categories-item__title {
  font-size: 35px;
  line-height: 45px;
  font-weight: normal;
  color: #7c7c7c;
  letter-spacing: 0;
  padding-right: 0;
  display: inline;
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 400;
}

.categories-item .category-item > span {
  position: relative;
}

.category-item .categories__count {
  display: inline;
  font-size: 22px;
  position: absolute;
  padding-left: 5px;
  transform: translateY(3px);
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 450;
  color: #bac7ff;
}

.categories-wrap-main {
  border-top: none;
}

.categories__title {
  font-size: 48px !important;
  text-align: center;
  font-weight: 450;
  font-family: "Futura PT", Arial, sans-serif !important;
}

.categories__subtitle {
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: #7c7c7c;
  font-weight: 400;
  font-family: "Futura PT", Arial, sans-serif;
}

.js-categories-main {
  margin-top: 70px;
}

.categories__link {
  padding: 15px 25px;
  border: 2px solid #6582fd;
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 20px;
  color: #6582fd;
  width: max-content;
  display: block;
  margin: 80px auto 0;
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 450;
}

.categories__link:hover {
  text-decoration: none;
  color: #fff;
  background: #6582fd;
}

.plate-listing li.plate-item {
  width: 320px;
  flex: unset;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0;
}

.plate-img {
  position: relative;
  width: 100%;
  display: block;
  height: 176px;
  border: none;
  flex-shrink: 0;
  border-radius: 0;
}

.plate-item .price-row {
  margin-top: auto;
  margin-bottom: 0;
}

.plate-item-body {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.plate-item .plate-item-body {
  padding: 20px 20px 36px;
}

.plate-item__logo {
  width: 100%;
  max-width: 117px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.plate-item-body .plate-item__title {
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 450;
  font-size: 20px;
  line-height: 22px;
  color: #272727;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.plate-item-body .plate-item__title:hover {
  text-decoration: none;
}

.plate-item .course-desc {
  height: 79px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  line-height: 1.18;
  color: #272727;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.plate-item__subtitle {
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 450;
  font-size: 16px;
  line-height: 21px;
  color: #777777;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
}
.plate-item__subtitle:hover {
  color: #737373;
  text-decoration: none;
}
.plate-item__title {
  font-size: 20px;
}

.plate-item__flex {
  display: flex;
  gap: 14px;
  margin-top: 4px;
}

.plate-item__flex span {
  font-size: 11px;
  line-height: 21px;
  color: #9c9c9c;
  font-weight: 400;
  font-family: "Futura PT", Arial, sans-serif;
}

.plate-item-top a,
.plate-item-top button {
  width: 20px;
  height: 24px;
}

.plate-item__lessons,
.plate-item__schedule {
  align-items: center;
  gap: 10px;
  display: flex;
}

.extra-text {
  font-family: "Futura PT";
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #272727;
  margin-top: 40px;
  margin-bottom: 80px;
  max-width: 1014px !important;
}

.extra-text > ul {
  padding-left: 26px;
  margin: 0;
}

.extra-text > ul > br {
  display: none;
}

.extra-text > ul > span:first-of-type > li {
  margin-top: 5px;
}

.extra-text > p {
  margin: 0;
}

.extra-text > p:first-child {
  margin-bottom: 4px;
}

.extra-text > p > br:not(:last-of-type) {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.list-table {
  top: 78px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.plate-item .course-desc {
  height: auto;
  margin-top: 15px;
  font-weight: 300;
  font-size: 17px;
  line-height: 1;
  font-family: "Futura PT", Arial, sans-serif;
  color: #000000;
}

.plate-item-top .text-right {
  position: absolute;
  top: 112px;
  right: 12px;
  width: auto;
  padding: 0;
}

.plate-item-top {
  position: absolute;
  bottom: 96px;
  display: none;
}

.rating__stars {
  position: relative;
  margin-left: -4px;
  max-width: 120px;
}

.plate-item .rating__stars {
  width: 16px;
  height: 16px;
}
.plate-item .rating__stars_empty {
  width: 16px;
  height: 16px;
}

.plate-item__top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  margin-bottom: 12px;
}

.rating__stars-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777777 !important;
}

.rating__stars-text:hover {
  text-decoration: none;
  opacity: 0.7;
}

.rating__stars_empty {
  background: url("https://kurs-market.com/img/star.png");
  width: 120px;
  height: 22px;
  background-position: 0;
  position: relative;
  background-size: auto 100%;
  background-repeat: repeat-x;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(335deg) brightness(93%)
    contrast(86%);
}

.rating__active {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: url("https://kurs-market.com/img/star.png");
  background-size: auto 100%;
  background-repeat: repeat-x;
}

.plate-item .raiting-block {
  background: none;
  padding: 0;
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 450;
  font-size: 14px;
  line-height: 15px;
  color: #f5af33;
}

.plate-item__rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.plate-item .price-row {
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  color: #000000;
}

.plate-item .alert-price {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #777777;
  font-family: "Futura PT", Arial, sans-serif;
  border-radius: 0 5px 5px 0;
  width: auto;
  background: #e66165;
  height: 25px;
  padding: 5px 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.plate-items {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.plate-item__course {
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 450;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #e66366;
  background: white;
  border-radius: 5px;
  border: 1px solid #e66366;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.plate-item__course:hover,
.plate-item__course:active {
  text-decoration: none;
  color: white;
  background-color: #e66366;
}

@media (max-width: 1024px) {
  .filters-wrap {
    width: max-content !important;
  }
}

@media (max-width: 769px) {
  .filters-wrap {
    width: 100% !important;
  }
}

.plate-item__more {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  text-decoration: underline;
}

.plate-item__more:hover,
.plate-item__more:active {
  /* opacity: 70%; */
  text-decoration: underline;
  color: #777777;
}

ul.plate-listing {
  justify-content: flex-start;
  gap: 25px;
}

.categories__container ul.plate-listing {
  justify-content: space-evenly;
}

.plate-listing li.plate-item {
  margin-bottom: 0;
}

.price__wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.price-row__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 17px;
  height: 26px;
  margin-bottom: 0px;
}

.price-row__credit span {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #272727;
}

.new-price {
  font-weight: 450 !important;
  font-size: 20px !important;
  line-height: 15px !important;
  color: #272727 !important;
}

span.old-price {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: line-through;
  color: #777777;
}

/* span.old-price:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    background: #777777;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-20deg);
} */

.market__descr {
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 25px;
  line-height: 32px;
  color: #7c7c7c;
}

#posts-counter {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
}

.breadcrumbs-wrap b,
.breadcrumbs-wrap h1 {
  font-weight: 450 !important;
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 60px !important;
  line-height: 77px;
  color: #272727;
  letter-spacing: unset !important;
}

.filters-wrap {
  margin-top: 13px;
  width: 24%;
  margin-right: 20px;
}

@media (max-width: 769px) {
  .filters-wrap {
    width: max-content;
    margin-right: 0px;
  }
}

.filters-wrap > .container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.categories__sorting-filters {
  display: flex;
  flex-direction: row;
  gap: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.categories__sorting-filters label {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
}

.sort-block {
  cursor: pointer;
  color: #777777;
}

.categories__sorting-filters label:hover {
  color: #272727;
}

.categories__sorting-filters label::after {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
}

.categories__modificators input:checked ~ label {
  background: #777777 !important;
  color: #ffffff !important;
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.categories__modificators input:checked ~ label img {
  display: block !important;
}

.categories__modificators label {
  cursor: pointer;
}

@media (max-width: 768px) {
  .desctop-sidebar {
    display: none;
  }
}

.category-tag a {
  font-family: "Futura PT", Arial, sans-serif;
}

.js-categories-slider {
  display: flex;
  flex-wrap: wrap;
  row-gap: 14px;
  column-gap: 24px;
}

.category-tag .active a {
  font-weight: 450;
  color: #fff;
  background: #a135ec;
}

a.market-btn {
  box-sizing: border-box;
  border-radius: 15px;
  padding: 15px 29px;
  font-weight: 450;
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: #e66366 !important;
  background: none;
  box-shadow: none;
}

a.market-btn:hover {
  opacity: 0.7;
}

.categories__modificators {
  margin-bottom: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 10px;
}

.categories__modificators a {
  color: #777777;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  height: 30px;
  padding: 3px 7px;
}

.categories__modificators a img {
  display: none;
}

@media (min-width: 768px) {
  .categories__modificators a:hover {
    text-decoration: none;
    background: #777777;
    color: #ffffff;
  }
}

.market__filters {
  width: 197px;
  flex-shrink: 0;
}

.market-top {
  box-shadow: none;
}

ul.plate-listing {
  margin-top: 0;
  margin-bottom: 20px !important;
}

.market__select {
  width: 100%;
  background: none;
  border: 1px solid #9d9d9d;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 8px;
}
.plate-listing + .text-center {
  margin-top: 25px;
}
.logout-link {
  display: none !important;
}
main {
  padding-top: 0;
}

.plate-item-top .text-right {
  top: 140px;
}

.filters__block {
  margin-top: 24px;
  font-family: "Futura PT", Arial, sans-serif;
}

.filters__checkbox,
.filters__radio {
  display: block;
  margin-top: 4px;
  margin-bottom: 0;
  position: relative;
}

@media (max-width: 768px) {
  .filters__checkbox span,
  .filters__radio span,
  .filters-show__text {
    white-space: unset !important;
  }

  .categories__modificators a:hover {
    color: #777777;
  }
}

.filters__checkbox span,
.filters__radio span,
.filters-show__text {
  padding-left: 27px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  color: #272727;
}
.filters-show .select-btn__text {
  font-size: 16px;
}
.filters-show .select-items {
  width: 60px;
  top: 40px;
}
.filters-show .select-items label .select-text {
  font-size: 16px;
  cursor: pointer;
  padding: 2.5px 10px;
}
.filters__checkbox input,
.filters__radio input {
  opacity: 0;
  visibility: hidden;
  height: 0;
  position: absolute;
}

.filters__checkbox input:checked + span:after,
.filters__radio input:checked + span:after {
  content: "";
}
.filters__radio input:checked + span:after {
  border-radius: 100%;
}
.filters__checkbox span:before,
.filters__radio span:before {
  content: "";
  width: 20px;
  background: #fff;
  height: 20px;
  display: block;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  top: 50%;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
}
.filters__checkbox input:checked + span:before,
.filters__radio input:checked + span:before {
  box-shadow: 0px 0px 0px 1px #e66366;
}
.filters__radio span:before,
.filters__radio span:after {
  border-radius: 100%;
}

.categories__sorting-filters input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
}

#rating_sort-asc:not(:checked) ~ .sort-asc-label,
#rating_sort-desc:checked ~ .sort-asc-label {
  display: block;
}

#rating_sort-desc:not(:checked) ~ .sort-asc-label,
#rating_sort-asc:checked ~ .sort-asc-label {
  display: none;
}

#rating_sort-asc:not(:checked) ~ .sort-decs-label,
#rating_sort-desc:checked ~ .sort-desc-label {
  display: none;
}

#rating_sort-desc:not(:checked) ~ .sort-decs-label,
#rating_sort-asc:checked ~ .sort-desc-label {
  display: block;
}

#rating_sort:checked ~ img.asc-img {
  display: block;
}

#rating_sort:not(:checked) ~ img.asc-img {
  display: none;
}

#rating_sort:not(:checked) ~ img.desc-img {
  display: block;
}

#rating_sort:checked ~ img.desc-img {
  display: none;
}

#price_sort-asc:not(:checked) ~ .sort-asc-label,
#price_sort-desc:checked ~ .sort-asc-label {
  display: block;
}

#price_sort-desc:not(:checked) ~ .sort-asc-label,
#price_sort-asc:checked ~ .sort-asc-label {
  display: none;
}

#price_sort-asc:not(:checked) ~ .sort-decs-label,
#price_sort-desc:checked ~ .sort-desc-label {
  display: none;
}

#price_sort-desc:not(:checked) ~ .sort-decs-label,
#price_sort-asc:checked ~ .sort-desc-label {
  display: block;
}

#price_sort:checked ~ img.asc-img {
  display: block;
}

#price_sort:not(:checked) ~ img.asc-img {
  display: none;
}

#price_sort:not(:checked) ~ img.desc-img {
  display: block;
}

#price_sort:checked ~ img.desc-img {
  display: none;
}

#date_sort-asc:not(:checked) ~ .sort-asc-label,
#date_sort-desc:checked ~ .sort-asc-label {
  display: block;
}

#date_sort-desc:not(:checked) ~ .sort-asc-label,
#date_sort-asc:checked ~ .sort-asc-label {
  display: none;
}

#date_sort-asc:not(:checked) ~ .sort-decs-label,
#date_sort-desc:checked ~ .sort-desc-label {
  display: none;
}

#date_sort-desc:not(:checked) ~ .sort-decs-label,
#date_sort-asc:checked ~ .sort-desc-label {
  display: block;
}

#date_sort:checked ~ img.asc-img {
  display: block;
}

#date_sort:not(:checked) ~ img.asc-img {
  display: none;
}

#date_sort:not(:checked) ~ img.desc-img {
  display: block;
}

#date_sort:checked ~ img.desc-img {
  display: none;
}

.sort-block {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  position: relative;
}

label {
  margin-bottom: 0;
}

.categories__show-all {
  display: flex;
  gap: 3px;
  margin-top: 5px;
  cursor: pointer;
}

.categories__show-all:hover {
  opacity: 0.7;
}

.categories__show-all span {
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #777777;
}

.filters__wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.filters__wrap input {
  width: 94px;
  border: 0.5px solid #777777;
  border-radius: 4px;
  padding: 0 3px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.plate-listing .text-center {
  width: 100%;
}

.filters__checkbox span:after,
.filters__radio span:after {
  /* content: ""; */
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  border-radius: 2px;
  background: #e66366;
}

.header__form {
  flex-direction: column;
  max-height: unset;
}

.header__search-wrapper .js-list-table {
  max-width: 400px;
  width: 100%;
  position: static;
  max-height: 406px;
  border: none;
  padding-top: 15px;
}

.header__search-wrapper .js-list-table > a {
  color: #000000 !important;
  font-weight: 600;
  padding-left: 20px;
  display: block;
}

.header__search-wrapper .js-list-table > p {
  padding-left: 20px;
}

.header__search-wrapper .js-list-table > ul:first-of-type::before {
  content: url(../img/svg/new-sf-line.svg);
  top: -52px;
  left: auto;
}

.header__search-wrapper .js-list-table > ul:first-of-type::after {
  content: url(../img/svg/new-sf-line.svg);
  position: absolute;
  top: -16px;
  height: 2px;
}

.header__search-wrapper .js-list-table > a::before,
.header__search-wrapper .js-list-table > a::after {
  content: none;
}

.header__search-wrapper .js-list-table > ul:first-of-type {
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
}

.header__search-wrapper .input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.header__search-wrapper .search__btn {
  background: white;
}

.filters__title {
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.select-label img {
  display: none;
}

.nav-breadcrumbs {
  background: #ffffff !important;
  z-index: 5;
}

.breadcrumb {
  margin-bottom: 0;
  background: none;
  padding: 15px 0;
  white-space: nowrap;
  overflow-x: auto;
  flex-wrap: nowrap !important;
}

@media (max-width: 769px) {
  .breadcrumb {
    margin-top: 20px;
  }
}

.category__modificator {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.category__subcategories-wrapper .category-tag:after {
  content: "";
}

@media (max-width: 769px) {
  .category__subcategories-wrapper {
    gap: 16px;
  }

  .category__subcategories-wrapper {
    margin-top: 13px;
  }

  .category__subcategories-wrapper .category__subcategories-wrapper {
    margin-top: 0;
  }

  .category__subcategories-wrapper .category-tag {
    height: auto;
  }

  .category__posts {
    display: none;
  }

  .category__market-top {
    margin-top: 24px;
  }
}

.breadcrumb-item a {
  color: rgba(39, 39, 39, 0.85);
}

.advantages__block {
  background: #efefef;
  border-radius: 45px;
  padding: 76px 115px 117px;
  font-family: "Futura PT", Arial, sans-serif;
}

.advantages__title {
  text-align: center;
  font-weight: 450;
  font-size: 60px;
  line-height: 77px;
}
.advantages__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 65px;
  row-gap: 100px;
}
.advantages-item {
  width: 42%;
}

.partners__slider {
  margin-top: 50px;
}
.partners {
  margin-top: 60px;
}
.partners__title {
  text-align: center;
  font-weight: 450;
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 48px;
  line-height: 62px;
}

.partners__slide {
  width: auto;
  height: 164px;
  /* background: grey; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partners__slide img,
.partners__slide picture {
  width: 80%;
  height: 80%;
  object-fit: contain;
  vertical-align: middle;
}

.advantages__text {
  margin-top: 10px;
  font-weight: 300;
  font-size: 25px;
  line-height: 32px;
}
.advantages__subtitle {
  font-weight: 450;
  font-size: 35px;
  line-height: 45px;
}

.overflow {
  position: fixed;
  top: 0;
  opacity: 0;
  visibility: hidden;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.overflow_active {
  opacity: 1;
  visibility: visible;
}

.login-popup__footer {
  background: #ffffff;
  border-radius: 10px;
  max-width: 360px;
  padding: 16px;
  display: flex;
  justify-content: center;
  column-gap: 15px;
  flex-wrap: wrap;
  margin: 16px auto 0;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
}

.login-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
}

.login-popup__footer > .login-popup__text {
  margin: 0;
}
.login-popup__footer-link {
  font-weight: 450;
  font-size: 18px;
  color: #6582fd;
  text-decoration: underline;
}

.login-popup {
  position: relative;
  height: fit-content;
  overflow-y: auto;
  max-height: 100vh;

  padding: 20px 30px;
  max-width: 420px;
}

.warning {
  display: none;
  font-weight: 300;
  line-height: 16px;
  color: #272727;
  max-height: 84px;
  margin-top: 13px;
  padding: 11px 12px 14px 15px;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.warning:before {
  content: "";
  border: solid transparent;
  position: absolute;
  top: 31%;
  right: 20px;
  border-bottom-color: white;
  border-width: 11px;
  margin-right: -1px;
  filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.05));
}

.login-popup__body {
  padding: 60px 30px 40px;
  max-width: 360px;
  margin: 0 auto;
  position: relative;
  font-family: "Futura PT", Arial, sans-serif;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
}
.login-popup__header {
  display: none;
}
.login-popup__header img {
  width: 45%;
}

.login-popup__close {
  position: absolute;
  right: 21px;
  top: 18px;
  z-index: 10;
  font-size: 20px;
  text-decoration: none;
  color: #777777;
}
.login-popup__close:hover {
  text-decoration: none;
  color: #ffc107;
}

.login-popup__title {
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  text-align: center;
}

.login-popup__input {
  margin-top: 28px;
  width: 100%;
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 12px 18px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  border: none;
  line-height: 26px;
  color: #a4a4a4;
}
.login-popup__input + .login-popup__input {
  margin-top: 14px;
}
.login-popup__forgot {
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #6582fd;
  display: inline-block;
}

.login-flex .filters__checkbox span {
  font-size: 16px;
  padding-left: 22px;
}

.login-flex .filters__checkbox span:before {
  width: 15px;
  height: 15px;
}

.login-flex .filters__checkbox span:after {
  width: 11px;
  left: 2px;
  height: 11px;
}

.login-popup__body .error {
  display: none;
}

.login-popup__register {
  display: block;
  padding: 13px;
  background: #ffffff;
  border: 1px solid #777777;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: #272727;
  line-height: 1.2;
  margin-top: 13px;
  text-decoration: none;
}
.login-popup__register:hover {
  color: #272727;
  text-decoration: none;
}
.login-popup__btn {
  width: 100%;
  margin-top: 28px;
  background: #e66165;
  padding: 13px;
  text-align: center;
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  border: none;
  color: #ffffff;
  border-radius: 10px;
  transition: 0.5s all;
}

.login-popup__btn:hover {
  opacity: 0.7;
}

.login-popup__text {
  margin-top: 32px;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #272727;
  text-align: left;
}

.login-popup__text > a {
  color: #e66165;
}

.login-popup__text > a:hover {
  opacity: 0.8;
}

.login-popup__text_fs15 {
  font-size: 15px;
  margin-top: 10px;
}
.login-popup__flex {
  display: flex;
  justify-content: space-between !important;
  margin-top: 8px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.comments-add .search-block ul {
  z-index: 10 !important;
}

.login-popup__flex a {
  background: #f9f9f9;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 10px 18px;
  transition: 0.5s all;
}

.login-popup__flex a:not(:last-child) {
  margin-right: 10px;
}

.login-popup__flex a:last-child {
  margin-left: 0 !important;
}

.login-popup__flex a:hover {
  opacity: 0.7;
}

.login-popup__flex a img {
  max-width: 29px;
}

.h1-container {
  text-align: center;
  margin: 30px 0 0;
  font-family: "Futura PT", Arial, sans-serif;
}

@media (max-width: 1400px) {
  .js-categories-main-scroll {
    grid-template-columns: repeat(3, 340px);
  }
}

@media (max-width: 1200px) {
  .advantages__wrapper {
    row-gap: 60px;
  }
  .advantages-item {
    width: 46%;
  }
  .advantages__block {
    padding: 40px;
  }

  .nav-breadcrumbs {
    padding-left: calc(50vw - 480px);
    padding-right: calc(50vw - 480px);
  }

  .categories-item .category-item {
    flex-direction: column;
    text-align: center;
  }

  .js-categories-main-scroll {
    grid-template-columns: repeat(3, 265px);
    grid-template-rows: auto;
    grid-auto-rows: auto;
  }

  .market__filters {
    width: 150px;
  }

  .market__flex {
    gap: 15px;
  }

  .filters__wrap {
    flex-wrap: wrap;
    gap: 10px;
  }

  ul.plate-listing {
    column-gap: 10px;
  }
}

@media (max-width: 992px) {
  header ul.header-menu li a {
    width: 100%;
    font-size: 18px;
  }

  .header ul.header-menu li.root-has-children > a {
    width: max-content;
  }

  header ul.header-menu li.has-children > a {
    width: max-content;
  }

  .nav-breadcrumbs {
    padding-left: calc(50vw - 360px);
    padding-right: calc(50vw - 360px);
  }

  .js-categories-main-scroll {
    grid-template-columns: repeat(3, 187px);
  }

  .category-item img {
    margin-bottom: 10px;
  }

  .category-item .categories-item__title {
    font-size: 24px;
    line-height: 1.2;
  }

  .categories__title {
    font-size: 32px !important;
  }

  .category-item .categories__count {
    font-size: 16px;
  }

  .categories__subtitle {
    font-weight: 450 !important;
    font-size: 20px !important;
    line-height: 21px !important;
  }

  .category-tag {
    position: relative;
    min-height: 34px;
    display: flex !important;
    align-items: center;
  }

  .category-tag::after {
    content: url("https://kurs-market.com/images/mob-cat-gr.svg");
    position: absolute;
    bottom: -8px;
    left: 0;
  }

  .filters-wrap > .container {
    gap: 0;
  }
}

.filters-show {
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-left: 160px;
}

.filters-show .select-btn {
  padding: 2px 10px;
}

.tag-amount {
  display: inline !important;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: #6582fd;
}
span.old-price {
  font-size: 16px;
  text-decoration: line-through;
  text-decoration-color: #777777;
}
span.old-price:after {
  display: none;
}
.cooperation-form:not(.form-success) .cooperation-form-success {
  display: none;
}

.form-success form {
  overflow: hidden;
  position: relative;
}
[data-popup="order-popup"] .login-popup__text {
  text-align: left;
  margin-top: 5px;
  font-size: 15px;
  font-weight: 300;
}
.cooperation-form-success {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cooperation-form-success__title {
  font-weight: 600;
  text-align: center;
  font-size: 30px;
  line-height: 1.3;
  color: #737373;
  opacity: 0.73;
}
.cooperation-form-success__title span {
  font-size: 25px;
  line-height: 1.3;
  font-weight: 400;
  color: inherit;
}
.coopearation-form__form {
  position: relative;
  overflow: hidden;
}
.login-popup__input {
  color: #000;
}
.login-popup__wrapper {
  position: relative;
  margin-top: 15px;
  margin-bottom: 0;
  width: 100%;
}
.login-popup__wrapper .login-popup__input {
  margin-top: 0;
}
.login-popup-pass-btn {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.static-page-wrap.cooperation {
  padding-bottom: 0;
}
main {
  padding-bottom: 0;
}

.course-fixed-old__text {
  font-size: 11px;
  color: #000;
  line-height: 14px;
  font-weight: 400;
}

.course-tarrif__label input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
}
.course-tarrif__label {
  padding-bottom: 44px;
}
/* .course-tarrif__label input:checked + .course-tarrif .course-tarrif__btn{
    background: linear-gradient(127deg, #6E3033 0%, #D939F9 51%);
}
.course-tarrif__label input:checked + .course-tarrif {
    box-shadow: 0px 0px 7px 7px rgba(0, 0, 0, 0.47);
} */

.course-fixed-grid__price,
.course-fixed-grid__installment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.course-fixed-grid__installment {
  row-gap: 10px;
}
/* TEST STYLES*/

.market__filters {
  width: 100%;
  font-family: "Futura PT";
}

.open-filters.active {
  position: relative;
}
.categories__btn {
  display: block;
}
.filters__block {
  margin-top: 0;
}
.filters__block + .filters__block {
  margin-top: 22px;
}

.js-categories-slider {
  display: none;
}

.market-wrap {
  position: relative;
  margin-top: 20px;
  width: 67%;
}
.breadcrumbs-wrap {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}
.breadcrumbs-wrap h1.category-page__header {
  font-size: 48px;
}
.market-top {
  padding-bottom: 30px;
}

.categories__container {
  background: #f5f3f1;
}

.categories__container > .container {
  display: flex;
}

.categories__search {
  max-width: 745px !important;
  width: 100% !important;
  height: 40px !important;
  background: #ffffff;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.2);
  border: none;
  margin-bottom: 24px;
}

.categories__search:has(.categories__search-res) {
  flex-direction: column;
  height: max-content !important;
  max-height: max-content;
  position: absolute;
  z-index: 1000;
}

.categories__search input {
  padding-left: 20px;
  background: #ffffff;
}

main .header__form {
  flex-direction: row;
}

@media (max-width: 768px) {
  .categories__search input {
    background: #f5f3f1;
    width: calc(100% - 75px);
  }

  main .header__form {
    justify-content: center;
  }
}

.b {
  padding-right: 0;
  padding-left: 20px;
  width: 40px;
  height: 100%;
  outline: none;
}

.categories__search-icon {
  max-width: 20px;
  max-width: 20px;
}

.market__filters {
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  column-gap: 24px;
}
.market__filters > * {
  flex-shrink: 0;
}
.market__filters .filters-show {
  width: 100%;
  flex-shrink: 1;
  justify-content: flex-end;
}
.active .select-btn .select-btn__text > img {
  transform: rotate(180deg);
}
.categories__btn {
  padding: 13px 20px;
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #272727;
  border: none;
  border-radius: 6px;
  position: relative;
  background: #fff;
  padding-right: 40px;
}

.select-btn {
  padding: 13px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
/*.categories__btn:after,*/
/*.categories__btn:before {*/
/*    content: "";*/
/*    width: 7px;*/
/*    height: 1px;*/
/*    background: #000;*/
/*    right: 16px;*/
/*    top: 50%;*/
/*    transform: translateY(-50%);*/
/*    position: absolute;*/
/*}*/

/*.categories__btn:after {*/
/*    transform: translateY(-50%) rotate(-40deg);*/
/*}*/

/*.categories__btn:before {*/
/*    right: 21px;*/
/*    transform: translateY(-50%) rotate(40deg);*/
/*}*/

.select-label {
  margin-bottom: 0;
  position: relative;
  order: 2;
  width: auto;
}

.select-label img {
  position: absolute;
  top: 50%;
  right: 50%;
  display: block;
  pointer-events: none;
  transform: translate(50%, -50%);
}

.select-label select {
  color: transparent;
  width: 50px;
  border: none;
}

.select-label option {
  color: #000;
}

.categories-slider-wrap {
  position: relative;
  background: #f9f9f9;
  border-radius: 5px;
}

.js-categories-slider {
  position: absolute;
  top: 60px;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  z-index: 50;
  max-height: 345px;
  overflow-y: auto;
  width: 100%;
  background: #fff;
}
.cooperation__tags .js-categories-slider {
  position: relative;
  top: unset;
  border-radius: 0%;
  box-shadow: none;
  max-height: unset;
  overflow: visible;
  background: none;
  display: flex;
  justify-content: flex-start;
}
.cooperation__tags .js-categories-slider .category-tag {
  width: auto;
}
.category-tag {
  display: block;
  width: 100%;
}

.category-tag > span:hover a {
  font-weight: 400;
}

.category-tag span {
  font-weight: 300;
  font-size: 16px;
  width: 100%;
}

.category-tag span a {
  box-shadow: none;
  background: none;
  display: block;
}

.category-tag .categories__subtitle {
  text-align: left;
}

.js-categories-slider.active {
  display: block;
}
.market-all-filters {
  display: block;
}
.market-all-filters {
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;
  z-index: 50;
  width: 320px;
  height: auto;
  overflow-y: auto;
}

.select-wrapper.button.select-btn:focus {
  outline: none;
}

@media (max-width: 767px) {
  .header-burger {
    order: 2;
  }
  .breadcrumbs-wrap h1.category-page__header {
    font-size: 35px;
  }

  .breadcrumbs-wrap h1 {
    font-weight: 450;
    font-size: 35px !important;
    line-height: 37px;
    margin-bottom: 6px;
  }

  .market-top {
    padding-bottom: 15px;
  }

  #posts-counter {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }

  .market-all-filters {
    right: 50%;
    transform: translateX(50%);
  }
  .open-filters.active {
    position: unset;
  }
  .advantages__block {
    padding: 43px 20px 52px;
  }
  .h1-container h1 {
    font-size: 35px;
  }
  .coopearation-form__form {
    position: unset;
  }
  .cooperation-form {
    position: relative;
  }
  .cooperation-form:not(.form-success) .cooperation-form-success {
    background: #6d30df;
  }
  .cooperation-form:not(.form-success)
    .cooperation-form-success
    .cooperation-form-success__title {
    color: #fff;
    opacity: 1;
  }
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 25px;
  }
  .advantages__title {
    font-size: 25px;
    text-align: left;
    line-height: 32px;
  }
  .advantages__subtitle {
    font-size: 20px;
    line-height: 26px;
  }
  .advantages__text {
    margin-top: 5px;
    font-size: 18px;
    line-height: 20px;
  }
  .partners__slide {
    padding-top: 0;
    padding-bottom: 0;
  }

  .advantages__wrapper {
    margin-top: 29px;
    row-gap: 30px;
  }
  .advantages-item {
    width: 100%;
  }

  .extra-text {
    font-size: 14px;
    line-height: 17px;
  }

  .list-table {
    top: 60px;
  }

  .nav-breadcrumbs {
    padding-left: calc(50vw - 270px);
    padding-right: calc(50vw - 270px);
  }

  .header__form button img,
  .header__form .close-search img {
    width: 100%;
  }

  .js-categories-main-scroll {
    grid-template-columns: repeat(2, 200px);
  }

  .opened-menu .header-menu::before {
    content: unset !important;
  }

  .opened-menu .header-menu {
    align-items: flex-start;
    height: auto;
    padding: 0;
    top: 59px;
    width: 100%;
    border-left: none;
    right: unset !important;
    left: 0;
    gap: 0;
    flex-direction: column;
  }

  .opened-menu ul.header-menu li {
    padding-left: 0;
    width: 100%;
  }

  .opened-menu ul.header-menu li a {
    padding: 22px 100px;
    width: 100%;
    border-bottom: 1px solid #fff;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
  }

  .opened-menu header ul.header-menu li {
    margin: 0;
  }

  body.opened-menu .mobile-menu-toggler {
    position: relative !important;
    right: unset !important;
    top: -5px !important;
  }

  .breadcrumbs-wrap b,
  .breadcrumbs-wrap h1 {
    font-size: 25px;
  }

  .market__descr {
    font-size: 14px;
    line-height: 15px;
  }

  .market__flex {
    flex-wrap: wrap;
  }
  .partners .owl-dot {
    background: #c4c4c4;
    width: 14px;
    height: 14px;
    border-radius: 100%;
  }
  .partners .owl-dot.active,
  .partners .owl-dot.active + .owl-dot {
    background: #6582fd;
  }
  .partners .owl-controls {
    text-align: center;
  }
  .partners .owl-dots {
    gap: 10px;
    display: inline-flex;
  }
}

.sorting__options.dropdown-active {
  visibility: visible !important;
  opacity: 1 !important;
}

#course_single {
  box-shadow: none;
  background: linear-gradient(90deg, #281940 99.99%, #7539a2 100%);
}
#course_single .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.85);
}
#course_single .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.85);
}
@media (max-width: 576px) {
  .advantages .container {
    padding-right: 0;
    padding-left: 0;
  }
  .plate-item__title {
    font-size: 20px;
  }
  .advantages__block {
    border-radius: 0;
  }
  .partners__title {
    font-size: 25px;
    line-height: 25px;
  }
  .partners__slider {
    margin-top: 28px;
  }
  .mobile-menu-toggler {
    width: 30px !important;
  }
  .partners__slide {
    width: auto;
  }

  .nav-breadcrumbs {
    box-shadow: none;
  }

  .nav-breadcrumbs {
    padding: 0;
  }

  .plate-listing li.plate-item {
    width: 100%;
  }

  .plate-img {
    left: 0;
    margin-left: 0;
    top: 0;
    object-fit: cover;
  }

  ul.plate-listing {
    gap: 0;
  }

  .plate-listing .plate-item + .plate-item {
    margin-top: 25px;
  }

  .plate-listing + .text-center {
    margin-top: 25px;
  }

  .login-btn {
    display: block;
    padding-top: 4px;
  }

  .login-btn-text {
    max-width: 39px;
  }

  header .user-link span {
    display: none !important;
  }

  body header {
    padding: 15px 0;
  }

  .breadcrumbs-wrap {
    margin-top: 0;
  }

  .categories__title {
    font-size: 25px !important;
    text-align: left;
    margin-bottom: 10px !important;
  }

  .js-categories-main-scroll {
    grid-template-columns: repeat(2, 1fr);
  }

  .category-item .categories-item__title {
    font-size: 18px;
  }

  .category-item .categories__count {
    font-size: 13px;
    column-gap: 40px;
    row-gap: 40px;
  }

  .categories__subtitle {
    font-size: 15px;
    text-align: left;
  }

  a.btn-custom,
  a.categories__link {
    padding: 0 !important;
    font-size: 20px !important;
    border: none !important;
    border-radius: 0;
    text-decoration: underline !important;
  }

  a.btn-custom:hover,
  a.categories__link:hover {
    background: transparent !important;
    color: initial !important;
  }

  .header__form .close-search {
    width: 20px;
    height: 20px;
  }

  .categories-item .category-item img {
    margin: 0 0 4px;
    width: 70px !important;
  }

  .mobile-menu-toggler div {
    height: 3px !important;
  }

  .plate-item__subtitle {
    font-size: 15px;
    margin-bottom: 4px;
  }
  .plate-item .course-desc {
    font-size: 17px;
    margin-top: 20px;
  }
  .plate-item-body .plate-item__title a {
    font-size: 18px;
  }
  .plate-item__flex {
    margin-top: 10px;
  }
  .plate-item-top {
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .plate-item .price-row {
    font-size: 20px;
    /* margin-top: 20px; */
  }
  .plate-item__more {
    font-size: 16px;
    color: #e66366;
  }

  .bot-wrapper .plate-item__more {
    color: #777777;
    display: flex;
    align-items: center;
  }
}
.categories__btn:focus {
  outline: none;
}
.categories__btn.active,
.open-filters.active .select-btn {
  box-shadow: 0px 0px 0px 1px #6582fd;
}

.categories__btn.active::before {
  transform: translateY(-50%) rotate(-40deg);
}
.categories__btn.active::after {
  transform: translateY(-50%) rotate(40deg);
}

/* Courses page*/

.course-wrapper {
  padding-top: 0;
  font-family: "Futura PT", Arial, sans-serif;
}
.course-container {
  margin-top: 56px;
  max-width: 800px;
}

.course-container h1 {
  padding-top: 12px;
  color: #272727;
  font-weight: 600;
  font-size: 48px;
  line-height: 1.2;
}
.course-rating {
  display: flex;
  align-items: center;
  color: #fed604;
  font-weight: 600;
  font-size: 23px;
  margin-top: 7px;
  column-gap: 7px;
  line-height: 1.3;
}
.course-rating > .rating__stars {
  margin-left: 7px;
}
.course-rating > .course-total {
  margin-left: 7px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1;
  color: #272727;
}
.school-page__rating .course-rating {
  margin-top: 0;
}
.course-author {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: #272727;
}
.course-author a {
  color: #272727;
  font-weight: 400;
  transition: 0.5s all;
}
.course-author a:hover {
  text-decoration: none;
  color: #777;
}
.course-table {
  margin-top: 30px;
}
.course-row {
  display: flex;
  column-gap: 6px;
}
.course-row + .course-row {
  margin-top: 8px;
}
.course-fixed .plate-item__mark {
  font-size: 16px;
}
.course-table-title {
  max-width: 344px;
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #272727;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}
.course-table-title:after {
  content: ".................................................";
  white-space: nowrap;
  display: inline-block;
  margin-left: 9px;
  font-size: 10px;
  letter-spacing: 0.35em;
}

.course-tag {
  font-size: 20px;
  line-height: 1.2;
  color: #272727;
}
.course-table-info {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.3;
  color: #272727;
}

.course-about-block__wrapper {
  padding: 20px 0 38px;
  position: relative;
  max-width: 784px;
  width: 100%;
  z-index: 1;
}
.course-about-block__wrapper:after {
  content: "";
  position: absolute;
  width: 125%;
  background: #f4f4f4;
  z-index: -1;
  border-radius: 10px;
  top: 0;
  height: 100%;
  right: 50%;
  transform: translateX(50%);
}
.courses-update {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.3;
  color: #272727;
}
.course-banner .container {
  position: relative;
}
.course-about-block {
  margin-top: 80px;
}
.course__tab.alone {
  font-size: 35px;
  font-weight: 600;
  color: #272727;
}
.course-programm-block__wrapper {
  max-width: 785px;
}
.course-fixed {
  max-width: 438px;
  width: 100%;
  position: absolute;
  z-index: 90;
  top: 0;
  right: 0;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
}
.course-fixed-grid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 27px;
}

.course-fixed.active {
  position: fixed !important;
  top: 29px !important;
  bottom: unset !important;
  right: calc(50vw - 690px) !important;
}
.course-fixed.absolute {
  top: unset;
  bottom: 0;
  right: 0;
}
.container-fx {
  position: relative;
}
.course-fixed-bg {
  height: 250px;
  display: block;
  width: 100%;
  object-fit: cover;
}
.course-fixed__body {
  padding: 20px;
}

.course-fixed__old {
  font-weight: 600;
  text-decoration: line-through;
  color: #777777;
  font-size: 18px;
  line-height: 1;
}
.course-fixed__price {
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
  color: #272727;
}
.header__search button,
.header__search input {
  outline: none !important;
}
.mobile-menu-toggler-side {
  display: none !important;
}
.block-center {
  display: none;
}

.course-fixed .alert-price {
  background: #a436f1;
}
.course-fixed-text {
  font-size: 12px;
  line-height: 1.3;
  color: #929292;
}
.course-fixed__installment {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.course-fixed__parts {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  min-width: 120px;
  color: #272727;
}
.course-fixed__info {
  font-size: 16px;
  color: #777777;
  line-height: 1.2;
}
.course-fixed__buttons {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.course-tosite {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 9px;
  padding: 10px 40px;
  border-radius: 10px;
  font-weight: 600;
  background: #e66165;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  line-height: 160%;
  transition: 0.5s all;
}
.course-tosite:hover {
  opacity: 0.8;
  text-decoration: none;
  color: #fff;
}
.course-tofavorite {
  width: 30px;
  position: absolute;
  top: 200px;
  right: 10px;
}
.course-tofavorite img {
  width: 100%;
}
.course-fixed__order {
  padding: 10px 60px;
  background: #a436f1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  border: none;
  color: #ffffff;
}
.course-fixed__caption {
  margin-top: 9px;
  font-size: 12px;
  line-height: 12px;
  color: #929292;
}
.course-learn {
  max-width: 800px;
  width: 100%;
  margin-top: 46px;
  padding: 24px 24px 46px;
  border: 1px solid rgba(196, 196, 196, 0.5);
  border-radius: 10px;
}
.course-learn__title {
  font-weight: 600;
  font-size: 25px;
  line-height: 1;
  color: #000000;
}
.course-list {
  margin-top: 19px;
  padding-left: 0;
  columns: 2;
  list-style-type: none;
}
.course-list > li {
  font-size: 15px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
}
.course-list > li:before {
  content: url("../img/check.svg");
  vertical-align: middle;
  margin-right: 13px;
}
.course-tabs-block {
  max-width: 725px;
  margin-top: 49px;
}
.course__tabs {
  display: flex;
}
.course__tab:focus {
  outline: none;
}
.course__tab {
  font-weight: 450;
  font-size: 25px;
  line-height: 1.2;
  background: none;
  border: none;
  color: #000000;
  padding: 0;
  position: relative;
}

.course__tab.active {
  color: #6d30df;
}

.course__tab.active:before {
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  position: absolute;
  background: #6d30df;
  bottom: -5px;
}
.course__tab + .course__tab {
  margin-left: 27px;
}

.course__blocks {
  margin-top: 25px;
}
.course__text {
  display: none;
  font-size: 16px;
  line-height: 1.56;
  margin-top: 5px;
  color: #737373;
}
.course-programm-block {
  margin-top: 55px;
}
.course__text ol {
  list-style-type: none;
  padding-left: 0;
}
.course__text.active {
  display: block;
}

.course__text .course-l-counter {
  font-weight: 450;
  font-size: 20px;
  line-height: 1.1;
  color: #272727;
  text-align: left;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  align-items: center;
  padding: 27px 0;
  background: none;
  border: none;
  border-top: 1px solid #d6d6d6;
}
.course__text .course-l-counter:focus {
  outline: none;
}
.course-programm-block .course__text > ol {
  border-bottom: 1px solid #d6d6d6;
}
.course__text-expand {
  display: none;
  padding-bottom: 25px;
}
.course__text-block.active .course__text-expand {
  display: block;
}
.course__text-block.active .course-l-counter span {
  border-color: #777;
  color: #777;
  transform: rotate(45deg);
}
.course__text-block {
  width: 100%;
}
.course__text .course-l-counter span {
  width: 30px;
  height: 30px;
  display: flex;
  line-height: 1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #272727;
  color: #272727;
  font-size: 32px;
  font-weight: 300;
  flex-shrink: 0;
}
.course-programm-block__wrapper .course__text {
  margin-top: 20px;
}

.course__text ol > li {
  display: flex;
}
.course__text ol > li img {
  width: 100%;
  margin-right: 20px;
  /* height: 100%; */
  max-height: 90px;
  max-width: 132px;
  object-fit: cover;
}

.course-tarrifs__block {
  margin-top: 23px;
}

.course__title {
  font-weight: 600;
  font-size: 35px;
  line-height: 1.2;
}

.comments-empty {
  color: #777777;
  margin-top: 20px;
  font-size: 16px;
}

.course-tarrif {
  height: 100%;
  width: 320px;
  border-radius: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
}

.course-tarrif__title {
  /* background: linear-gradient(90.01deg, #6D30DF 99.99%, #DB39FA 100%); */
  padding: 21px 20px 0;
  /* border-radius: 10px 10px 0 0; */
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  color: #272727;
}

.course-tarrif__advantages {
  list-style-type: disc;
  flex: 1;
  list-style-position: inside;
  padding: 0 20px;
  font-size: 18px;
  color: #737373;
  margin-top: 20px;
}

.course-tarrif__price {
  margin-top: 22px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  position: relative;
  line-height: 26px;
  padding: 0 20px;
}

.course-tarrif__price * {
  display: block;
}
.course-tarrif__price .old {
  margin-top: 12px;
  font-size: 16px;
  text-decoration: line-through;
  line-height: 1;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
}
.course-tarrif__price .new {
  font-size: 20px;
  margin-top: 29px;
  color: #272727;
  text-align: left;
}
.course-tarrif__price .old + .new {
  margin-top: 8px;
}
.course-tarrif__price:after {
  position: absolute;
  content: "";
  width: 70%;
  left: 20px;
  top: 0;
  height: 2px;
  background: rgba(164, 54, 241, 0.5);
}

.course-tarrif__btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: #fff;
  display: none;
  padding: 11px 24px;
  background: #6d30df;
  pointer-events: none;
  border: none;
  border-radius: 10px;
  position: absolute;
  right: 50%;
  text-decoration: none;
  transform: translateX(50%);
  bottom: -20px;
}
.course-tarrif__btn:hover {
  color: #fff;
  text-decoration: none;
}
.course-tarrifs__block {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.course-teachers__block {
  margin-top: 100px;
  max-width: 896px;
  padding-right: 30px;
}
.course-wrapper .course-teachers__block {
  max-width: 100%;
  margin-top: 50px;
  padding: 0;
}
.course__teachers {
  margin-top: 46px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.course-teacher {
  /* width: 385px; */
  display: flex;
}
img.course-teacher__img {
  width: 117px !important;
  height: 117px;
  border-radius: 100%;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 18px;
}
.course-teacher__name {
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}
.course-teacher__descr {
  margin-top: 2px;
  font-size: 15px;
  line-height: 1.1;
}
.course-teacher__text {
  margin-top: 14px;
  font-size: 16px;
  line-height: 125%;
  color: #737373;
}
.course-reviews {
  max-width: 800px;
}

.course-reviews__flex {
  margin-top: 32px;
  display: flex;
}
.course-reviews__ratings {
  margin-left: 32px;
  width: 100%;
}

.course-reviews__number {
  font-weight: 600;
  font-size: 63px;
  line-height: 41%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #fed604;
}
.course-reviews__number + .rating__stars {
  width: 100px;
  margin-top: 25px;
  height: 18px;
}
.course-reviews__number + .rating__stars .rating__stars_empty {
  width: 100%;
  height: 100%;
}

.course-total {
  font-size: 14px;
  line-height: 186%;
  text-align: center;
}
.course-reviews__rating {
  display: flex;
  align-items: center;
}

.course-reviews__rating .line {
  max-width: 392px;
  height: 9px;
  background: #f1f1f1;
  width: 100%;
  position: relative;
  flex-shrink: 0;
}

.course-reviews__rating .line + .rating__stars {
  margin-left: 20px;
}

.course-reviews__rating .line + .rating__stars + .course-review__total {
  margin-left: 17px;
}
.line-total {
  position: absolute;
  left: 0;
  height: 100%;
  background: #b7b7b7;
}

.comments-block {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  border-top: 1px solid rgba(187, 187, 187, 0.5);
  margin-top: 40px;
}
.comments-block ~ .comments-block {
  border-top: unset;
  margin-top: 0;
}
.comments-item {
  display: flex;
}

.comments-item__avatar {
  margin-right: 23px;
  flex-shrink: 0;
  width: 43px;
  height: 43px;
  object-fit: cover;
  border-radius: 100%;
}

.comments-item__name {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.5;
}

.comments-item__rating {
  display: flex;
  margin-top: 4px;
  align-items: center;
}

.comments-item__date {
  margin-left: 15px;
  font-size: 14px;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.5);
}

.comments-item__text {
  margin-top: 4px;
  font-size: 15px;
  line-height: 1.5;
}
.comments-item__own-rating {
  margin-top: 20px;
}

.comments-item__like,
.comments-item__dislike {
  background: none;
  position: relative;
  border: none;
  padding: 0;
}
.comments-item__complain,
.comments-item__reply {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  margin-left: 33px;
  color: rgba(0, 0, 0, 0.5);
}
a.comments-item__reply {
  color: #737373;
  margin-left: 16px;
  font-weight: normal;
}
.comments-item__like-total {
  font-size: 10px;
  line-height: 1;
  vertical-align: bottom;
  color: rgba(0, 0, 0, 0.3);
}

.comments-item__form {
  padding-left: 24px;
  margin-top: 18px;
  display: flex;
  position: relative;
}

.comments-item__form:before {
  content: url("../img/reply.svg");
  top: -5px;
  position: relative;
}

.comments-item__form .comments-item__input {
  /* width: 100%; */
  width: 100%;
  margin-left: 3px;
  border: 1px solid #bdbdbd;
  border-radius: 15px 3px 15px 15px;
  padding: 14px 100px 14px 17px;
}

.comments-item__btn {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 2px solid #bdbdbd;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s all;
}

.comments-item__btn:focus {
  background: #6582fd;
  border: 2px solid #6582fd;
  outline: none;
}
.comments-item__btn svg path {
  transition: 0.5s all;
}
.comments-item__btn:focus svg path {
  fill: #fff;
}

.comments-item__more {
  background: none;
  border: none;
  font-size: 15px;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.5);
}

.comments-item__extra {
  margin-top: 17px;
  display: flex;
}

.comments-item__extra-message {
  background: #f0f0f0;
  border-radius: 3px 15px 15px 15px;
  padding: 10px 11px 15px 15px;
  max-width: 382px;
}
.comments-item__extra-body {
  width: 100%;
}
.comments-item__extra-name {
  font-weight: 450;
  font-size: 15px;
  color: #000;
  line-height: 26px;
}

.comments-item__school {
  margin-left: 60px;
}

.comments-item__user {
  margin-left: 120px;
}

.comments-add {
  margin-top: 32px;
  background: #f4f4f4;
  padding: 19px 24px 23px;
  border-radius: 10px;
}

.comments-add__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.6;
  color: #000;
}

.comments-add__rating {
  display: flex;
  margin-top: 13px;
  align-items: center;
}

.comments-add__rating .rating__group {
  margin-left: 16px;
  position: relative;
  width: 125px;
  height: 22px;
  position: relative;
}
.comments-add__rating .rating__group:before {
  content: "";
  position: absolute;
  background-image: url("https://kurs-market.com/img/star.png");
  background-repeat: repeat-x;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(335deg) brightness(93%)
    contrast(86%);
  width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: auto 104%;
  background-position: 0;
}
.comments-add__caption {
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  color: #000;
}
.rating__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.rating__star {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  height: 22px;
  background-size: 25px 22px;
  background-repeat: repeat-x;
}
.rating__input:checked + .rating__star,
.rating__star:hover {
  background-image: url("https://kurs-market.com/img/star.png");
}
.rating__star:hover ~ .rating__star {
  background-image: url("https://kurs-market.com/img/star.png");
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(335deg) brightness(93%)
    contrast(86%);
}
.rating__star:nth-of-type(1) {
  z-index: 5;
  width: 25px;
}
.rating__star:nth-of-type(2) {
  z-index: 4;
  width: 50px;
}
.rating__star:nth-of-type(3) {
  z-index: 3;
  width: 75px;
}
.rating__star:nth-of-type(4) {
  z-index: 2;
  width: 100px;
}
.rating__star:nth-of-type(5) {
  z-index: 1;
  width: 125px;
}

.comments-add-label {
  margin-top: 14px;
  display: block;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  color: #000;
}

.comments-add-textarea {
  resize: none;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  border: none;
  height: 128px;
}

.coments-add-center {
  text-align: center;
  margin-top: 23px;
}

.comments-add__btn {
  border: none;
  padding: 7px 35px;
  background: #e66165;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  transition: 0.5s all;
}
.comments-add__btn:hover {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
}
.courses-other {
  margin-top: 158px;
}

.courses-other__title {
  text-align: left;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.4;
  color: #000000;
}
.courses-other .plate-listing {
  margin-top: 29px;
}
.course__teachers {
  position: relative;
}
.course__teachers .owl-prev,
.course__teachers .owl-next,
.school-page__articles-wrapper .owl-prev,
.school-page__articles-wrapper .owl-next {
  position: absolute;
  background: none;
  top: 50%;
  transform: translateY(-50%);
}

.course__teachers .owl-prev,
.school-page__articles-wrapper .owl-prev {
  left: -60px;
}

.course__teachers .owl-next,
.school-page__articles-wrapper .owl-next {
  right: -60px;
}
.course-reviews {
  margin-top: 134px;
}
.course__teachers .owl-dot,
.school-page .owl-dot {
  background: #c4c4c4;
  width: 14px;
  height: 14px;
  border-radius: 100%;
}
.school-page .owl-dot.active {
  background: #6582fd;
}
.school-page .owl-controls {
  text-align: center;
}
.course__teachers .owl-dot.active,
.course__teachers .owl-dot.active + .owl-dot,
.school-page__articles-wrapper .owl-dot.active,
.school-page__articles-wrapper .owl-dot.active + .owl-dot {
  background: #6582fd;
}
.course__teachers .owl-controls,
.school-page__articles-wrapper .owl-controls {
  text-align: center;
  margin-top: 50px;
}
.course__teachers .owl-dots,
.school-page .owl-dots {
  gap: 10px;
  display: inline-flex;
  flex-wrap: wrap;
}

.comments-more.active {
  display: block;
}
.comments-item__form {
  display: none;
}

.comments-item__form.active {
  display: flex;
}
.course-table-info {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.cooperation {
  font-family: "Futura PT", Arial, sans-serif;
}
.cooperation-title {
  margin-top: 75px;
  font-weight: 450;
  font-size: 50px;
  line-height: 1.2;
  color: #000000;
}
.cooperation__wrapper {
  display: flex;
  gap: 77px;
}
.cooperation__text {
  margin-top: 12px;
  font-size: 25px;
  line-height: 1.3;
  color: #7c7c7c;
}
.cooperation__tags .js-categories-slider {
  column-gap: 15px;
  row-gap: 12px;
}
.cooperation__tags .category-tag a {
  border-radius: 14px;
}

.cooperation__link {
  margin-top: 18px;
  text-align: right;
  display: block;
  text-decoration: underline;
  font-weight: 450;
  font-size: 20px;
  line-height: 1.2;
  color: #6582fd;
}
.cooperation-advantages {
  margin-top: 100px;
  background: #efefef;
  border-radius: 45px;
  padding: 79px 44px;
}

.cooperation__subtitle {
  font-weight: 450;
  font-size: 50px;
  line-height: 1.25;
  color: #000000;
}

.cooperation-advantages__wrapper {
  display: flex;
  margin-top: 56px;
  gap: 40px;
}
.cooperation-advantages__title {
  font-weight: 450;
  font-size: 30px;
  line-height: 1.3;
  color: #000000;
}
.cooperation-advantages__item {
  width: 30%;
}

.cooperation-advantages__text {
  margin-top: 9px;
  font-weight: 300;
  font-size: 25px;
  line-height: 1.3;
}

.cooperation-info {
  display: flex;
  margin-top: 67px;
  align-items: center;
  gap: 25px;
}

.cooperation-info .cooperation__text {
  max-width: 1015px;
}

.cooperation-options {
  margin-top: 130px;
}
.cooperation__variants {
  margin-top: 37px;
  display: flex;
  gap: 28px;
}
.cooperation-item {
  width: 31%;
  background: #ffffff;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}
.cooperation-item__header {
  padding: 24px;
  text-align: center;
  font-weight: 450;
  font-size: 30px;
  line-height: 1.3;
  color: #ffffff;
  background: linear-gradient(90.01deg, #6d30df 99.99%, #db39fa 100%);
}
.cooperation-item:nth-child(2) .cooperation-item__header {
  background: linear-gradient(90.01deg, #6d30df 0.01%, #db39fa 100%);
}
.cooperation-item:nth-child(3) .cooperation-item__header {
  background: linear-gradient(90.01deg, #6d30df 0.01%, #db39fa 0.02%);
}
.cooperation__body {
  padding: 50px 24px;
}
.cooperation-main > ul {
  padding-left: 18px;
  min-height: 122px;
}
.cooperation-main > ul > li {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
}
#courses-list {
  width: 100%;
  margin-bottom: 60px;
}
.cooperation-item__title {
  margin-top: 74px;
  font-size: 20px;
  line-height: 1.3;
  color: #000000;
}
.cooperation-item__text {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.1;
  color: #000000;
  margin-top: 5px;
}
.cooperation-item__text + .cooperation-item__text {
  margin-top: 10px;
}

.cooperation__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cooperation-item {
  display: flex;
  flex-direction: column;
}
.cooperation-footer {
  margin-top: auto;
  min-height: 200px;
  position: relative;
  padding: 36px 20px 51px;
  text-align: center;
}
.cooperation-footer:before {
  content: "";
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 0;
  width: 58%;
  height: 1px;
  background: #000;
}
.cooperation-footer__title {
  font-size: 20px;
  line-height: 1.3;
  color: #000000;
}
.cooperation-footer__text {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.1;
  margin-top: 13px;
  color: #000000;
}

.cooperation-form {
  background: #6d30df;
  margin: 160px 0 0;
  width: 100%;
  padding: 94px 20px 140px;
  z-index: 10;
}
.coopearation-form__form {
  max-width: 1120px;
  margin: 0 auto;
  padding: 78px 100px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 45px;
}

.select-btn {
  border: none;
  display: flex;
  column-gap: 10px;
}
.select-btn__text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  color: #272727;
  font-weight: 300;
  column-gap: 10px;
  text-decoration: none;
  font-family: "Futura PT";
}
.select-wrapper {
  position: relative;
}
.select-items {
  position: absolute;
  top: 60px;
  background: #fff;
  display: none;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-family: "Futura PT";
  z-index: 100;
}
.select-items label {
  margin-bottom: 0;
  width: 100%;
}
.select-wrapper.active .select-btn {
  box-shadow: 0 0 0 1px #6582fd;
}
.select-wrapper .select-btn:focus,
.open-filters .select-btn:focus {
  outline: none;
}
.select-wrapper.active .select-items {
  display: block;
}
.select-items label .select-text {
  padding: 5px 20px;
  font-size: 18px;
  color: #000;
  display: block;
  width: 100%;
  font-weight: 300;
}
.select-items label .select-text:hover {
  font-weight: 400;
}

.select-items label input {
  opacity: 0;
  visibility: hidden;
  width: 0;
  position: absolute;
}
.select-items label input:checked + .select-text {
  font-weight: 400;
}
.coopearion-form__title {
  font-weight: 450;
  font-size: 60px;
  line-height: 1.3;
  color: #000000;
}
.cooperation-form__text {
  margin-top: 3px;
  font-size: 25px;
  line-height: 1.3;

  color: #777777;
}
.cooperation-form__block {
  width: 45%;
  flex-shrink: 0;
  margin-right: 78px;
}
.cooperation-form__inputs {
  margin-top: 30px;
}
.cooperation-form__input {
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px;
  border: none;
  width: 100%;
}
.cooperation-form__wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.cooperation-form__wrapper + .cooperation-form__wrapper {
  margin-top: 40px;
}
.cooperation-form__privacy {
  width: 48%;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.12;
  color: #000000;
}
.cooperation-form__btn {
  padding: 15px 34px;
  background: #e66165;
  border-radius: 10px;
  font-weight: 600;
  font-size: 19px;
  line-height: 1.3;
  border: none;
  margin-left: 16px;
  color: #ffffff;
}
.cooperation-form__wrapper .cooperation-form__input {
  width: 48%;
}
@media (max-width: 1382px) {
  .course-fixed.active {
    right: calc(50vw - 563px) !important;
  }
  .course-learn {
    max-width: 695px;
  }
  .course-tarrifs,
  .course-teachers__block,
  .course-learn,
  .course-reviews,
  .course-container {
    max-width: 670px;
  }
  .course-reviews__rating .line {
    max-width: 320px;
  }
}

@media (max-width: 1185px) {
  .course-tarrifs,
  .course-teachers__block,
  .course-learn,
  .course-reviews,
  .course-container,
  .course-tabs-block {
    max-width: 580px;
  }
  .cooperation-item__header {
    font-size: 24px;
  }
  .course-fixed.active {
    right: calc(50vw - 475px) !important;
  }
  .course-tarrifs__block {
    flex-wrap: wrap;
  }
  .course-tarrif {
    margin-top: 23px;
  }
  .course-tarrifs__block {
    margin-top: 0;
  }
  .course-reviews__flex {
    flex-wrap: wrap;
  }
  .course-reviews__ratings {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media (max-width: 993px) {
  .course-tarrifs,
  .course-teachers__block,
  .course-learn,
  .course-reviews,
  .course-container,
  .course-tabs-block {
    max-width: 100%;
  }
  .course-fixed,
  .course-fixed.active {
    position: relative !important;
    top: unset !important;
    bottom: unset !important;
    right: unset !important;
    max-width: 100%;
  }
  .course-fixed-bg {
    display: none;
  }
  .course-fixed {
    box-shadow: none;
    overflow: visible;
  }
  .course-fixed .alert-price {
    top: -30px;
  }
  .course-fixed__buttons {
    margin-top: 20px;
  }
  .course-learn {
    margin-top: 34px;
  }
  .course-fixed__body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .course-tabs-block,
  .course-teachers__block,
  .course-reviews,
  .course-tarrifs {
    padding-left: 0;
  }
  .course-tarrifs .owl-dot {
    background: #c4c4c4;
    width: 14px;
    height: 14px;
    border-radius: 100%;
  }
  .course-tarrifs .owl-dot.active {
    background: #6582fd;
  }
  .course-tarrifs .owl-controls {
    text-align: center;
  }
  .course-tarrifs .owl-dots {
    gap: 10px;
    display: inline-flex;
  }
  .course-reviews__rating .line {
    max-width: 300px;
  }

  .cooperation-title {
    font-size: 35px;
    margin-top: 16px;
  }
  .cooperation__wrapper {
    flex-wrap: wrap;
    gap: 43px;
  }
  .cooperation__text {
    width: 100%;
  }
  .cooperation__wrapper .js-categories-slider {
    display: none;
  }
  .cooperation__link {
    text-align: center;
  }
  .cooperation__tags {
    width: 100%;
    text-align: center;
  }
  .cooperation__text {
    font-size: 18px;
    /* line-height: 21px; */
  }
  .cooperation-advantages {
    padding: 35px 20px;
  }
  .cooperation__subtitle {
    font-size: 25px;
  }
  .cooperation-advantages__wrapper {
    flex-wrap: wrap;
    gap: 0;
    margin-top: 0;
  }
  .cooperation-info {
    align-items: flex-start;
    margin-top: 50px;
  }
  .cooperation-options {
    margin-top: 76px;
  }
  .cooperation__variants {
    flex-wrap: wrap;
    margin-top: 29px;
  }
  .cooperation-item {
    width: 100%;
  }
  .cooperation-item__header {
    padding: 12px;
    font-size: 20px;
  }
  .cooperation-info .cooperation__text {
    margin-top: 0;
  }
  .cooperation-info img {
    width: 40px;
    height: 40px;
  }
  .cooperation-advantages__item {
    width: 100%;
    margin-top: 29px;
  }
  .cooperation-advantages__title {
    font-size: 20px;
  }
  .cooperation-advantages__text {
    font-size: 18px;
    margin-top: 5px;
  }
  .cooperation__body {
    padding: 30px 10px 40px;
  }
  .cooperation-main > ul > li {
    font-size: 18px;
  }
  .cooperation-main > ul {
    min-height: auto;
  }
  .cooperation-item__title {
    margin-top: 20px;
    font-size: 18px;
  }
  .cooperation-item__text {
    font-size: 18px;
  }
  .cooperation-footer {
    min-height: auto;
    padding: 20px 0 30px;
    margin: 0 10px;
  }
  .cooperation-footer__title {
    font-size: 18px;
  }
  .cooperation-footer__text {
    margin-top: 9px;
    font-size: 16px;
  }
  .cooperation-form {
    margin-top: 49px;
    padding: 22px 20px 60px;
  }
  .coopearation-form__form {
    background: none;
    padding: 0;
    display: block;
  }
  .cooperation-form__block {
    width: 100%;
    margin-right: 0;
  }
  .coopearion-form__title {
    font-size: 30px;
    text-align: center;
    color: #fff;
  }
  .cooperation-form__text {
    margin-top: 2px;
    text-align: center;
    font-size: 20px;
    color: #f4f4f4;
  }
  .cooperation-form__wrapper {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .cooperation-form__privacy {
    order: 2;
    width: 100%;
    font-size: 16px;
    color: #fff;
    margin-top: 18px;
    text-align: center;
  }
  .cooperation-form__btn {
    margin: 0 auto;
    background: none;
    font-size: #fff;
    border: 2px solid #fff;
  }
  .cooperation-form__wrapper .cooperation-form__input {
    width: 100%;
    margin-top: 20px;
  }
  .cooperation-footer::before {
    width: 100%;
  }
}
.courses-more {
  display: none;
}
#courses-list .show-more-btn {
  display: none;
}
.market__filters {
  position: relative;
}
@media (max-width: 576px) {
  .course-container h1 {
    font-size: 35px;
    line-height: 1;
  }
  .cooper-cont {
    padding: 0;
  }
  .cooperation-advantages {
    border-radius: 0;
  }
  .course-table {
    margin-top: 35px;
  }
  .course-table .course-row:first-child {
    flex-wrap: wrap;
  }
  .course-fixed__buttons .course-tofavorite {
    display: none;
  }
  .course-table-title {
    max-width: 160px;
  }

  .course-fixed__body {
    padding-top: 20px;
  }
  .course-fixed-text {
    font-size: 11px;
  }
  .course-tosite {
    width: 100%;
  }
  .course-learn {
    padding: 0;
    border: none;
  }

  .course-learn .course-list {
    columns: 1;
  }
  .course__text ol > li {
    flex-wrap: wrap;
  }
  .course__text ol > li img {
    margin-top: 10px;
    order: 2;
  }
  .courses-more {
    display: block;
    background: none;
    border: none;
    font-weight: 450;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-decoration-line: underline;
    color: #6582fd;
  }

  /* .block-center {
        display: flex;
        justify-content: center;
    }
    .courses-more {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 10;
        transform: translateX(-50%);
    } */
  .course__text-more {
    height: auto;
    overflow: visible;
  }
  .course__text-more:after {
    display: none;
  }
  .course__text-more .courses-more {
    bottom: -30px;
  }
  .course-tarrif {
    margin: 23px 15px;
  }
  .course-teachers__block {
    margin-top: 80px;
  }
  .course__teachers {
    margin-top: 30px;
  }
  img.course-teacher__img {
    width: 70px !important;
    height: 70px;
  }
  .course-reviews {
    margin-top: 108px;
  }
  .rating__stars,
  .course-total {
    display: inline-block;
  }
  .course-reviews__number + .rating__stars {
    width: 90px;
    height: 17px;
  }
  .course-reviews__number {
    text-align: left;
  }
  .course-review__total {
    display: none;
  }
  .course-reviews__rating .line {
    width: 200px;
  }
  .course-reviews__rating + .course-reviews__rating {
    margin-top: 20px;
  }
  .comments-item__avatar {
    display: none;
  }
  .comments-item__school {
    margin-left: 30px;
  }
  .comments-item__user {
    margin-left: 60px;
  }
  /* .comments-add__rating .rating__group {
        width: 200px;
        height: 38px;
    }
    .comments-add__rating .rating__star {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        height: 38px;
        background-size: 40px 38px;
        background-repeat: repeat-x;
    }
    .comments-add__rating .rating__star:nth-of-type(1) {
        z-index: 5;
        width: 40px;
    }
    .comments-add__rating .rating__star:nth-of-type(2) {
        z-index: 4;
        width: 80px;
    }
    .comments-add__rating .rating__star:nth-of-type(3) {
        z-index: 3;
        width: 120px;
    }
    .comments-add__rating .rating__star:nth-of-type(4) {
        z-index: 2;
        width: 160px;
    }
    .comments-add__rating .rating__star:nth-of-type(5) {
        z-index: 1;
        width: 200px;
    } */
}

/*School page*/

.school-page {
  font-family: "Futura PT", Arial, sans-serif;
}

.school-page__title {
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
}
.school-page__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.school-page__left {
  max-width: 785px;
}
.school-page-event {
  width: 248px;
  position: relative;
  padding: 20px 20px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.21);
  border-radius: 15px;
}
.school-page-event_main {
  width: 326px;
  padding: 22px 26px;
  border-radius: 6px;
}

.school-page-event__date {
  font-size: 14px;
  line-height: 1.2;
  color: #737373;
  display: flex;
  justify-content: space-between;
}
.school-page-event_main .school-page-event__date > * + * {
  margin-left: 20px;
}
.school-page-event_main .school-page-event__date {
  font-size: 16px;
  display: block;
  font-weight: 600;
}

.school-page-event__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.12;
  color: #000000;
  margin-bottom: 7px;
  margin-top: 9px;
}
.school-page-event_main .school-page-event__title {
  font-size: 25px;
  margin-top: 15px;
}
.school-page-event__row {
  display: flex;
  gap: 3px;
  margin-top: 3px;
}
.school-page-event__header {
  font-size: 14px;
  line-height: 1;
  color: #000000;
}
.school-page-event_main .school-page-event__header,
.school-page-event_main .school-page-event__text {
  font-size: 16px;
}
.school-page-event__descr {
  font-size: 14px;
  line-height: 1.25;
  margin-top: 20px;
  color: #737373;
}
.school-page-event_main .school-page-event__descr {
  font-size: 16px;
  margin-top: 13px;
}
.school-page-event__text {
  font-size: 14px;
  line-height: 1;
  color: #737373;
}

.school-page-event_main .course-tarrif__btn {
  position: relative;
  transform: none;
  display: block;
  bottom: 0;
  border: none;
  right: 0;
  margin: 28px auto 0;
  background: #e66165;
}

.school-page .course-table {
  margin-top: 15px;
}

.school-page .course-table-title {
  color: #000;
  font-size: 16px;
}
.school-page__scale {
  display: flex;
  align-items: center;
}
.school-page__rating {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 60px;
}
.school-page__rating-total {
  font-weight: 600;
  font-size: 23px;
  line-height: 29px;
  color: #fed604;
  margin-right: 8px;
}
.school-page__reviews-link a {
  color: #272727;
  font-weight: 400;
  font-size: 20px;
  text-decoration: underline;
}
.school-page__scale .course-total {
  margin-left: 12px;
}
.school-page .course-table-info {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.school-page .course-table-info a {
  color: #6e30e0;
  text-decoration: underline;
  font-weight: 600;
  font-size: 16px;
}

.school-page__about {
  margin-top: 60px;
}

.school-page__about-title {
  font-weight: 600;
  font-size: 25px;
  line-height: 1.3;
  color: #000000;
}
.school-page__about-wrapper {
  margin-top: 9px;
}

.school-page__avatar {
  background-repeat: no-repeat;
  background-size: 100%;
  flex-shrink: 0;
}

.school-page__descr {
  font-size: 16px;
  line-height: 1.5;
  color: #737373;
}
.school-page-events__wrapper {
  display: flex;
  gap: 30px;
  margin-top: 35px;
  flex-wrap: wrap;
}
.school-page__events {
  background: #efefef;
  margin-top: 83px;
  padding: 30px 0 70px;
}
.school-page__subtitle {
  font-weight: 600;
  font-size: 35px;
  line-height: 1.3;
  color: #272727;
}

.school-page__subtitle span {
  font-weight: 300;
  font-size: 25px;
  line-height: 1.3;
  color: #777777;
  margin-left: 10px;
}

.school-page-event__price {
  text-align: center;
  margin-top: 12px;
}

.school-page-event__price .old {
  font-weight: 600;
  font-size: 13px;
  line-height: 9px;
  text-decoration-line: line-through;
  color: rgba(0, 0, 0, 0.5);
}

.school-page-event__price .new {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-top: 3px;
}

.school-page-events__wrapper .school-page-event .course-tarrif__btn {
  border: none;
}
.school-page-events__wrapper
  .school-page-event:nth-child(5n + 2)
  .course-tarrif__btn {
  background: linear-gradient(90deg, #6d30df 0.34%, #db39fa 99.7%);
}

.school-page-events__wrapper
  .school-page-event:nth-child(5n + 3)
  .course-tarrif__btn {
  background: linear-gradient(90deg, #db39fa 99.69%, #6d30df 99.7%);
}

.school-page-events__wrapper
  .school-page-event:nth-child(5n + 4)
  .course-tarrif__btn {
  background: linear-gradient(270deg, #6d30df 0.3%, #db39fa 99.66%);
}

.school-page__courses {
  margin-top: 30px;
}

.school-page__courses .filters-wrap {
  margin-top: 0px;
  width: auto;
  margin-right: 0px;
}

.school-page__courses .filters-wrap > .container {
  display: block;
  gap: 0;
}

.school-page__courses .market__filters {
  flex-direction: row;
}

.school-page__courses .category-tag a {
  background: #ffffff;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding: 5px 13px;
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: inline-block;
}

.school-page__courses .market-all-filters {
  position: absolute;
  top: 60px;
  left: 0;
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 15%);
  border-radius: 5px;
  padding: 15px;
  z-index: 50;
  width: 320px;
  height: auto;
  overflow-y: auto;
  display: block;
}

.school-page__courses .category-tag span a {
  display: inline-block;
  box-shadow: none;
  border-radius: 0;
  font-weight: 300;
  padding-left: 20px;
  background: none;
  padding-right: 5px;
  font-size: 18px;
  line-height: 24px;
}

.schools-wrapper + .filters-wrap {
  margin-top: 0px;
  width: auto;
  margin-right: 0px;
}

.schools-wrapper + .filters-wrap > .container {
  display: block;
  gap: 0px;
}

.schools-wrapper + .filters-wrap > .container > .market__filters {
  flex-direction: row;
}

.schools-wrapper
  + .filters-wrap
  > .container
  > .market__filters
  .category-tag
  span
  a {
  display: inline-block;
  box-shadow: none;
  border-radius: 0;
  font-weight: 300;
  padding-left: 20px;
  background: none;
  padding-right: 5px;
  font-size: 18px;
  line-height: 24px;
}

.schools-wrapper
  + .filters-wrap
  > .container
  > .market__filters
  .category-tag
  a {
  background: #ffffff;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding: 5px 13px;
  font-family: "Futura PT", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: inline-block;
}

.school-page__courses .plate-listing {
  margin-top: 35px;
}
.school-page__show-more {
  font-weight: 450;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  text-decoration-line: underline;
  color: #6582fd;
  display: inherit;
  margin-top: 50px;
}

.school-page:before {
  display: none;
}
.static-page-wrap .owl-stage-outer .course-teacher {
  white-space: initial;
}

.school-page .course-teacher {
  flex-wrap: wrap;
  justify-content: center;
}

.school-page img.course-teacher__img {
  margin-right: 0;
}

.school-page .course-teacher__block {
  margin-top: 14px;
  text-align: center;
  width: 100%;
}

.school-page .owl-next {
  right: -50px;
}

.school-page__teachers {
  margin-top: 70px;
}
.school-page .course__teachers .owl-dot.active,
.course__teachers .owl-dot.active + .owl-dot {
  background: #6582fd;
}
.school-page .course__teachers .owl-dot.active,
.course__teachers .owl-dot.active + .owl-dot,
.school-page__articles-wrapper .owl-dot.active + .owl-dot + .owl-dot,
.school-page__articles-wrapper
  .owl-dot.active
  + .owl-dot
  + .owl-dot
  + .owl-dot {
  background: #6582fd;
}

.school-page__articles {
  margin-top: 70px;
}
.school-page-article {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.school-page-article img {
  width: 100%;
  object-fit: cover;
  height: 100px;
}

.school-page-article__title {
  margin-top: 13px;
  padding: 0 15px;
  font-weight: 450;
  font-size: 16px;
  line-height: 1.1;
  color: #000000;
}
.school-page-article__wrapper {
  padding: 30px 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.school-page__articles-wrapper {
  margin-top: 31px;
}
.school-page-article__stats {
  display: flex;
  align-items: center;
  gap: 7px;
}
.school-page-article__stats > * {
  display: flex;
  align-items: center;
}

.school-page-article__stats > * > span {
  font-size: 10px;
  line-height: 1;
  color: #737373;
}

.school-page-arcticle__link {
  font-weight: 450;
  font-size: 14px;
  line-height: 1.1;
  color: #000000;
}

@media (max-width: 1200px) {
  .school-page__left {
    width: 60%;
  }
  .user-link a {
    display: block;
    white-space: nowrap;
  }
  .select-btn__text {
    font-size: 14px;
  }
  .school-page__events .school-page-event {
    width: unset;
    margin-bottom: 20px;
  }
  .static-page-wrap .owl-stage-outer .school-page-event {
    white-space: initial;
  }
  .school-page__courses ul.plate-listing {
    column-gap: 90px;
  }
  .course__teachers .owl-dot.active + .owl-dot {
    background: #c4c4c4;
  }
}

@media (max-width: 997px) {
  .school-page__courses ul.plate-listing {
    column-gap: 190px;
  }

  .school-page-event_main {
    display: none;
  }
  .school-page__left {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .school-page__rating {
    order: 2;
    margin-top: 10px;
  }
  .school-page__left .course-table {
    order: 3;
  }
  .school-page__about {
    order: 4;
    margin-top: 35px;
  }
  .school-page__about-title {
    /* display: none; */
  }
  .course__teachers .owl-dot.active + .owl-dot + .owl-dot,
  .school-page__articles-wrapper .owl-dot.active + .owl-dot + .owl-dot,
  .school-page__articles-wrapper
    .owl-dot.active
    + .owl-dot
    + .owl-dot
    + .owl-dot,
  .course__teachers .owl-dot.active + .owl-dot + .owl-dot + .owl-dot {
    background: #c4c4c4;
  }
}
.school-page-article + .school-page-article {
  margin-top: 20px;
}
.artcles-more {
  display: none;
}

.question-wrapper {
  position: fixed;
  bottom: 30%;
  left: 0;
  z-index: 1000;
  display: flex;
  font-family: "Futura PT", Arial, sans-serif;
}
.question-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 0 5px 5px 0;
  background: #6e30e0;
}

.question-form {
  width: 320px;
  padding: 20px;
  position: absolute;
  left: 40px;
  top: 0;
  background: #f9f9f9;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: none;
}
.question-wrapper.active .question-form {
  display: block;
}
.question-form__close {
  position: absolute;
  right: 15px;
  top: 15px;
  background: none;
  border: none;
}

.question-form__text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.question-form__btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 20px;
  width: 100%;
  background: #6e30e0;
  display: block;
  /* height: 50px; */
  border-radius: 10px;
  text-align: center;
  padding: 9px;
}
.question-form__btn:hover {
  color: #fff;
}
.question-form__label {
  margin: 11px 0 0;
}
.question-form__label span {
  margin-left: 6px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
}
@media (max-width: 767px) {
  .school-page__rating {
    display: block;
  }

  .opened-menu header ul.header-menu li {
    padding: 0;
  }
  .course-tarrif__label {
    width: 100%;
  }
  .course-tarrif__label {
    padding-bottom: 0;
  }
  .question-btn {
    display: none;
  }
  .select-btn__text {
    display: none;
  }
  .select-sort-mb {
    display: block;
  }
  .categories__btn {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .select-wrapper {
    position: unset;
  }
  .select-items {
    width: calc(100% - 30px);
    margin: 0 15px;
    left: 0;
  }
  .question-form {
    position: relative;
    width: 100%;
    left: 0;
    padding: 20px 30px;
  }
  .question-wrapper {
    bottom: 0;
  }
  .school-page__reviews-link {
    margin-top: 5px;
  }
  .school-page .course-table-title {
    max-width: auto;
    width: unset;
    margin-right: 10px;
  }
  .school-page__avatar {
    width: 105px;
    height: 64px;
  }
  .school-page__descr {
    font-size: 17px;
  }
  .school-page__events {
    margin-top: 35px;
  }
  .school-page__events .owl-nav {
    margin-top: 40px;
  }
  .school-page__courses ul.plate-listing {
    column-gap: 10px;
  }
  .course__teachers .owl-dot.active + .owl-dot,
  .school-page__articles-wrapper .owl-dot.active + .owl-dot {
    background: #c4c4c4;
  }
  .artcles-more {
    display: block;
  }
}

@media (max-width: 767px) {
  .school-page__rating {
    display: block;
  }
  .school-page__reviews-link {
    margin-top: 5px;
  }
  .school-page .course-table-title {
    max-width: auto;
    width: unset;
    margin-right: 10px;
  }
  .school-page__avatar {
    width: 105px;
    height: 64px;
  }
  .school-page__descr {
    font-size: 17px;
  }
  .school-page__events {
    margin-top: 35px;
  }
  .school-page__events .owl-nav {
    margin-top: 40px;
  }
  .school-page__courses ul.plate-listing {
    column-gap: 10px;
  }
  .course__teachers .owl-dot.active + .owl-dot,
  .school-page__articles-wrapper .owl-dot.active + .owl-dot {
    background: #c4c4c4;
  }
  .artcles-more {
    display: block;
  }
}

ul.plate-listing {
  margin-top: 0;
  margin-bottom: 20px !important;
}
.market-description span {
  font-size: 25px !important;
  font-family: "Futura PT" !important;
  background: none !important;
  color: #7c7c7c !important;
  max-width: 844px;
  margin-top: 12px;
  display: block;
  margin-bottom: 77px;
}

@media (max-width: 993px) {
  .market-description {
    display: none;
  }
}

footer {
  background: #272727;
  font-family: "Futura PT", sans-serif;
}
a.footer-logo {
  background-image: url(/assets/img/logo-footer.png);
  width: 130px;
}

.footer-col-1 {
  display: flex;
  flex-direction: column;
}

footer .copyright {
  font-weight: 400;
  margin-top: 0;
  font-size: 16px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
}

.footer__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

footer > .container > div.row {
  justify-content: space-between;
}

@media (max-width: 991px) {
  footer > .container > div.row {
    justify-content: flex-start;
  }
}

.footer__nav ul {
  padding-left: 0;
  margin: 15px 0 0;
  list-style-type: none;
}

.footer__nav ul li {
  margin-top: 10px;
}
footer a {
  color: #ffffff;
  font-size: 16px;
  line-height: 21px;
  transition: 0.5s all;
}
footer a:hover {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.footer-item {
  margin-top: 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.footer__flex {
  display: flex;
  column-gap: 15px;
  align-items: center;
}
.footer__flex .footer-item {
  margin-top: 10px;
}

.footer__subtitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 20px;
}

.footer-item ~ .footer-item:not(.footer-lock) {
  margin-top: 10px;
}

footer .row > .col-md-2:last-child {
  padding: 0;
}

.footer-lock {
  margin-top: 40px;
  margin-bottom: 7px;
}

.footer-lock__text {
  font-size: 16px;
  line-height: 1;
  max-width: 166px;
  color: rgba(255, 255, 255, 0.5);
}

footer .row {
  row-gap: 40px;
}

.cashback {
  margin-top: 35px;
  font-family: "Futura PT";
}
.cashback__info {
  max-width: 784px;
  font-size: 16px;
  line-height: 1.3;
  color: #272727;
}
.cashback__info span {
  font-weight: 700;
  font-size: 25px;
}

.cashback__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
  margin: 50px 0 0;
  list-style-type: none;
  counter-reset: li;
  column-gap: 40px;
}

.cashback__list li {
  font-weight: 450;
  font-size: 20px;
  line-height: 1.3;
  position: relative;
  color: #272727;
  display: flex;
  column-gap: 17px;
  padding-top: 10px;
}
.cashback__list li:before {
  content: counter(li);
  font-size: 96px;
  color: #272727;
  font-weight: 600;
  transform: translateY(-54px);
  padding-top: 10px;
  counter-increment: li;
}
.cashback__list li:after {
  content: url(/assets/img/shape1.svg);
  position: absolute;
  top: 0;
  left: -10px;
  z-index: 0;
}
.cashback__list li:nth-child(4n + 1):after {
  content: url(/assets/img/shape1.svg);
}
.cashback__list li:nth-child(4n + 2):after {
  content: url(/assets/img/shape2.svg);
}
.cashback__list li:nth-child(4n + 3):after {
  content: url(/assets/img/shape3.svg);
}
.cashback__list li:nth-child(4n):after {
  content: url(/assets/img/shape4.svg);
}
.cashback__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 1.3;
  color: #272727;
}

.cashback__block {
  margin-top: 95px;
  max-width: 784px;
}
.cashback__subtitle {
  font-weight: 600;
  font-size: 25px;
  line-height: 1.3;
  color: #272727;
}
.cashback__subtitle ~ .cashback__subtitle {
  margin-top: 40px;
}
.cashback__text ul {
  padding: 0 0 0 22px;
}
.cashback__text ul li {
  color: #777777;
  line-height: 1.25;
}
.cashback__text ul li + li {
  margin-top: 5px;
}
.cashback__ul {
  list-style-type: none;
  padding: 0;
  padding-left: 0px;
  margin: 5px 0 0;
}
.cashback__ul li {
  font-size: 16px;
  line-height: 1.25;
  color: #7c7c7c;
  position: relative;
}
.cashback__ul li span {
  display: block;
  font-weight: 700;
  color: #272727;
}
.cashback__ul li + li {
  margin-top: 25px;
}

.cashback__ul li:before {
  margin-top: 3px;
  content: url(/assets/img/ul-shape-marker.svg);
  position: absolute;
  left: -29px;
  top: -10px;
}

.cashback__text {
  color: #777777;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 5px;
  margin-bottom: 0;
}

.cashbacks-study__block {
  padding: 48px 115px 67px;
  background: #ededed;
  border-radius: 15px;
  font-family: "Futura PT";
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: auto 323px;
  column-gap: 65px;
  row-gap: 17px;
  grid-template-areas:
    "header header"
    "text btn";
}
.cashback-study {
  margin-top: 50px;
}

.cashback-study__title {
  grid-area: header;
  font-size: 48px;
  font-weight: 700;
  line-height: 54%;
  color: #272727;
}
.cashback-study__text {
  font-size: 20px;
  line-height: 1.25;
  color: #7c7c7c;
  margin-bottom: 0;
}

.cashback-btn {
  padding: 12px 29px;
  background: #e66165;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  height: 50px;
  transition: 0.5s all;
  color: #ffffff;
}

.cashback-btn:hover {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: #fff;
}
@media (max-width: 992px) {
  .cashback__list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
  .cashbacks-study__block {
    padding: 36px 30px;
  }
}
a.js-legal-info {
  color: #272727;
}
@media (max-width: 767px) {
  footer {
    padding-top: 15px;
    padding-bottom: 32px;
    text-align: left;
  }
  .cashback-study {
    margin-top: 35px;
  }
  .cashback__block {
    margin-top: 70px;
  }
  .footer-col-1 {
    order: 5;
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
  a.footer-logo {
    margin-bottom: 0;
    background-image: url(/assets/img/logo-white.png);
  }
  .footer__title {
    font-size: 20px;
  }
  .footer__nav ul {
    margin-top: 0;
  }
  .footer__flex {
    display: block;
  }
  footer .footer-lock {
    margin-top: 43px;
  }
  .footer__nav ul li {
    margin-top: 7px;
  }
  .footer__nav ul li a {
    font-size: 20px;
  }
  footer .copyright {
    color: #fff;
    font-size: 20px;
  }
  .cashback__title {
    font-size: 35px;
    line-height: 1;
  }
  .cashback__info {
    font-size: 16px;
  }
  .cashback__list {
    grid-template-columns: 100%;
  }
  .cashbacks-study__block {
    grid-template-columns: 100%;
    grid-template-areas: "header" "text" "btn";
  }
}
.sub-subcategories-wrap-wrapper {
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .cashback__ul {
    padding-left: 0;
  }
  .cashback-study .container {
    padding: 0;
  }
  .cashbacks-study__block {
    border-radius: 0;
    padding: 30px 20px;
    row-gap: 10px;
    margin-bottom: 0;
  }
  .cashback-btn {
    padding: 12px 20px;
    height: auto;
    margin-top: 20px;
  }
}

.plate-item__abs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 280px;
  margin-bottom: 22px;
}

@media (max-width: 768px) {
  .plate-item__abs {
    max-width: unset;
  }
}

.plate-item__abs > div,
.plate-item__abs > span {
  height: 23px !important;
}

.to-fav {
  filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.25));
}

.to-fav svg path {
  transition: 0.5s all;
  fill: transparent;
}

.to-fav:hover svg path {
  fill: #c736e2;
  stroke: none;
}

.plate-item__mark {
  font-family: "Futura PT";
  font-weight: 400;
  font-size: 16px;
  width: max-content;
  padding: 0 5px;
  line-height: 18px;
  border-radius: 4px;
  color: #777777;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plate-item__mark.cache_back {
  background: rgba(230, 99, 102, 0.2);
}

.plate-item__mark.promo {
  display: flex;
  flex-direction: row;
  gap: 3px;
  background: #eff1e9;
}

.plate-item__mark.credit {
  background: #eff1e9;
}

.plate-item__mark.free {
  background: #f8f3dc;
}

.plate-listing-empty {
  color: #7c7c7c;
  font-size: 25px;
  line-height: 1.3;
  text-align: center;
  font-family: "Futura PT";
  width: 100%;
  margin-top: 80px;
}

.plate-item__abs .cache_back {
  background-color: #ebebf5;
  border-radius: 3px !important;
}

.plate-item__abs .alert-price {
  background: #eff1e9;
  border-radius: 3px !important;
}

.pagination-links {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  font-family: "Futura PT", Arial, sans-serif;
}

.page-numbers {
  font-size: 18px;
  color: #000000;
  margin-top: 50px;
}

a.page-numbers:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.7;
}

.page-numbers.current {
  background: #e66165;
  width: 28px;
  height: 28px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
}

.school-page__title .school-page__verify {
  display: inline-block;
  margin-left: 5px;
}
.school-page__title .school-page__verify svg {
  vertical-align: super;
}

.school-page__website {
  font-size: 20px;
  line-height: 1.2;
  color: #272727;
}
.school-page__website:hover {
  color: #777;
  text-decoration: none;
}
.school-page__rating {
  margin-top: 8px;
}
.school-page__about {
  margin-top: 20px;
}
.school-page__descr {
  font-size: 18px;
}
.school-page__avatar {
  width: 325px;
  border-radius: 10px;
  max-height: 325px;
  object-fit: contain;
  border: 1px solid #dbdbdb;
}
.school-page__courses .market__filters {
  justify-content: flex-start;
  column-gap: 25px;
}
.school-page__courses .market__filters * {
  flex-shrink: 0;
}

.school-page__courses .market__filters .filters-show {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 1;
}

.school-page__right {
  padding: 20px;
  height: fit-content;
  margin-right: 110px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
}

.school-page__about-title {
  font-weight: 600;
  font-size: 18px !important;
  line-height: 23px;
  color: #272727;
}
.school-page__about-title.js-legal-info {
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 5px;
}
.school-page__about-title.js-legal-info:hover {
  color: #777;
}
.school-search-wrapper {
  width: 100%;
  position: relative;
  margin-top: 36px;
}
.school-search-wrapper input {
  background: #ffffff;
  border-radius: 5px;
  padding: 12px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.44;
  width: 100%;
  border: none;
  color: #777777;
}
.school-search-results {
  position: absolute;
  top: 53px;
  width: 100%;
  height: auto;
  max-height: 156px;
  z-index: 50;
  overflow-y: auto;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 20px;
}
.school-search-results label {
  display: block;
  cursor: pointer;
}
.school-search-results label input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
}
.school-search-results label:hover span {
  color: #777;
}
.school-search-results label + label {
  margin-top: 10px;
}
.school-search-results label span {
  display: inline-block;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #272727;
}

.cashback-wrapper {
  display: flex;
  justify-content: space-between;
  font-family: "Futura PT", Arial, sans-serif;
}

.cashback-wrapper .btn-get-money {
  background: #db39fa;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  transition: 0.5s all;
}
.cashback-wrapper .btn-get-money[disabled] {
  background: #bebebe;
  opacity: 1;
}
.cashback-wrapper .btn-get-money:hover {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
}
.cashback-wrapper .btn-get-money:focus {
  outline: none;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
}
.cashback-cards {
  display: grid;
  grid-template-columns: repeat(3, 180px);
  gap: 15px;
}
.cashback-cards .card {
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.card-tooltip {
  position: relative;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  cursor: default;
  color: #db39fa;
}
.cashback-cards .card-body {
  padding: 7px 15px 9px;
}
.card-tooltip:hover .card-tooltip__text {
  opacity: 1;
  visibility: visible;
}
.cashback-cards .card-body__wrapper {
  display: flex;
  column-gap: 5px;
}
.cashback-cards .card-title {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
}
.cashback-cards .card-text {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #000;
}
.card-tooltip__text {
  opacity: 0;
  visibility: hidden;
  left: 10px;
  z-index: 20;
  margin: 0;
  transition: 0.5s all;
  top: 0;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 14px;
  width: 223px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  position: absolute;
}
.cashback-table {
  margin: 0;
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-family: "Futura PT", Arial, sans-serif;
}
.cashback-table thead th,
.cashback-table td {
  border: 1px solid #cccccc;
}
.cashback-table thead th {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.cashback-table td {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.cashback-table td p {
  margin: 0;
}
.cashback-table td.status {
  font-weight: 400;
}
.cashback-table__wrapper {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 993px) {
  .market-description {
    display: none;
  }
  .cashback-wrapper {
    display: block;
  }
  .cashback-cards {
    grid-template-columns: 100%;
    column-gap: 10px;
  }
  .cashback-wrapper .btn-get-money {
    padding: 18px;
    width: 100%;
    margin-top: 10px;
  }
  .cashback-table__wrapper {
    overflow-x: auto;
    scrollbar-color: #db39fa #cccccc;
    scrollbar-width: thin;
  }
  .cashback-table {
    width: 1200px;
    margin-bottom: 25px;
  }
  /* width */
  .cashback-table__wrapper::-webkit-scrollbar {
    height: 3px;
  }

  /* Track */
  .cashback-table__wrapper::-webkit-scrollbar-track {
    background: #cccccc;
  }

  /* Handle */
  .cashback-table__wrapper::-webkit-scrollbar-thumb {
    background: #db39fa;
  }
}

@media (max-width: 767px) {
  .cashback-table thead tr th:first-child {
    width: 150px;
  }
  .cashback-table thead tr th:nth-child(2) {
    width: 80px;
  }
  .cashback-table {
    width: 600px;
  }
  .cashback-table tbody tr td:first-child > p {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
  }
  .cashback-table tbody tr td:nth-child(2) > p {
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
  }
}

.sort-block .img-vis ~ img {
  opacity: 1;
  visibility: visible;
}

.sort-block:has(.img-vis) {
  font-weight: 450;
  color: #272727;
}

.sort-block img {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 1399px) {
  .filters-show {
    margin-left: 0px;
  }
  ul.plate-listing {
    column-gap: 75px;
  }
}
.categories-slider-wrap .categories__btn {
  width: 300px;
}
.categories-slider-wrap .categories__btn .select-btn__text {
  width: 100%;
}
@media (max-width: 1200px) {
  .open-filters .select-btn__text,
  .select-wrapper .select-btn__text {
    display: none;
  }
  .open-filters .select-btn img {
    height: 24px;
    width: 24px;
  }
  .filters-show .select-btn__text {
    display: block;
  }
  .filters-show {
    margin-left: 10px;
  }
  .categories__btn {
    padding: 13px 10px;
  }
  ul.plate-listing {
    column-gap: 50px;
  }
}

@media (max-width: 992px) {
  .market__filters {
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 24px;
  }
  .market__filters .filters-show {
    margin-left: 0;
    width: auto;
  }
  .filters-show__text {
    padding-left: 0;
  }

  .school-page__courses .market__filters .filters-show {
    display: none;
  }
}

@media (max-width: 767px) {
  .filters-show {
    display: none;
  }
  .categories-slider-wrap .categories__btn {
    width: 211px;
  }
  .categories-slider-wrap .select-btn__text {
    display: flex;
    font-size: 18px;
  }
  .select-btn {
    padding: 13px 10px;
  }
  .school-page__courses .market__filters {
    column-gap: 15px;
  }
  .market-wrap .plate-listing li.plate-item {
    width: 100%;
    height: auto;
  }

  .market-wrap .plate-listing li.plate-item .bot-wrapper {
    height: auto;
    gap: 30px;
  }
  .plate-item .course-desc {
    min-height: auto;
  }
}

@media (max-width: 998px) {
  .header__left .header-menu {
    margin-right: 0 !important;
    background: white;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px !important;
  }
  .header__left .header-menu {
    height: 70px;
    margin: 0;
  }

  .header_controls-container {
    min-width: 100%;
    gap: 0;
  }
  .filters-wrap > .container {
    padding: 0;
  }
  .filters-wrap > .container > .school-page__subtitle {
    padding: 0 15px;
  }
  .filters-wrap > .container .plate-listing {
    padding: 0 15px;
  }

  .market__filters {
    padding: 10px 15px;
  }
  .course-fixed .plate-item__abs {
    position: relative;
    top: unset;
    flex-direction: row;
    margin-top: 45px;
  }
  .course-fixed .plate-item__mark {
    border-radius: 5px;
  }
}

.plate-item .plate-item-body {
  background: #ffffff;
}

.school-page__subtitle + .market__filters {
  margin-top: 15px;
}
.school-teachers {
  column-gap: 70px;
  justify-content: flex-start;
}
.school-teachers .course-teacher {
  max-width: 250px;
}

.school-teachers .owl-dot.active + .owl-dot + .owl-dot + .owl-dot {
  background: #6582fd;
}

.school-teachers .owl-dot.active + .owl-dot + .owl-dot {
  background: #6582fd;
}

@media (max-width: 1400px) {
  .school-page__right {
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .school-teachers .owl-dot.active + .owl-dot + .owl-dot + .owl-dot {
    background: #c4c4c4;
  }

  .school-teachers .owl-dot.active + .owl-dot + .owl-dot {
    background: #c4c4c4;
  }
  .school-teachers .owl-dot.active + .owl-dot {
    background: #6582fd;
  }
  .school-teachers .course-teacher {
    max-width: 100%;
  }
}

@media (max-width: 967px) {
  .school-page__courses ul.plate-listing {
    column-gap: 50px;
  }

  .school-page__wrapper {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .school-page .plate-listing li.plate-item {
    width: 100%;
  }
  .school-teachers .owl-dot.active + .owl-dot {
    background: #c4c4c4;
  }
  .school-page__courses {
  }
  .course-banner .course-container {
    margin-top: 30px;
  }
  .course-container h1 {
    font-size: 35px;
  }
  .course-author {
    font-size: 18px;
    margin-top: 15px;
  }
  .course-rating > .course-total {
    font-size: 16px;
  }
  .course-table {
    margin-top: 25px;
  }
  .course-table-title {
    max-width: unset;
    width: auto;
    overflow: visible;
    font-size: 18px;
  }
  .course-table-info {
    row-gap: 5px;
    font-size: 18px;
  }
  .courses-update {
    margin-top: 20px;
  }
  .course-fixed .plate-item__mark {
    font-size: 14px;
  }
  .course-fixed-grid {
    grid-template-columns: 100%;
  }
  .course-table-title:after {
    display: none;
  }
  .course-about-block {
    margin-top: 50px;
  }
  .course-about-block {
    padding: 0 0 30px;
  }
  .course__tab.alone {
    font-size: 25px;
  }
  .course__text {
    font-size: 18px;
  }
  .course-wrapper .course-teacher.solo-teacher img.course-teacher__img {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .course-programm-block {
    margin-top: 10px;
  }
  .course__text .course-l-counter {
    font-size: 18px;
    padding: 17px 0;
  }
  .course__text-expand {
    font-size: 18px;
  }
  .course-wrapper .course-teacher.solo-teacher {
    display: block;
  }
  .course-tariffs-block {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  .course-tariffs-block .course__title {
    text-align: left;
    font-size: 25px;
  }
  .course-wrapper .course__title {
    font-size: 25px;
  }
  .course-tariffs-block .course-tarrifs__block {
    margin-top: 15px;
    flex-wrap: nowrap;
    column-gap: 20px;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-bottom: 35px;
    scrollbar-width: thin;
    scrollbar-color: #6582fd #b7b7b7;
  }
  .course-tariffs-block .course-tarrifs__block::-webkit-scrollbar {
    width: 3px;
  }

  .course-tariffs-block .course-tarrifs__block::-webkit-scrollbar-track {
    background: #b7b7b7;
  }
  .course-tariffs-block .course-tarrifs__block::-webkit-scrollbar-thumb {
    background: #6582fd;
  }

  .course-tarrif {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 320px;
  }
  .course-wrapper .course-reviews {
    margin-top: 97px;
  }
  .courses-other {
    display: none;
  }
  .course-wrapper {
    padding-bottom: 0;
  }
  .comments-add {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
  }
  .comments-add:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 150%;
    height: 100%;
    background: #f4f4f4;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

.course-page__breadcrumbs .nav-breadcrumbs {
  box-shadow: none;
}
.course-tariffs-block {
  background: #f4f4f4;
  margin-top: 80px;
  padding: 43px 0 90px;
}

.course-tarrifs .course__title {
  text-align: center;
  font-size: 35px;
}
.course-tarrifs__block {
  justify-content: center;
}
@media (max-width: 767px) {
  .course-tarrifs .course__title {
    text-align: left;
    font-size: 25px;
  }
  .course-tariffs-block {
    background: #f4f4f4;
    margin-top: 60px;
    padding: 30px 0 60px;
  }
  .cashback__info span {
    font-size: 16px;
  }
  .cashback__list {
    margin-top: 80px;
  }
  .cashback__list li::after {
    top: -30px;
    left: -40px;
    z-index: 0;
    transform: scale(0.5);
  }
  .cashback__subtitle {
    line-height: 1.12;
  }
  .cashback__ul {
    margin-top: 20px;
  }
  .cashback__list li::before {
    transform: translateY(-20px);
    line-height: 0.8;
  }
  .cashback-study__title {
    line-height: 1;
  }
  .cashbacks-study__block {
    padding: 20px 13px 50px;
  }
  .cashback-study__text {
    font-size: 18px;
  }
  .cashback-btn {
    text-align: center;
    margin: 10px 15px 0;
  }
}

.course-wrapper .course-teacher.solo-teacher img.course-teacher__img {
  width: 323px !important;
  background-color: lightgray;
  border-radius: unset;
  height: 400px;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__name {
  font-size: 20px;
  color: #272727;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__descr {
  font-size: 16px;
  margin-top: 5px;
  color: #272727;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__text {
  font-size: 16px;
  margin-top: 15px;
  padding-top: 30px;
  border-top: 1px solid #d6d6d6;
  color: #737373;
}

.course-wrapper .course-teacher.solo-teacher {
  width: 785px;
}

.course-wrapper .course-teacher:not(.solo-teacher) {
  width: 210px;
  display: block;
  position: relative;
}
.course-teacher__info {
  position: absolute;
  right: 15px;
  top: 220px;
  width: 30px;
  height: 30px;
  background: #f4f4f4;
  border-radius: 50%;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.course-teacher__popup {
  position: absolute;
  background: #f4f4f4;
  width: 300px;
  z-index: 5;
  top: 0;
  height: min-content;
  opacity: 0;
  visibility: hidden;
  height: 260px;
  transition: 0.5s all;
  max-width: 90vw;
  right: 50%;
  transform: translateX(50%) translateY(40px);
  padding: 36px 20px;
}
.course-teacher.active .course-teacher__popup {
  opacity: 1;
  visibility: visible;
  transform: translateX(50%) translateY(0);
}
.course-teacher__popup .course-teacher__text {
  font-size: 16px;
  color: #272727;
  margin-top: 0;
}

.course-teacher__close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
}
.course-wrapper .course-teacher:not(.solo-teacher) img.course-teacher__img {
  width: 100% !important;
  margin: 0;
  height: 260px;
  object-fit: cover;
  border-radius: unset;
  background: lightgray;
  display: block;
}
.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__block {
  padding-top: 13px;
}
.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__name {
  font-size: 20px;
  color: #272727;
}

.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__descr {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #d6d6d6;
  font-size: 16px;
  color: #272727;
}
@media (max-width: 1185px) {
  .course-banner .course-container {
    max-width: 530px;
  }
  .course-fixed {
    max-width: 388px;
  }
  .course__teachers {
    gap: 15px;
  }
}
@media (max-width: 991px) {
  .course-wrapper .course-teacher.solo-teacher {
    display: block;
  }
  .course-tariffs-block {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  .course-tariffs-block .course__title {
    text-align: left;
    font-size: 25px;
  }
  .course-wrapper .course__title {
    font-size: 25px;
  }
  .course-tariffs-block .course-tarrifs__block {
    margin-top: 15px;
    flex-wrap: nowrap;
    column-gap: 20px;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-bottom: 35px;
    scrollbar-width: thin;
    scrollbar-color: #6582fd #b7b7b7;
  }
  .course-tariffs-block .course-tarrifs__block::-webkit-scrollbar {
    width: 3px;
  }

  .course-tariffs-block .course-tarrifs__block::-webkit-scrollbar-track {
    background: #b7b7b7;
  }
  .course-tariffs-block .course-tarrifs__block::-webkit-scrollbar-thumb {
    background: #6582fd;
  }

  .course-tarrif {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 320px;
  }
  .course-wrapper .course-reviews {
    margin-top: 97px;
  }
  .courses-other {
    display: none;
  }
  .course-wrapper {
    padding-bottom: 0;
  }
  .comments-add {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
  }
  .comments-add:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 150%;
    height: 100%;
    background: #f4f4f4;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

.course-page__breadcrumbs .nav-breadcrumbs {
  box-shadow: none;
}
.course-tariffs-block {
  background: #f4f4f4;
  margin-top: 80px;
  padding: 43px 0 90px;
}

.course-tarrifs .course__title {
  text-align: center;
  font-size: 35px;
}
.course-tarrifs__block {
  justify-content: center;
}
@media (max-width: 767px) {
  .course-tarrifs .course__title {
    text-align: left;
    font-size: 25px;
  }
  .course-tariffs-block {
    background: #f4f4f4;
    margin-top: 60px;
    padding: 30px 0 60px;
  }
  .cashback__info span {
    font-size: 16px;
  }
  .cashback__list {
    margin-top: 80px;
  }
  .cashback__list li::after {
    top: -30px;
    left: -40px;
    z-index: 0;
    transform: scale(0.5);
  }
  .cashback__subtitle {
    line-height: 1.12;
  }
  .cashback__ul {
    margin-top: 20px;
  }
  .cashback__list li::before {
    transform: translateY(-20px);
    line-height: 0.8;
  }
  .cashback-study__title {
    line-height: 1;
  }
  .cashbacks-study__block {
    padding: 20px 13px 50px;
  }
  .cashback-study__text {
    font-size: 18px;
  }
  .cashback-btn {
    text-align: center;
    margin: 10px 15px 0;
  }
}

.course-wrapper .course-teacher.solo-teacher img.course-teacher__img {
  width: 323px !important;
  background-color: lightgray;
  border-radius: unset;
  height: 400px;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__name {
  font-size: 20px;
  color: #272727;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__descr {
  font-size: 16px;
  margin-top: 5px;
  color: #272727;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__text {
  font-size: 16px;
  margin-top: 15px;
  padding-top: 30px;
  border-top: 1px solid #d6d6d6;
  color: #737373;
}

.course-wrapper .course-teacher.solo-teacher {
  width: 785px;
}

.course-wrapper .course-teacher:not(.solo-teacher) {
  width: 210px;
  display: block;
  position: relative;
}
.course-teacher__info {
  position: absolute;
  right: 15px;
  top: 220px;
  width: 30px;
  height: 30px;
  background: #f4f4f4;
  border-radius: 50%;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.course-teacher__popup {
  position: absolute;
  background: #f4f4f4;
  width: 300px;
  z-index: 5;
  top: 0;
  height: min-content;
  opacity: 0;
  visibility: hidden;
  height: 260px;
  transition: 0.5s all;
  max-width: 90vw;
  right: 50%;
  transform: translateX(50%) translateY(40px);
  padding: 36px 20px;
}
.course-teacher.active .course-teacher__popup {
  opacity: 1;
  visibility: visible;
  transform: translateX(50%) translateY(0);
}
.course-teacher__popup .course-teacher__text {
  font-size: 16px;
  color: #272727;
  margin-top: 0;
}

.course-teacher__close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
}
.course-wrapper .course-teacher:not(.solo-teacher) img.course-teacher__img {
  width: 100% !important;
  margin: 0;
  height: 260px;
  object-fit: cover;
  border-radius: unset;
  background: lightgray;
  display: block;
}
.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__block {
  padding-top: 13px;
}
.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__name {
  font-size: 20px;
  color: #272727;
}

.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__descr {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #d6d6d6;
  font-size: 16px;
  color: #272727;
}
@media (max-width: 1185px) {
  .course-banner .course-container {
    max-width: 530px;
  }
  .course-fixed {
    max-width: 388px;
  }
  .course__teachers {
    gap: 15px;
  }
}
@media (max-width: 991px) {
  .course-wrapper .course-teacher.solo-teacher {
    width: 100%;
  }
  .course-wrapper .course__teachers {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 30px;
  }
  .course-wrapper .course-teacher:not(.solo-teacher) {
    flex-shrink: 0;
  }
  .course-wrapper .course__teachers {
    padding-bottom: 35px;
    overflow-x: auto;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #6582fd #b7b7b7;
  }
  .course-wrapper .course__teachers ::-webkit-scrollbar {
    width: 3px;
  }

  .course-wrapper .course__teachers ::-webkit-scrollbar-track {
    background: #b7b7b7;
  }
  .course-wrapper .course__teachers ::-webkit-scrollbar-thumb {
    background: #6582fd;
  }
  .course-teacher__popup {
    top: 0;
    bottom: unset;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .course-fixed__body .course-tofavorite {
    display: none;
  }
}

.subcategories-wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.sub-subcategories-wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.subcategories-wrap .category-tag,
.sub-subcategories-wrap .category-tag {
  width: auto;
  flex-shrink: 0;
}
.subcategories-wrap .category-tag span a {
  padding: 3px 12px;
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: auto;
}
.subcategories-wrap .category-tag.active span a {
  box-shadow: 0px 0px 1px 1px #6582fd;
}

.sub-subcategories-wrap .category-tag span a {
  padding: 0;
}
.subcategories-wrap .category-tag span a:hover {
  font-weight: 300;
  box-shadow: 0px 0px 1px 1px #6582fd;
}
.sub-subcategories-wrap .category-tag span a:hover {
  font-weight: 300;
  color: #6582fd;
}
@media (max-width: 575px) {
  .subcategories-wrap {
    margin-left: 20px;
    margin-top: 20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: calc(100% - 20px);
    padding-bottom: 15px;
    scrollbar-width: thin;
    padding-right: 20px;
    scrollbar-color: #6582fd #cccccc;
  }
  .subcategories-wrap::-webkit-scrollbar {
    width: 3px;
  }
  .subcategories-wrap .category-tag span a {
    padding: 0;
    box-shadow: unset;
  }
  .subcategories-wrap .category-tag span a:hover {
    box-shadow: unset;
    color: #6582fd;
  }

  .subcategories-wrap::-webkit-scrollbar-track {
    background: #cccccc;
  }
  .subcategories-wrap::-webkit-scrollbar-thumb {
    background: #6582fd;
  }
  .sub-subcategories-wrap {
    margin-left: 20px;
    scrollbar-width: thin;
    padding-bottom: 15px;
    padding-top: 10px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-right: 20px;
    width: calc(100% - 20px);
    position: relative;
    scrollbar-color: #c736e2 #cccccc;
  }
  .sub-subcategories-wrap::-webkit-scrollbar-track {
    background: #cccccc;
  }
  .sub-subcategories-wrap::-webkit-scrollbar-thumb {
    background: #c736e2;
  }
  .sub-subcategories-wrap-wrapper {
    background: #f6f6f6;
  }
}
.course-tarrifs__block {
  justify-content: center;
}
@media (max-width: 767px) {
  .course-tarrifs .course__title {
    text-align: left;
    font-size: 25px;
  }
  .course-tariffs-block {
    background: #f4f4f4;
    margin-top: 60px;
    padding: 30px 0 60px;
  }
  .cashback__info span {
    font-size: 16px;
  }
  .cashback__list {
    margin-top: 80px;
  }
  .cashback__list li::after {
    top: -30px;
    left: -40px;
    z-index: 0;
    transform: scale(0.5);
  }
  .cashback__subtitle {
    line-height: 1.12;
  }
  .cashback__ul {
    margin-top: 20px;
  }
  .cashback__list li::before {
    transform: translateY(-20px);
    line-height: 0.8;
  }
  .cashback-study__title {
    line-height: 1;
  }
  .cashbacks-study__block {
    padding: 20px 13px 50px;
  }
  .cashback-study__text {
    font-size: 18px;
  }
  .cashback-btn {
    text-align: center;
    margin: 10px 15px 0;
  }
}

.course-wrapper .course-teacher.solo-teacher img.course-teacher__img {
  width: 323px !important;
  background-color: lightgray;
  border-radius: unset;
  height: 400px;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__name {
  font-size: 20px;
  color: #272727;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__descr {
  font-size: 16px;
  margin-top: 5px;
  color: #272727;
}

.course-wrapper .course-teacher.solo-teacher .course-teacher__text {
  font-size: 16px;
  margin-top: 15px;
  padding-top: 30px;
  border-top: 1px solid #d6d6d6;
  color: #737373;
}

.course-wrapper .course-teacher.solo-teacher {
  width: 785px;
}

.course-wrapper .course-teacher:not(.solo-teacher) {
  width: 210px;
  display: block;
  position: relative;
}
.course-teacher__info {
  position: absolute;
  right: 15px;
  top: 220px;
  width: 30px;
  height: 30px;
  background: #f4f4f4;
  border-radius: 50%;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.course-teacher__popup {
  position: absolute;
  background: #f4f4f4;
  width: 300px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all;
  bottom: 100px;
  max-width: 90vw;
  right: 50%;
  transform: translateX(50%) translateY(40px);
  padding: 36px 20px;
}
.course-teacher.active .course-teacher__popup {
  opacity: 1;
  visibility: visible;
  transform: translateX(50%) translateY(0);
}
.course-teacher__popup .course-teacher__text {
  font-size: 16px;
  color: #272727;
  margin-top: 0;
}

.course-teacher__close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
}
.course-wrapper .course-teacher:not(.solo-teacher) img.course-teacher__img {
  width: 100% !important;
  margin: 0;
  height: 260px;
  object-fit: cover;
  border-radius: unset;
  background: lightgray;
}
.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__block {
  padding-top: 13px;
}
.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__name {
  font-size: 20px;
  color: #272727;
}

.course-wrapper .course-teacher:not(.solo-teacher) .course-teacher__descr {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #d6d6d6;
  font-size: 16px;
  color: #272727;
}
@media (max-width: 1185px) {
  .course-banner .course-container {
    max-width: 530px;
  }
  .course-fixed {
    max-width: 388px;
  }
  .course__teachers {
    gap: 15px;
  }
}
@media (max-width: 991px) {
  .course-wrapper .course-teacher.solo-teacher {
    width: 100%;
  }
  .course-wrapper .course__teachers {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 30px;
  }
  .course-wrapper .course-teacher:not(.solo-teacher) {
    flex-shrink: 0;
  }
  .course-wrapper .course__teachers {
    padding-bottom: 35px;
    overflow-x: auto;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #6582fd #b7b7b7;
  }
  .course-wrapper .course__teachers ::-webkit-scrollbar {
    width: 3px;
  }

  .course-wrapper .course__teachers ::-webkit-scrollbar-track {
    background: #b7b7b7;
  }
  .course-wrapper .course__teachers ::-webkit-scrollbar-thumb {
    background: #6582fd;
  }
  .course-teacher__popup {
    top: 0;
    bottom: unset;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .course-fixed__body .course-tofavorite {
    display: none;
  }
}

.subcategories-wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.sub-subcategories-wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.subcategories-wrap .category-tag,
.sub-subcategories-wrap .category-tag {
  width: auto;
  flex-shrink: 0;
}
.subcategories-wrap .category-tag span a {
  padding: 3px 12px;
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: auto;
}
.subcategories-wrap .category-tag.active span a {
  box-shadow: 0px 0px 1px 1px #6582fd;
}

.sub-subcategories-wrap .category-tag span a {
  padding: 0;
}
.subcategories-wrap .category-tag span a:hover {
  font-weight: 300;
  box-shadow: 0px 0px 1px 1px #6582fd;
}
.sub-subcategories-wrap .category-tag span a:hover {
  font-weight: 300;
  color: #6582fd;
}
@media (max-width: 575px) {
  .subcategories-wrap {
    margin-left: 20px;
    margin-top: 20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: calc(100% - 20px);
    padding-bottom: 15px;
    scrollbar-width: thin;
    padding-right: 20px;
    scrollbar-color: #6582fd #cccccc;
  }
  .subcategories-wrap::-webkit-scrollbar {
    width: 3px;
  }
  .subcategories-wrap .category-tag span a {
    padding: 0;
    box-shadow: unset;
  }
  .subcategories-wrap .category-tag span a:hover {
    box-shadow: unset;
    color: #6582fd;
  }

  .subcategories-wrap::-webkit-scrollbar-track {
    background: #cccccc;
  }
  .subcategories-wrap::-webkit-scrollbar-thumb {
    background: #6582fd;
  }
  .sub-subcategories-wrap {
    margin-left: 20px;
    scrollbar-width: thin;
    padding-bottom: 15px;
    padding-top: 10px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-right: 20px;
    width: calc(100% - 20px);
    position: relative;
    scrollbar-color: #c736e2 #cccccc;
  }
  .sub-subcategories-wrap::-webkit-scrollbar-track {
    background: #cccccc;
  }
  .sub-subcategories-wrap::-webkit-scrollbar-thumb {
    background: #c736e2;
  }
  .sub-subcategories-wrap-wrapper {
    background: #f6f6f6;
  }
}
.school-page__courses .show-more-btn {
  display: none;
}

.solo-teacher .course-teacher__info {
  display: none;
}

.solo-teacher .course-teacher__close {
  display: none;
}
.course-reviews__ratings .rating__stars {
  width: 90px;
  height: 16px;
}
.course-reviews__ratings .rating__stars_empty {
  width: 90px;
  height: 16px;
}

@media (max-width: 767px) {
  #courses-list .show-more-btn {
    display: block;
  }
  #courses-list .show-more-btn ~ .pagination-links {
    display: none;
  }

  .text-center ~ .pagination-links {
    display: none;
  }

  .comments-empty {
    margin-top: 15px;
    font-size: 18px;
  }
  .school-search-wrapper {
    margin-top: 10px;
  }
  .school-page__courses .categories-slider-wrap .categories__btn {
    width: 265px;
  }
  .school-page__courses
    .categories-slider-wrap
    .categories__btn
    .select-btn__text {
    justify-content: center;
  }
  .market__filters {
    column-gap: 15px;
  }
  .school-page__subtitle + .market__filters {
    margin-top: 10px;
  }
  .market-all-filters {
    right: 20px;
    transform: none;
    left: unset;
    width: calc(100vw - 35px);
    top: 70px;
  }
  .course-tosite {
    font-size: 18px;
    line-height: 1.22;
    padding: 8px 65px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .course-fixed-grid__installment {
    flex-direction: row;
    margin-top: 12px;
    column-gap: 5px;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .course-fixed-grid__installment .course-fixed__info {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .course-fixed-grid__installment .course-fixed__info:after {
    content: "...................................................................";
    flex-shrink: 1;
  }

  .course-fixed-grid__installment .course-fixed__parts {
    flex-shrink: 0;
    width: auto;
  }
  .course-wrapper .course-teacher.solo-teacher {
    width: 210px;
    position: relative;
  }
  .course-wrapper .course-teacher.solo-teacher img.course-teacher__img {
    width: 100% !important;
    height: 260px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .course-wrapper .course-teacher.solo-teacher .course-teacher__descr {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #d6d6d6;
  }

  .course-wrapper .course-teacher.solo-teacher .course-teacher__text {
    position: absolute;
    max-width: 360px;
    width: calc(100vw - 30px);
    left: 0;
    top: 0;
    margin: 0;
    border: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
    padding: 36px 22px;
    transition: 0.5s all;
    background: #f4f4f4;
  }
  .course-wrapper
    .course-teacher.solo-teacher
    .course-teacher__text
    .course-teacher__close {
    display: block;
  }
  .course-wrapper .course-teacher.solo-teacher .active .course-teacher__text {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
  .solo-teacher .course-teacher__info {
    display: flex;
  }
}

@media (min-width: 993px) {
  .course-teacher__popup {
    right: -120%;
    transform: none;
  }

  .course-teacher__info:hover ~ .course-teacher__popup {
    opacity: 1;
    visibility: visible;
  }

  .course-teacher__popup .course-teacher__close {
    display: none;
  }

  .course__teachers .course-teacher:nth-child(4n) .course-teacher__popup {
    right: unset;
    left: -120%;
  }
  .course-teacher__popup {
    width: 250px;
  }
}
@media (min-width: 1400px) {
  .course-teacher__popup {
    right: -143%;
    transform: none;
  }
  .course__teachers .course-teacher:nth-child(4n) .course-teacher__popup {
    right: -143%;
    left: unset;
  }
  .course__teachers .course-teacher:nth-child(5n) .course-teacher__popup {
    right: unset;
    left: -143%;
  }
  .course-teacher__popup {
    width: 300px;
  }
}

.sub-subcategories-wrap .active a {
  font-weight: 300;
  color: #6582fd;
}

/* Cashback popup start*/

.cashback-popup {
  position: fixed;
  top: 50%;
  transform: translate(50%, -55%);
  right: 50%;
  padding: 30px 20px 50px;
  box-shadow: 0px 0px 54px 2px rgba(0, 0, 0, 0.25);
  background: #fff;
  z-index: 500;
  max-width: 360px;
  margin: 0 15px;
  font-family: "Futura PT";
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.cashback-popup-success__title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #000;
}

.cashback-popup-success__text {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;
  color: #000;
  margin-top: 5px;
  text-align: center;
}

.cashback-popup.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translate(50%, -50%);
}

.cashback-popup form {
  margin: 0;
}

.cashback-popup__text {
  font-size: 16px;
  line-height: 1.12;
  color: #000;
  text-align: center;
  margin-top: 5px;
  font-weight: 300;
}

.cashback-popup__input {
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 14px 20px;
  font-size: 18px;
  font-weight: 300;
  color: #272727;
  border: none;
  display: block;
  width: 100%;
  margin-top: 12px;
}

.cashback-popup__wrapper {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 13px;
}

.cashback-popup-item {
  padding: 15px;
  height: 100%;
}

.cashback-popup-item .cashback-popup__img {
  width: 100%;
}

.cashback-popup-item {
  background: #f9f9f9;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-end;
}

.cashback-popup__block input:checked ~ .cashback-popup-item {
  outline: 1px solid #e66165;
}
.cashback-popup.success {
  transition: none;
}
.cashback-popup.success .success-hide {
  display: none;
}

.cashback-popup__success {
  display: none;
}

.cashback-popup.success .cashback-popup__success {
  display: block;
}

.cashback-popup-item__title {
  font-size: 22px;
  color: #272727;
  font-weight: 500;
  margin-bottom: 7px;
}

.cashback-popup__block input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}

.cashback-popup__btn[disabled] {
  background: #bebebe;
}
.cashback-popup__btn[disabled]:hover {
  box-shadow: none;
}
.cashback-popup__btn:hover {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
}

.cashback-popup__2 .cashback-popup__label {
  text-align: left;
}

.cashback-popup__info {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.14;
  color: #000000;
  margin-top: 5px;
}

.cashback-popup__btn {
  width: 100%;
  border: none;
  padding: 17px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  background: #e66165;
  transition: 0.5s all;
  margin-top: 15px;
}

.cashback-popup__label {
  font-size: 16px;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-top: 23px;
}
.cashback-popup__close {
  background: none;
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.5s all;
}

.cashback-popup__close:hover {
  color: #e66165;
}

.cashback-popup__title {
  text-align: center;
  font-size: 20px;
  line-height: 1.3;
  color: #000;
}
/* Cashback popup end*/

/*Schools page*/

.schools-wrapper {
  margin-top: 82px;
  font-family: "Futura PT";
  margin-bottom: 20px;
}

.schools-wrapper .page-title-static {
  font-weight: 450;
  font-size: 48px;
  color: #272727;
  margin-bottom: 5px;
  font-family: "Futura PT";
}

.schools-wrapper .courses-count-wrap {
  font-family: "Futura PT";
  font-size: 22px;
  color: #272727;
  letter-spacing: 1;
  text-transform: unset;
}

.filters-search {
  width: 818px;
  position: relative;
  font-family: "Futura PT";
}

.filters-search + .filters-show {
  margin-left: 0;
}

.filters-search + .filters-show .filters-show__text {
  padding-left: 0;
}

.filters__price {
  display: flex;
  gap: 7px;
}

.filters__price input {
  width: 101px;
  height: 40px;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding-left: 10px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}

.filters__price input:focus {
  outline: 1px solid #e66366;
}

.categories-gradient {
  max-width: 210px;
  padding-top: 20px;
}

.categories-gradient:last-of-type {
  padding-bottom: 14px;
}

.filters-search input {
  width: 100%;
  padding: 12px 50px 12px 20px;
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-size: 18px;
  color: #272727;
  font-weight: 300;
}

.filters-search__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  filter: brightness(0);
}

.filters-search__btn img {
  width: 100%;
}

.filters-search__results {
  position: absolute;
  top: 58px;
  left: 0;
  display: none;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 50;
  font-family: "Futura PT";
}

.filters-search__results ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filters-search__results * {
  font-size: 18px;
  font-weight: 300;
  color: #272727;
  line-height: 1.17;
  transition: 0.5s all;
}

.filters-search__results a:hover {
  text-decoration: none;
  color: #6582fd;
}

.filters-search__results * + * {
  margin-top: 15px;
}

.filters-wrap + .static-page-wrap {
  margin-top: 35px;
  padding-top: 0;
}

.school-item {
  margin: 0;
}

.schools-wrap {
  column-gap: 26px;
  row-gap: 43px;
}

.school-item::before {
  display: none;
}

.school-item {
  background: #fff;
  padding-bottom: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
}

.school-info-popup {
  width: 100%;
  text-decoration: none;

  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
}

.school-info__btn {
  padding: 12px 20px;
  background: #e66165;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Futura PT";
  transition: 0.5s all;
}

.school-info__btn:hover {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  text-decoration: none;
}

.school-item:hover .school-info-popup {
  opacity: 1;
  visibility: visible;
  text-decoration: none;
}

.school-item__img {
  width: 100%;
  min-height: 146px;
  max-height: 146px;
  object-fit: contain;
  padding: 20px 20px 0px;
}

.school-item__body {
  flex: 1;
  background: #f5f5f5;
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
}
.school-name span {
  font: inherit;
  color: inherit;
}
.school-name {
  font-family: "Futura PT";
  font-size: 22px;
  color: #272727;
  line-height: 1.14;
  white-space: normal;
  height: 60px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  background: none;
  letter-spacing: normal;
  text-transform: unset;
  text-align: left;
  font-weight: 450;
}

.school-info-block {
  margin-top: 18px;
  font-size: 18px;
  margin-bottom: 5px;
  color: #272727;
}

.school-item .plate-item__rating > span {
  font-size: 23px;
  font-weight: 600;
  color: #fed604;
  font-family: "Futura PT";
}

.school-item .plate-item__rating.is-zero > span {
  color: #c4c4c4;
}

.school-item .raiting-block {
  overflow: hidden;
  font-family: "Futura PT";
  background: none;
  font-size: 18px;
  font-weight: 300;
  color: #272727;
  padding: 0;
}

.schools-page .page-numbers.current {
  background: #e66165;
}

.schools-page .extra-text {
  max-width: 900px;
  margin: 80px auto 30px;
  font-size: 16px;
  font-weight: 300;
}

@media (max-width: 1400px) {
  .filters-search {
    width: 580px;
  }
}

@media (max-width: 1200px) {
  .filters-search {
    width: 380px;
  }
}

@media (max-width: 993px) {
  .filters-search {
    width: 360px;
  }
}

@media (max-width: 767px) {
  .filters-search {
    width: 100%;
    order: 0;
  }
  .school-filters .categories-slider-wrap .course-filters {
    order: 1;
    width: 100%;
  }
  .school-filters .categories-slider-wrap .categories__btn .course-filters {
    width: 100%;
  }
  .schools-wrapper .courses-wrapper {
    margin-top: 35px;
  }
  .schools-wrapper .page-title-static {
    text-align: left;
    font-size: 35px;
  }
  .schools-wrapper .courses-count-wrap {
    font-size: 20px;
  }

  .schools-page .show-more-btn {
    display: block;
    margin-top: 30px;
  }
  /* .schools-page .pagination-links {
        display: none;
    } */
}

@media (max-width: 576px) {
  .schools-page {
    padding: 0 15px;
  }
  .school-item {
    width: 100%;
  }
  .schools-page .extra-text {
    margin-top: 60px;
    font-size: 18px;
  }
}

.banner__img {
  width: 100%;
}

.banner img {
  width: 100%;
}

.footer-cookies {
  margin-top: 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 9px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-cookies > div:last-child {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.footer-cookies__wrap {
  font-family: "Futura PT", Arial, sans-serif;
  line-height: 1.25;
  color: #fff;
  font-weight: 300;
  max-width: 660px;
}

.footer-cookies__wrap a {
  font-weight: 400;
  text-decoration: underline;
}
.partners {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .footer-cookies__wrap {
    font-size: 18px;
  }
}

.js-list-table {
  width: 440px;
  background: #ffffff;
  position: absolute;
  top: 80px;
  z-index: 100;
  border: 1px solid #dbdbdb;
}

.js-list-table > ul:first-of-type {
  list-style-type: none;
  margin: 0;
  padding-top: 10px;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.js-list-table > ul:first-of-type li,
.js-list-table > ul:last-of-type a {
  padding: 5px 0 5px 5px;
}

.js-list-table > ul:last-of-type {
  list-style-type: none;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.js-list-table > ul:last-of-type > a {
  line-height: 12px;
}

.js-list-table > a {
  color: #e66166 !important;
  font-weight: 450;
  font-size: 16px;
  line-height: 35px;
  text-decoration: none;
  padding-left: 15px;
}

.js-list-table > a::before {
  content: "";
  width: 8px;
  height: 8px;
  border: solid #e66166;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(135deg);
  position: absolute;
  left: 92%;
  top: 18px;
}

.js-list-table > a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: solid #e66166;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(135deg);
  position: absolute;
  left: 94%;
  top: 18px;
}

.js-list-table > ul > li {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.js-list-table > ul a {
  width: 100%;
  text-decoration: none;
}

.js-list-table > ul.header__search-courses a {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.js-list-table > ul > li:hover {
  background-color: #f4f4f4;
}

.js-list-table > ul > a:hover {
  background-color: #f4f4f4;
}

.js-list-table > ul > li span:first-child {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 12px;
  color: #272727;
  text-decoration: none !important;
  display: block;
}

.js-list-table > ul > li > a > span:last-child {
  font-weight: 300;
  font-size: 14px;
  line-height: 12px;
  color: #272727;
  display: block;
}

.js-list-table > ul > a {
  text-decoration: none;
  color: #272727;
}

.js-list-table > ul > span {
  font-weight: 450;
  font-size: 16px;
  line-height: 18px;
  color: #777777;
}

.js-list-table > ul:first-of-type::before {
  content: url(../img/svg/search-gradient.svg);
  position: absolute;
  left: 0px;
  top: 35px;
}

.js-list-table > ul:last-of-type {
  margin-bottom: 24px;
}

.js-list-table > div > ul {
  list-style-type: none;
}

.search__nothing-typed {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: absolute;
  background: white;
  z-index: 100;
  border: 1px solid #dbdbdb;
  width: 360px;
  top: 104px;
  padding-top: 20px;
  max-height: 515px;
  overflow-y: auto;
}

.search__nothing-typed > ul {
  list-style-type: none;
  padding-left: 18px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search__nothing-typed > ul > li {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.search__nothing-typed > ul > li > a {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272727;
}

.search__nothing-typed > ul > li > span {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #272727;
}

.search-results {
  padding: 30px 0 0 270px;
}

.search-results__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 25px;
}

.search-results__title {
  font-weight: 450;
  font-size: 60px;
  line-height: 77px;
  color: #272727;
}

.search-results__searched {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  margin-bottom: 0;
}

.search-results__courses,
.search-results__schools {
  color: #e66366;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  list-style-type: none;
  padding-left: 0;
}

.search-results__courses > span,
.search-results__schools > span {
  font-weight: 450;
}

.search-results__courses > span {
  margin-bottom: -15px;
}

.search-results__schools > span {
  margin-bottom: -12px;
}

.search-results__courses > li > a,
.search-results__schools > li > a,
.search-results__courses > li > span > a,
.search-results__schools > li > span > a {
  text-decoration: none;
  color: #272727;
  width: 100%;
}

.search-results__courses > li > span {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #272727;
}

.search-results__courses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 52px;
}

.search-results__courses:first-child {
  gap: 7px;
}

.search-results__schools {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 93px;
}

.search-results__courses > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  color: #272727;
  position: relative;
  width: 100%;
}

.search-results__schools > li {
  color: #272727;
  position: relative;
  width: 100%;
}

.search-results__courses > li:not(:last-child):after,
.search-results__schools > li:not(:last-child):after {
  content: url(../img/svg/Line107.svg);
  position: absolute;
  height: 1px;
}

.search-results__courses > li > span,
.search-results__schools > li > span {
  line-height: 1;
}

.search-results__courses > li:not(:last-child):after {
  bottom: 5px;
}

.search-results__schools > li:not(:first-child):after {
  top: 40px;
  left: 0px;
}

@media (max-width: 768px) {
  main .header__form {
    height: 50px !important;
    background: #f5f3f1;
    box-shadow: none;
    position: relative;
  }

  main .header__form button {
    position: absolute;
    right: 4%;
  }

  .categories__container {
    background-color: white;
  }

  .search-results {
    padding: 35px 0 0 20px;
  }

  .search-results__header {
    gap: 15px;
    margin-bottom: 15px;
  }

  .search-results__title {
    font-weight: 500;
    font-size: 35px;
    line-height: 20px;
  }

  .search-results__courses,
  .search-results__schools {
    line-height: 24px;
  }

  .search-results__courses > li {
    gap: 2px;
  }

  .search-results__courses > li > span {
    line-height: 22px;
  }

  .search-results__courses {
    gap: 10px;
    margin-bottom: 0px !important;
  }

  .search-results__schools {
    gap: 10px;
    margin-bottom: 68px;
  }

  .search-results__courses > li:not(:first-child):after,
  .search-results__schools > li:not(:first-child):after {
    content: none;
  }

  .search-results__courses:after {
    content: url(../img/svg/Line109.svg);
    margin: 15px 0;
  }

  .search-results__courses > span {
    margin-bottom: 0px;
  }

  .search-results__schools > span {
    margin-bottom: 0px;
  }
}

.pass-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.7;
}

.pass-icon:hover {
  opacity: 1;
}

.login-popup__back {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.15);
  width: 42px;
  height: 42px;
  top: 25px;
  left: -21px;
  border: none;
  border-radius: 50%;
  transition: 0.5s all;
}

.login-popup__back:hover {
  opacity: 0.7;
}

.js-error {
  margin: 0;
  position: absolute;
  top: 130%;
  line-height: 1.05;
  width: 100%;
  border-radius: 10px;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  z-index: 10;
  background: #ffffff;
}

.js-error:after {
  content: "";
  width: 0;
  font-size: 15px;
  font-family: "Futura PT";
  font-weight: 300;
  color: #272727;
  height: 0;
  border-style: solid;
  border-width: 0 10px 20px 10px;
  border-color: transparent transparent #ffffff transparent;
  position: absolute;
  right: 40px;
  top: -15px;
}

.login-popup__input[type="password"] {
  padding-right: 40px;
}

.login-popup__input[type="password"] + img:not(.pass-icon) {
  display: none;
  opacity: 0;
}

.overflow .register-form,
.overflow .register-school-form,
.overflow .reset-pass-form {
  display: none;
}

.register-email .login-popup {
  display: none;
}
.register-email .register-form {
  display: block;
}

.register-school .login-popup {
  display: none;
}
.register-school .register-school-form {
  display: block;
}

.reset-pass .login-popup {
  display: none;
}
.reset-pass .reset-pass-form {
  display: block;
}

.error-msg {
  border: 1px solid #ab3116;
}

.reset-success {
  position: absolute;
  top: 0;
  width: 100%;
  background: #fff;
  left: 0;
  font-size: 28px;
  text-align: center;
  color: #272727;
  font-weight: 400;
  font-family: "Futura PT";
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  display: none;
  padding: 20px;
  flex-direction: column;
}

.reset-done .reset-success {
  display: flex;
}

.reset-success a {
  text-decoration: underline;
  color: #6582fd;
  display: block;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

@media (max-width: 567px) {
  .login-popup {
    max-width: 100%;
    padding: 0;
    width: 100%;
  }
  .overflow {
    align-items: flex-start;
  }
  .login-popup__forgot {
    font-size: 18px;
  }
  .login-flex .filters__checkbox span {
    font-size: 18px;
  }
  .login-flex .filters__checkbox span::before {
    width: 18px;
    height: 18px;
  }
  .login-flex .filters__checkbox span::after {
    width: 13px;
    left: 2.5px;
    height: 13px;
  }

  .login-popup__body {
    padding-top: 80px;
    max-width: 100%;
    border-radius: 0px;
    width: 100%;
  }
  .login-popup__footer {
    border-radius: 0px;
  }
  .login-popup__back {
    left: 0;
    width: 9px;
    height: 18px;
    top: 50px;
    left: 26px;
    box-shadow: unset;
    border-radius: 0px;
  }
  .register-form .login-popup__body,
  .register-school-form .login-popup__body {
    padding-top: 115px;
  }
  .reset-pass-form {
    height: 100%;
  }

  .reset-pass-form .login-popup__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.upper::first-letter {
  text-transform: uppercase !important;
}

.school-page__link {
  background: #e66366;
  color: #ffffff;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 80px 13px 70px;
  border-radius: 5px;
  width: max-content;
}

.school-page__link:hover {
  opacity: 0.7;
  color: #ffffff;
}

.tab__outlined {
  border-bottom: 2px solid #e66165;
}

.parent-li-with-all-courses::after {
  content: "";
  width: 8px;
  height: 8px;
  border: solid #777777;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(135deg);
  position: absolute;
  left: 94%;
  top: 56%;
}

.review__title > span {
  font-weight: 600;
  font-size: 20px !important;
  line-height: 26px;
  color: #272727;
}

.review__title {
  margin-top: 5px !important;
}

.review__date {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #777777;
}

.review__text {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #272727;
}

.review__rating {
  fill: #f5af33;
}

.course-reviews > div > div {
  max-width: 785px !important;
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
}

.course-reviews > div {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin: 20px auto 0;
}

.author[data-v-0fe69960] {
  font-family: "Futura PT", Arial, sans-serif !important;
}

.review__date[data-v-0fe69960] {
  font-family: "Futura PT", Arial, sans-serif !important;
}

.courses-show-count {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
}

.courses-show-count > a {
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #272727;
}

.courses-show-count > a:hover {
  text-decoration: none;
  font-weight: 450;
}

.articles__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;
}

.articles__item {
  max-width: 320px;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  position: relative;
  height: 329px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.articles__item:hover {
  border: 1px solid #dbdbdb;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.articles__item-title {
  margin-top: 10px;
  margin-bottom: 26px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #272727;
}

.articles__bottom {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}

.articles__item-views {
  display: flex;
  gap: 3px;
  align-items: center;
}

.articles__item-picture > img {
  max-width: 100%;
}

.articles__item.no-picture-item {
  padding: 30px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.no-picture-item > div > .articles__item-title {
  margin-bottom: 8px;
  margin-top: 0px;
  padding: 0;
}

.no-picture-item > .articles__bottom {
  padding: 0;
  margin-bottom: 0;
}

.articles__item-text,
.views-number {
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
}

.articles__item-text {
  color: #272727;
}

.views-number {
  color: #777777;
}

.articles__item-read > a {
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #272727;
}

.articles__item-read > a:hover {
  color: #777777;
}

.articles__item-text {
  margin-bottom: 0 !important;
}

.articles__pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  gap: 4px;
}

.articles__pagination > a {
  font-weight: 400;
  font-size: 18px;
  line-height: 10px;
  color: #272727;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-decoration: none;
}

.articles__prev-btn {
  margin-right: 6px;
  background: url(../img/svg/pagination-arrow.svg) no-repeat center;
  width: 13px;
  height: 13px;
  transform: rotate(180deg);
}

.main-page__course-title + div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.articles__next-btn {
  margin-left: 6px;
  background: url(../img/svg/pagination-arrow.svg) no-repeat center;
  width: 13px;
  height: 13px;
}

.articles__prev-btn:hover,
.articles__next-btn:hover {
  opacity: 0.6;
}

.articles__page-btn.page-active {
  background: #272727;
  color: #ffffff;
  border-radius: 50%;
}

.articles__page-btn:hover {
  background: #272727;
  color: #ffffff;
  border-radius: 50%;
}

@media (max-width: 1400px) {
  .articles__wrapper {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}

@media (max-width: 1200px) {
  .articles__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .articles__wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

  .static-page-wrap {
    padding-top: 18px;
    padding-bottom: 80px;
  }

  .page-title-static {
    margin-bottom: 4px !important;
    line-height: 35px;
  }

  .courses-show-count {
    display: none;
  }

  .courses-count-wrap {
    margin-bottom: 14px;
  }

  .articles__item.no-picture-item {
    height: auto;
  }

  .no-picture-item > .articles__bottom {
    margin-top: 30px;
  }

  .show-more-btn {
    display: block;
  }

  .show-more-btn > a {
    display: block;
    text-align: center;
    font-weight: 450;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #272727;
  }

  .show-more-btn > a:hover {
    color: #777777;
  }

  .articles__pagination {
    display: none;
  }
}

.sorting-active img {
  transition-duration: 0.3s;
}

.icon-reversed {
  transform: scale(1, -1);
  transition-duration: 0.3s;
}

main:has(.main-page) {
  background: #f5f3f1;
  flex: none;
  display: block;
  padding-bottom: 80px;
}

.main-page h1 {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 70px;
  line-height: 79px;
  color: #ffffff;
}

.main-page p {
  margin: 0;
}

.main-page__banner {
  background-position: center !important;
  height: 600px;
  padding: 60px 0 60px 104px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.main-page__banner-top-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-page__banner-info {
  display: flex;
  gap: 38px;
}

.main-page__banner-info span:first-of-type {
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
}

.main-page__banner-info span:last-of-type {
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: #ffffff;
}

.main-page__banner-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 653px;
  height: 100%;
}

.main-page__banner-bot-wrapper {
  margin-bottom: 30px;
}

.main-page__banner-btn {
  max-width: 284px;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  padding: 21px 67px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}

.main-page__banner-btn:hover {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  text-decoration: none;
}

.main-page__test {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.main-page__test-wrapper {
  padding-left: 47px;
  padding-right: 81px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.main-page__test-text h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  color: #272727;
  margin-bottom: 0;
}

.main-page__test-text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
}

.main-page__test-btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  background: #333333;
  border-radius: 5px;
  padding: 23px 71px;
  white-space: nowrap;
}

.main-page__test-btn:hover {
  background: #555555;
  text-decoration: none;
  color: #ffffff;
}

.main-page__test-text {
  max-width: 652px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.bulb-img {
  position: absolute;
  top: 20;
  right: 510px;
  z-index: 0;
}

.boy-img {
  position: absolute;
  top: 0;
  right: 300px;
  z-index: 0;
}

.main-page.container {
  padding-right: 20px;
  padding-left: 20px;
}

.main-page__course.big > a {
  display: block;
  padding: 20px 30px 30px;
  border-radius: 10px;
}

.main-page__course > a {
  display: block;
  padding: 20px 30px;
  border-radius: 10px;
  height: 100%;
}

.main-page__course > a:hover {
  text-decoration: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.main-page__course.big h3 {
  margin-bottom: 10px;
  font-weight: 450;
  font-size: 35px;
  line-height: 45px;
  color: #272727;
}

.main-page__course h3 {
  font-size: 25px;
  line-height: 32px;
  color: #272727;
  margin-bottom: 8px;
  font-weight: 450;
}

.main-page__course-sub a {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #777777;
  text-decoration: none;
}

.main-page__course-sub {
  color: #777777;
}

.main-page__course-sub {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.main-page__course-sub a:hover {
  color: #333333;
  text-decoration: underline;
}

.main-page__course-link > a {
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  color: #e66366;
}

.main-page__course-link > a:hover {
  color: #eb8f91;
  text-decoration: none;
}

.main-page__courses-top {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
}

.main-page__courses {
  margin-bottom: 50px;
}

.main-page__course-link {
  display: block;
  text-align: right;
}

.main-page__course {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.main-page__courses-bottom {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 20px;
}

.main-mobile__course-sub {
  display: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #777777;
}

.main-page__partners {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.main-page__partners-el {
  height: 85px;
  max-width: 175px;
}

.main-page__partners-el a {
  max-width: 175px;
  width: max-content;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-page__partners-el a img {
  width: 100%;
  max-width: 100%;
  height: 85px;
  object-fit: contain;
  object-position: center;
}

.main-page__partners-el a:hover {
  opacity: 0.7;
}

.main-page__partners-el.link a {
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #e66366;
}

.main-page__partners-el.link a:hover {
  opacity: 0.7;
  color: #e66366;
}

.main-page__partners-els {
  display: grid;
  grid-template-columns: repeat(auto-fit, 175px);
  grid-gap: 20px;
  padding: 20px 45px 40px;
  justify-items: center;
  justify-content: space-between;
}

.main-page__partners h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  color: #272727;
  padding: 30px 0 0 45px;
  margin-bottom: 0;
}

@media (max-width: 1260px) {
  .main-page__partners-el.desctop-hide {
    display: none;
  }

  .main-page__partners-els {
    grid-template-columns: repeat(6, 1fr);
  }

  .main-page__partners-el {
    justify-self: center;
    width: 100%;
  }

  .about__bot-wrapper .main-page__partners-els {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1399px) {
  .main-page.container {
    max-width: none !important;
  }
}

@media (max-width: 1140px) {
  .main-page__banner {
    background-size: cover;
    padding: 40px 0 60px 30px;
  }

  .main-page__partners-el.tablet-hide {
    display: none;
  }

  .main-page__partners-els {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
    padding-top: 12px;
  }

  .main-page h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .main-page__banner-content {
    max-width: 527px;
  }

  .main-page__banner-info {
    flex-direction: column;
    gap: 8px;
  }

  .main-page__test-wrapper {
    height: 400px;
    padding-right: 20px;
    padding-left: 30px;
    align-items: flex-start;
    padding-top: 36px;
  }

  .main-page__test-test {
    align-self: flex-end;
    display: flex;
    margin-bottom: 74px;
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 34px;
  }

  .bulb-img {
    top: auto;
    bottom: 20;
    z-index: 0;
  }

  .boy-img {
    top: auto;
    bottom: 0;
    z-index: 0;
  }

  .main-page__courses-top {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }

  .main-page__courses-bottom {
    gap: 12px;
    grid-template-columns: repeat(2, auto);
  }

  .header__search-wrapper {
    top: 14px;
    left: 32%;
    z-index: 400;
  }

  .header__search-wrapper .js-list-table > ul:last-of-type > a {
    text-align: left;
  }

  .header__search-wrapper .js-list-table > a {
    text-align: left;
  }

  .header__form {
    z-index: 400;
  }

  .header__search-wrapper .js-list-table {
    max-width: 100%;
  }
}

@media (max-width: 596px) {
  .main-page__partners h4 {
    font-size: 30px;
    line-height: 35px;
    padding: 15px 0 0 20px;
  }

  .main-page__partners-els {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-page__partners-el.tablet-hide:first-of-type {
    display: block !important;
  }

  .main-page__courses {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .main-page__course-sub {
    display: none;
  }

  .main-mobile__course-sub {
    display: -webkit-box;
    /* overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; */
  }

  .main-mobile__course-sub a {
    color: #777777;
  }

  .main-mobile__course-sub a:hover {
    color: #333333;
    text-decoration: underline;
  }

  .main-mobile__course-sub > a:hover {
    text-decoration: none;
  }

  .main-page__courses-bottom .main-page__course {
    grid-column: span 12 !important;
    height: 100% !important;
  }

  .main-page__course-link {
    margin-top: 10px !important;
  }

  .main-page h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .main-page__banner-btn {
    margin-top: 10px;
    padding: 15px 35px;
    display: block;
    width: fit-content;
  }

  .header__search-wrapper .js-list-table > a {
    display: flex;
    justify-self: flex-start;
  }

  .js-list-table > ul:last-of-type {
    padding-left: 20px;
  }

  .main-page__banner {
    margin: 0 -20px 20px;
    padding: 0;
    background-position: center !important;
    background-size: cover !important;
  }

  .main-page__banner {
    border-radius: 0;
    height: 495px;
    margin: 0;
  }

  .main-page__banner-content {
    /* align-items: center; */
    max-width: unset;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 40px;
    padding-left: 26px;
    padding-right: 26px;
    height: auto;
  }

  .boy-img {
    display: none;
  }

  .bulb-img {
    top: -30;
    bottom: auto;
    right: -30;
    transform: rotate(56deg);
  }

  .main-page__test {
    margin-bottom: 30px;
  }

  .main-page__test-text h2 {
    font-size: 30px;
    line-height: 35px;
    max-width: 210px;
  }

  .main-page__test-wrapper {
    padding: 15px 20px 30px;
    height: 347px;
  }

  .main-page__test-test {
    margin: 0 auto;
  }

  .main-page__test-text {
    gap: 10px;
  }

  .main-page__courses-bottom {
    grid-template-columns: repeat(1, auto);
  }

  .main-page__course h3,
  .main-page__course.big h3 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 2px;
  }

  .main-page__course-sub > a {
    font-size: 16px;
    line-height: 21px;
  }

  .main-page__course-link > a {
    line-height: 21px;
  }

  .main-page__course > a {
    padding: 10px 20px 15px !important;
  }
}

.main-page__courses-bottom {
  grid-template-columns: repeat(12, 1fr);
}
.main-page__courses-bottom .main-page__course {
  grid-column: span 3;
}

.main-page__courses-bottom .main-page__course:nth-child(1),
.main-page__courses-bottom .main-page__course:nth-child(2),
.main-page__courses-bottom .main-page__course:nth-child(3) {
  grid-column: span 4;
}

.main-page__course a > object {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

@media (max-width: 1140px) {
  .main-page__courses-bottom .main-page__course {
    height: 155px;
  }

  .main-page__course-sub {
    -webkit-line-clamp: 2;
  }

  .main-page__course-link {
    margin: 0;
  }

  .main-page__courses-bottom .main-page__course {
    grid-column: span 6;
  }

  .main-page__courses-bottom .main-page__course:nth-child(1),
  .main-page__courses-bottom .main-page__course:nth-child(2),
  .main-page__courses-bottom .main-page__course:nth-child(3) {
    grid-column: span 12;
  }
}
.ratings__table-row-rating > span.bg-best {
  background: linear-gradient(90deg, #77a654 0%, #60a55a 100%);
}

.ratings__table-row-rating > span.bg-good {
  background: linear-gradient(90deg, #96a84b 0%, #80a751 100%);
}

.ratings__table-row-rating > span.bg-mixed {
  background: linear-gradient(90deg, #b6aa43 0%, #9ea949 100%);
}

.ratings__table-row-rating > span.bg-bad {
  background: linear-gradient(90deg, #d5ad3b 0%, #bdab41 100%);
}

.categories__search-res ul,
.categories__search-res li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.categories__search-res .s-link {
  color: #272727;
  text-decoration: none;
  width: 100%;
  padding: 0 0 0 60px;
  display: flex;
  align-items: center;
  height: 28px;
  font-size: 19px;
}

.categories__search-res li {
  font-size: 16px;
  line-height: 18px;
}

.categories__search-res li:hover {
  background: #f4f4f4;
}

.categories__search-res {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 15px;
}

.search-form-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
}

.market-top + .container {
  height: 64px;
}

.contacts a {
  text-decoration: none;
  color: #272727;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  position: relative;
}

.contacts a:not(.p-mail) {
  padding-left: 30px;
}

.contacts h2 {
  font-weight: 450;
  font-size: 25px;
  line-height: 32px;
  color: #272727;
}

.contacts p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #272727;
}

.contacts h1 {
  font-weight: 550;
  font-size: 48px;
  line-height: 52px;
  color: #272727;
}

.contacts__left {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.contacts__top {
  display: flex;
  flex-direction: row;
  gap: 130px;
}

.contacts__top div {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.conatacts__bot-inner {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.tel::before {
  content: url(https://kurs-market.com/images/phone.svg);
  position: absolute;
  left: 0;
  top: -2px;
}

.telegram::before {
  content: url(https://kurs-market.com/images/telegram.svg);
  position: absolute;
  left: 0;
  top: 1px;
}

.whatsapp::before {
  content: url(https://kurs-market.com/images/whatsapp.svg);
  position: absolute;
  left: 0;
  top: -2px;
}

.email::before {
  content: url(https://kurs-market.com/images/email.svg);
  position: absolute;
  left: 0;
  top: 1px;
}

.vk::before {
  content: url(https://kurs-market.com/images/vk.svg);
  position: absolute;
  left: 0;
  top: 2px;
}

.zen::before {
  content: url(https://kurs-market.com/images/zen.svg);
  position: absolute;
  left: 0;
  top: -2px;
}

.telegram-pub::before {
  content: url(https://kurs-market.com/images/telegram-pub.svg);
  position: absolute;
  left: 0;
  top: -2px;
}

@media (max-width: 768px) {
  .contacts-img {
    display: none;
  }

  .contacts__top {
    flex-direction: column;
    gap: 25px;
  }

  .conatacts__bot-inner {
    flex-direction: column;
  }

  .contacts h1 {
    font-size: 35px;
    line-height: 20px;
  }
}

.user__dropdown {
  position: absolute;
  visibility: visible;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  top: 48px;
  right: 0;
  width: 200px;
  z-index: 100;
}

.user__dropdown ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0 28px 46px;
  margin: 0;
}

.user form {
  margin: 0;
}

.user__dropdown a {
  text-decoration: none;
  color: #272727 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  margin: 0 !important;
  width: 100%;
  height: 22px;
  justify-content: flex-start;
}

.user__dropdown .settings:before {
  content: url(../img/svg/settings-black.svg);
  position: absolute;
  top: 3px;
  left: -30px;
}

.user__dropdown .favourite:before {
  content: url(../img/svg/favourite-black.svg);
  position: absolute;
  top: 3px;
  left: -28px;
}

.user__dropdown .logout:before {
  content: url(../img/svg/logout-black.svg);
  position: absolute;
  top: 5px;
  left: -28px;
}

.user__nav .settings:before {
  content: url(../img/svg/settings-red.svg);
  position: absolute;
  top: 12px;
  left: 10px;
}

.user__nav .favourite:before {
  content: url(../img/svg/favourite-red.svg);
  position: absolute;
  top: 12px;
  left: 12px;
}

.user__nav .logout:before {
  content: url(../img/svg/logout-red.svg);
  position: absolute;
  top: 15px;
  left: 11px;
}

.user-link .login-btn-text {
  display: flex;
  align-items: center;
}

.user .user__nav a {
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272727;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
}

.user .user__nav a:hover {
  background: #ffffff;
}

.user .user__nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 60px;
  width: max-content;
  min-width: 266px;
  margin-left: auto;
}

.user .user__nav li,
.user__dropdown li {
  position: relative;
}

.user .user__nav li {
  height: 44px;
}

.user-link a:has(img.logged-in).dropdown-active {
  position: relative;
}

.user-link:has(a.dropdown-active) img {
  border: 2px solid #e66366;
  border-radius: 100px;
}

.user__wrapper {
  display: flex;
  flex-direction: row;
}

.user__nav {
  width: 33%;
  background: #f5f3f1;
}

.user__contents {
  width: 77%;
  background: #ffffff;
}

.user__settings,
.user__favourite {
  padding: 70px 20px 70px 80px;
}

.user__settings h1 {
  margin-bottom: 20px;
  font-weight: 450;
  font-size: 30px;
  line-height: 38px;
  color: #272727;
}

.user__setting-contents form .user__setting-item:first-of-type {
  margin-bottom: 40px;
}

.user__setting-item h2 {
  margin-bottom: 20px;
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
}

.user__setting-contents {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.user__setting-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user__contents fieldset {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  max-width: 600px;
  height: 50px;
}

.user__contents legend {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  margin: 0;
  margin-left: 13px;
  width: max-content;
}

.user__contents input {
  background: #ffffff;
  border: 0;
  border-radius: 5px;
  outline: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.user__save-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #333333;
  width: 170px;
  height: 50px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 450;
  font-size: 20px;
  line-height: 20px;
  outline: 0;
  border: 0;
  padding: 0;
}

.user__save-btn:hover {
  background: #555555;
  color: #ffffff;
  text-decoration: none;
}

.user__save-btn:active,
.user__save-btn:focus {
  border: 0;
  outline: 0;
}

.user__password {
  height: 44px !important;
}

.user__password {
  position: relative;
}

.user__password a {
  position: absolute;
  top: 11px;
  right: 14px;
}

.password-popup__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
}

.password-popup__wrapper.active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-popup .user__password {
  height: 50px !important;
}

.password-popup {
  max-width: 700px;
  max-height: 343px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 30px 50px 50px;
  position: relative;
}

.password-popup h3 {
  font-weight: 450;
  font-size: 20px;
  line-height: 27px;
  color: #272727;
  margin-bottom: 20px;
}

.password-popup legend {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  margin: 0;
  margin-left: 13px;
  width: max-content;
}

.password-popup fieldset {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  max-width: 600px;
  height: 50px;
}

.password-popup .error,
.user__settings .error {
  color: red;
  margin: 0;
}

.password-popup input {
  background: #ffffff;
  border: 0;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: baseline;
}

.restore-pass a {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #777777;
  display: flex;
  width: max-content;
  margin-left: auto;
}

.restore-pass a:hover {
  opacity: 0.7;
}

.restore-pass {
  margin-top: 4px;
  margin-bottom: 20px;
}

.password-popup .user__password:last-of-type {
  margin-bottom: 20px;
}

.close-user-popup {
  position: absolute;
  right: 20px;
  top: 20px;
}

.close-user-popup img:last-of-type {
  display: none;
}

.close-user-popup img:first-of-type {
  display: block;
}

@media (max-width: 998px) {
  .user__settings {
    padding: 70px 50px 70px 50px;
  }

  .user .user__nav ul {
    width: auto;
    min-width: auto;
    margin-left: 22px;
  }

  .user__nav {
    width: auto;
  }
}

@media (max-width: 500px) {
  .user__dropdown {
    z-index: 100;
  }

  .user__nav {
    display: none;
  }

  .user-link {
    position: static;
  }

  .user__contents {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .user__settings,
  .user__favourite {
    padding-left: 0;
    padding-right: 0;
  }

  .user__save-btn {
    width: 100%;
  }

  .user__dropdown {
    width: 100vw;
    top: 60px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    border: 0;
  }

  .user__dropdown a {
    font-size: 20px !important;
    line-height: 26px !important;
  }

  .user__settings h1 {
    font-weight: 550;
  }

  .user__contents input {
    font-size: 20px;
    line-height: 26px;
  }

  .password-popup {
    max-height: unset;
    padding: 20px 20px 0px 20px;
  }

  .password-popup h3 {
    margin-bottom: 100px;
    text-align: center;
  }

  .close-user-popup img:last-of-type {
    display: block;
  }

  .close-user-popup img:first-of-type {
    display: none;
  }

  .close-user-popup {
    left: 20px;
    top: 25px;
    right: auto;
  }
}

.main-page__ads {
  margin-bottom: 50px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  list-style-type: none;
}

li + .main-page__ads {
  margin-bottom: 0;
  display: none;
}

.main-page.container .main-page__ads {
  margin-bottom: 50px;
}

.main-page__ads img {
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
}

.main-page__promo {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 35px 30px 40px;
}

.main-page__promo-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 54px;
}

.main-page__promo-text h2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 51px;
  color: #272727;
}

.main-page__promo-text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  max-width: 650px;
}

.main-page__promo-promocode p {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 10px;
}

.main-page__promo-promocode div {
  padding: 14px 45px;
  border: 1px solid #e66366;
  border-radius: 5px;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  color: #000000;
}

.main-page__promo-swiper .swiper-slide {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.main-page__promo-swiper .swiper-slide a {
  text-decoration: none;
  width: 100%;
}

.main-page__promo-swiper .swiper-slide object {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  padding-left: 25px;
  padding-right: 25px;
  gap: 10px;
}

.promo-discount {
  min-width: fit-content;
}

.promo-discount p:first-of-type {
  font-weight: 450;
  font-size: 25px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 4px;
}

.promo-discount p:last-of-type {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.promo-swiper-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
  align-items: center;
}

.main-page__promo-bot .swiper-button-next,
.main-page__promo-bot .swiper-button-prev {
  position: relative;
  left: auto;
  right: auto;
  align-items: flex-end;
}

.main-page__promo-bot .swiper-scrollbar {
  position: static;
  margin-top: 20px;
  display: none;
}

.main-page__promo-bot .swiper-button-next::after {
  content: "";
  width: 16px;
  height: 16px;
  border: solid #777777;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(135deg);
  position: absolute;
}

.main-page__promo-bot .swiper-button-prev::after {
  content: "";
  width: 16px;
  height: 16px;
  border: solid #777777;
  border-width: 2px 0 0 2px;
  transform: translate(-40%, -40%) rotate(315deg);
  position: absolute;
}

.main-page__reviews {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 37px 0 0;
}

.main-page__reviews-review {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 331px;
  width: 300px;
  padding: 15px 15px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.main-page .school-rating-stars_empty {
  width: 110px;
  height: 20px;
  background: url(https://kurs-market.com/img/star.png);
  background-position: 0;
  background-size: auto 100%;
  background-repeat: repeat-x;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(335deg) brightness(93%)
    contrast(86%);
}

.main-page .school-rating-active {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  background: url(https://kurs-market.com/img/star.png);
  background-size: auto 100%;
  background-repeat: repeat-x;
  max-width: 110px;
}

.main-page .school-rating-stars {
  position: relative;
}

.main-page__reviews > h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  color: #272727;
  margin-bottom: 10px;
  padding-left: 32px;
}

.main-page__reviews p {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  max-width: 651px;
  padding-left: 32px;
}

.main-page__reviews-contents {
  padding-top: 33px;
  padding-bottom: 58px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: nowrap;
  justify-content: center;
  width: max-content;
}

.main-page__review-top h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #272727;
  margin-bottom: 5px;
}

.main-page__reviews-rating {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 9px;
}

.main-page__review-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #272727;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-page__review-more {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #777777;
}

.main-page__review-more:hover,
.main-page__review-more:active {
  color: #777777;
  opacity: 0.7;
}

.main-page__reviews-bot p {
  font-weight: 450;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
  margin-bottom: 5px;
}

.main-page__reviews-bot img {
  max-width: 100px;
}

.promo-image img {
  max-width: 150px;
  max-height: 50px;
  width: 100%;
}

.rewiews__contents-wrapper {
  overflow-x: auto;
}

.main-page__reviews-bot p {
  padding: 0;
}

ul.thim-text {
  list-style-type: "-  ";
}

.main-page__ratings-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}

.main-page__head-left p {
  max-width: 651px;
}

.main-page__ratings {
  padding-top: 30px;
  padding-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 50px;
}

.main-page__head-left > h2,
.main-page__head-left > p {
  padding-left: 0;
}

.main-page__ratings-head {
  margin-bottom: 28px;
  padding-left: 30px;
  padding-right: 60px;
}

.main-page__ratings-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  overflow: auto;
  padding-bottom: 24px;
  padding-top: 12px;
  padding-left: 30px;
  padding-right: 60px;
  margin-bottom: 40px;
}

.main-page__ratings-filters::-webkit-scrollbar {
  height: 3px;
  background: #cccccc;
}

.main-page__ratings-filters::-webkit-scrollbar-thumb {
  background: #e66366;
}

.main-page__ratings-filters a {
  border: 2px solid transparent;
  padding: 9px 20px;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #272727;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: max-content;
  height: 45px;
}

.main-page__ratings-filters a:hover,
.main-page__ratings-filters a:active {
  border: 2px solid #dbdbdb;
}

.main-page__ratings-filters a.modif-selected {
  border: 2px solid #dbdbdb;
}

.ratings__table-header {
  display: grid;
  border-bottom: 3px solid #ffffff;
  height: 43px;
  flex-wrap: nowrap;
  background: #f4f4f4;
  grid-template-columns: 22% 12% 15% 9% 13% 13% 16%;
  align-items: center;
}

.ratings__table-header div {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #777777;
  padding-left: 20px;
}

.school-rating-star {
  height: 15px;
  width: 15px;
}

.ratings__table-row {
  display: grid;
  grid-template-columns: 22% 12% 15% 9% 13% 13% 16%;
  align-items: baseline;
  width: 100%;
  z-index: 4;
}

.ratings__table-row:not(:last-of-type) {
  border-bottom: 3px solid #ffffff;
}

.ratings__table-row > div {
  padding-left: 20px;
}

.main-page .ratings__table-row-course {
  padding-top: 20px;
  background: #f4f4f4;
  padding: 20px;
}

.ratings__table-row-course p {
  font-weight: 450;
  font-size: 18px;
  line-height: 22px;
  color: #272727;
}

.mobile-rating-number {
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.ratings__table-row-school {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.main-page .ratings__table-row-school img {
  max-width: 110px;
  max-height: 20px;
}

.school-rating-container a {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #777777;
}

.school-rating-container a:hover,
.school-rating-container a:active {
  opacity: 0.7;
}

.school-rating-count {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #f5af33;
}

.school-rating-stars {
  display: flex;
  gap: 4px;
  flex-direction: row;
}

.school-rating-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.ratings__table-row-price div {
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #272727;
}

.main-page__head-right {
  display: flex;
}

.ratings__table-row-price span {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-decoration-line: line-through;
  color: #777777;
}

.ratings__table-row-price {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ratings__table-row-length span {
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #272727;
}

.ratings__table-row-link a {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #e66366;
}

.ratings__table-row-link a:hover,
.ratings__table-row-link a:active {
  opacity: 0.7;
}

.mobile-block {
  display: none;
}

.main-page__ratings-more {
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline !important;
  color: #777777;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.main-page__ratings-more:hover,
.main-page__ratings-more:active {
  opacity: 0.7;
  color: #777777;
}

@media (max-width: 1080px) {
  .main-page__ratings-more {
    margin-top: 0;
  }

  #main-page__ratings-courses-results {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    gap: 20px;
    padding: 0 60px 20px 30px;
  }

  .main-page .ratings__table-row {
    display: flex;
    flex-direction: column;
    width: 320px;
    padding: 12px 50px 40px 15px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #dbdbdb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    min-width: 320px;
    justify-content: space-between;
  }

  .main-page__ratings-courses {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    gap: 20px;
    padding-bottom: 30px;
  }

  .main-page__ratings-courses::-webkit-scrollbar {
    display: none;
  }

  .main-page__ratings-filters::-webkit-scrollbar-thumb {
    background: #999999;
  }

  .ratings__table-row > div {
    padding: 0;
  }

  .ratings__table-row > div:first-of-type {
    margin-bottom: 8px;
  }

  .ratings__table-row-course p {
    margin-bottom: 8px;
  }

  .ratings__table-row a > img {
    max-width: 140px;
    max-height: 30px;
  }

  .main-page .ratings__table-row-school,
  .main-page .ratings__table-row-price {
    display: none;
  }

  .mobile-block {
    display: inline-block;
  }

  .ratings__table-header {
    display: none;
  }

  .ratings__table-row-price span {
    display: none;
  }

  .ratings__table-row-rating a.mobile-block {
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #272727;
  }

  .ratings__table-row-course {
    background: none !important;
    padding: 0 !important;
  }

  .ratings__table-row-rating {
    display: flex;
    gap: 6px;
  }

  .ratings__table-row-rating span.mobile-block,
  .ratings__table-row-credit span.mobile-block,
  .ratings__table-row-length span.mobile-block {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #777777;
  }

  .school-rating-container a {
    display: none;
  }

  .school-rating-stars {
    display: none;
  }

  .mobile-rating-number {
    font-weight: 450;
  }

  .main-page .ratings__table-row-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
  }
}

@media (max-width: 768px) {
  #header__courses {
    font-size: 18px;
  }

  .catalog-container {
    top: 150px !important;
    left: 0 !important;
    z-index: 200 !important;
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px;
    position: fixed !important;
    width: 100vw !important;
    min-height: 90vh !important;
    overflow-y: auto !important;
    box-shadow: inset 0px 4px 10px rgb(0 0 0 / 15%) !important;
  }

  .catalog-container .catalog-contents ul.header__dropdown {
    left: 0 !important;
    overflow-y: auto !important;
    flex-wrap: nowrap !important;
  }

  .catalog-container > .catalog-contents > .header__dropdown {
    padding-left: 20px !important;
  }

  .main-page__ratings-head {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  .main-page__ads {
    margin-bottom: 40px;
  }

  .main-page__promo {
    margin-bottom: 30px;
    padding: 20px 20px 40px;
  }

  .main-page__promo-top {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .main-page__promo-promocode p {
    color: #777777;
  }

  .main-page__promo-promocode div {
    color: #272727;
    font-weight: 450;
    border: 0;
    padding: 0;
  }

  .container:has(.main-page__promo),
  .container:has(.main-page__ratings),
  .container:has(.main-page__reviews) {
    padding: 0;
  }

  .main-page__promo-promocode p {
    margin-bottom: 6px;
  }

  .main-page__promo-bot .swiper-scrollbar {
    display: block;
    height: 5px;
  }

  .main-page__promo-bot .swiper-button-next,
  .main-page__promo-bot .swiper-button-prev {
    display: none;
  }

  .main-page__reviews {
    background: none;
    box-shadow: none;
  }

  .main-page__reviews > h2 {
    font-size: 30px;
    line-height: 35px;
  }
}

@media (max-width: 480px) {
  .main-page__ratings-courses {
    flex-direction: column;
    gap: 0px;
    padding: 0;
  }

  #main-page__ratings-courses-results {
    display: block;
    overflow: hidden;
  }

  .main-page .ratings__table-row {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #dbdbdb !important;
    border-radius: 0%;
    width: 100vw;
  }

  .main-page__ratings .main-page__test-btn {
    padding: 15px 30px;
  }

  .main-page__partners-el a {
    max-width: 125px;
  }
}

.about__top-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 62px;
}

.about__top-block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 42px 30px 50px 65px;
}

.about__top-block:not(:first-of-type) {
  padding-left: 35px;
}

.about__top-block li,
.about__top-block p {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #272727;
  list-style-type: none;
}

.about__top-block ul {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.about__top-block li,
.about__top-block p,
.about__top-block ul {
  margin: 0;
  padding: 0;
}

.about__top-block:first-of-type li {
  position: relative;
  padding-left: 15px;
}

.about__top-block:first-of-type li::before {
  content: url(https://kurs-market.com/images/about-before-arrow.svg);
  position: absolute;
  left: -25px;
}

.about__top-double {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about__top-double .about__top-block {
  padding: 30px 46px 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 141px;
}

.about__title {
  font-weight: 500;
  font-size: 48px;
  line-height: 52px;
  color: #272727;
  text-align: center;
  margin-bottom: 14px;
}

.about__subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  max-width: 714px;
  margin: 0 auto 20px auto;
}

.about__mid-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  min-height: 198px;
  margin-bottom: 40px;
}

.about__mid-pictures {
  position: relative;
  width: 50%;
}

.about__mid-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 654px;
  justify-content: center;
  padding-left: 47px;
}

.additional-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 160%;
}

.about__mid-content h2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 51px;
  color: #272727;
  margin: 0;
}

.about__mid-content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  margin: 0;
}

.about__mid-pictures img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

.about__mid-pictures img:first-of-type {
  left: 120px;
  top: -12px;
}

.about__mid-pictures img:last-of-type {
  right: 0;
  top: 48px;
}

.about__bot-wrapper .main-page__partners-el.link a {
  color: #777777;
}

.about__bot-wrapper .main-page__partners h4 {
  font-weight: 500;
}

.user__contents article {
  display: none;
  min-height: 100vh;
}

.user__contents article.active {
  display: block;
}

.user .user__nav a.active {
  background: #ffffff;
}

.plate-item-body .plate-item__abs.right-side {
  justify-content: flex-end;
}

.favourite-course-new {
  margin-left: auto;
}

.course-banner .plate-item__abs {
  display: flex;
  position: absolute;
  top: 22px;
  z-index: 5;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 280px;
}

.plate-item-body .favourite-course-new a {
  display: block;
  width: 18px;
  height: 18px;
  background: url(../img/svg/course-fav.svg) no-repeat center;
}

.plate-item-body .favourite-course-new a.favourite {
  background: url(../img/svg/course-fav_red.svg) no-repeat center;
}

.plate-item-body .favourite-course-new a:hover,
.plate-item-body .favourite-course-new a:active {
  background: url(../img/svg/course-fav_red.svg) no-repeat center;
}

.user__favourite-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
}

.user__favourite h2 {
  margin-bottom: 20px;
}

.user__favourite .plate-listing li.plate-item {
  box-shadow: none;
}

.user__favourite .plate-item .plate-item-body {
  box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
  border-radius: 10px;
}

@media (max-width: 1399px) {
  .additional-wrapper {
    width: 100%;
  }

  /* .about__bot-wrapper .main-page__partners-els {
        grid-template-columns: repeat(6, 1fr);
    } */

  .about__top-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .about__top-block {
    width: 50% !important;
  }

  .about__top-double {
    flex-direction: row;
  }

  .about__mid-wrapper {
    flex-direction: column;
  }

  .about__mid-pictures {
    width: 100%;
    height: 180px;
  }

  .about__mid-content {
    padding-top: 35px;
    max-width: 540px;
  }

  .about__mid-pictures img:first-of-type {
    left: unset;
    right: 0;
  }

  .about__mid-pictures img:last-of-type {
    right: 290px;
  }

  /* .about__bot-wrapper .tablet-hide {
        display: none;
    } */
}

@media (max-width: 991px) {
  .about__mid-wrapper {
    margin-bottom: 60px;
  }

  .about__bot-wrapper .main-page__partners h4 {
    padding: 0;
  }

  .about__bot-wrapper .main-page__partners {
    box-shadow: none;
  }

  .about__bot-wrapper .main-page__partners-els {
    padding-top: 20px;
    padding-left: 0;
  }

  .about__bot-wrapper .main-page__partners-els + a {
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #777777;
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }

  .about__bot-wrapper .main-page__partners-els + a:hover,
  .about__bot-wrapper .main-page__partners-els + a:active {
    opacity: 0.7;
  }

  .about__bot-wrapper .main-page__partners-els {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: scroll;
  }

  .about__bot-wrapper .tablet-hide {
    display: block;
  }

  .additional-wrapper {
    flex-direction: column;
    gap: 30px;
  }

  .about__mid-content {
    padding-top: 14px;
    padding-left: 0;
  }

  .about__mid-pictures img:first-of-type {
    left: 0;
    top: 30px;
  }

  .about__mid-pictures img:last-of-type {
    right: unset;
    left: 280px;
    top: 170px;
  }

  .about__top-block {
    width: 100% !important;
  }

  .about__top-double {
    flex-direction: column;
  }

  .about__mid-wrapper {
    height: 480px;
  }

  .about__top-double .about__top-block {
    height: auto;
  }

  .about__top-block {
    box-shadow: none;
    padding: 0;
  }

  .about__top-wrapper {
    gap: 30px;
    margin-bottom: 40px;
  }

  .about__top-double .about__top-block,
  .about__top-block:not(:first-of-type) {
    padding: 0 !important;
  }

  .about__top-block:first-of-type {
    padding-left: 25px;
  }

  .about__subtitle,
  .about__title {
    text-align: left;
  }

  .about__subtitle {
    margin-bottom: 50px;
    font-size: 20px;
  }

  .about__title {
    font-size: 35px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .about__mid-wrapper {
    box-shadow: none;
  }
}

@media (max-width: 500px) {
  .about__mid-pictures img:last-of-type {
    left: unset;
    right: 10px;
  }

  .about__mid-pictures img {
    max-width: 70%;
  }
}

.main-page__benefits {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 50px;
}

.main-page__benefits-item {
  padding: 20px 20px 25px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 440px;
  width: 100%;
}

.main-page__benefits-item h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #272727;
  margin: 0;
}

.main-page__benefits-item p {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  letter-spacing: -0.01em;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 1140px) {
  .main-page__benefits {
    flex-direction: column;
    gap: 10px;
  }

  .main-page__benefits-item {
    max-width: unset;
    padding: 14px 30px 20px;
  }

  .main-page__benefits-item h2 {
    font-size: 30px;
    line-height: 38px;
  }
}

@media (max-width: 768px) {
  .main-page__benefits-item h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .main-page__benefits-item p {
    font-size: 18px;
    line-height: 22px;
  }

  .main-page__benefits-item {
    padding: 10px 20px 20px;
  }
}

.review__body {
  margin-top: 10px;
}
.js-list-table {
  max-height: 515px;
  overflow-y: auto;
}
.upper:first-letter {
  text-transform: uppercase;
}

.col-12 .card {
  overflow: hidden;
  overflow-x: scroll;
}

.toastWrapper {
  top: 0% !important;
  border-radius: clamp(10px, 1.47vw, 20px) !important;
}

.toastItem {
  padding: clamp(10px, 1.47vw, 20px) clamp(20px, 2.94vw, 40px) !important;
  font-size: clamp(1rem, 2.35vw, 1.5rem) !important;
}

.toastItem > i {
  font-style: normal !important;
  transform: rotate(-15deg);
}

.hideItem {
  display: none;
}

button {
  span {
    font-family: 'Futura PT', sans-serif;
  }
}

.catalog-container {
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
}

button:focus {
  outline: none;
}

body main {
	display: block;
	color: #272727;
}

a {
	color: #272727;
	text-decoration: none;
}

a:hover {
	color: #272727;
	text-decoration: none;
}

p {
	margin-bottom: 0;
}

.ratings__wrapper {
	margin: 0 auto;
	max-width: 1360px;
}

.ratings__title {
	font-weight: 500;
	font-size: 48px;
	line-height: 52px;
	margin-bottom: 15px;
}

.icon-reverted {
	transform: scale(1, -1);
}

.ratings__title ~ p {
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 6px;
}

.table-header-title {
	cursor: pointer;
}

.ratings__title ~ p:last-of-type {
	margin-bottom: 24px;
}

.ratings__title ~ p > a {
	text-decoration: underline;
	color: #272727;
}

.ratings__title ~ p > a:hover {
	opacity: 0.8;
}

.ratings__modificators {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 25px;
	max-width: 1269px;
}

.ratings__modificators > a {
	color: #777777;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	background: #FFFFFF;
	border: 1px solid #DBDBDB;
	border-radius: 3px;
	height: 25px;
	padding: 0 7px;
}

.ratings__modificators > a > img {
	display: none;
}

.ratings__modificators > a:hover {
	background: #777777;
	color: #FFFFFF;
}

.ratings__sorting {
	display: flex;
	flex-direction: row;
	gap: 20px;
	font-weight: 450;
	font-size: 18px;
	line-height: 24px;
}

.ratings__sorting-filters {
	display: flex;
	flex-direction: row;
	gap: 15px;
	font-weight: 400;
	margin-bottom: 10px
}

.ratings__sorting-filters > a {
	color: #777777;
	text-decoration: none;
}

.ratings__sorting-filters > a:hover {
	color: #272727;
}

.ratings__sorting-filters > a::after {
    display:block;
    content:attr(title);
    font-weight:bold;
    height:1px;
    color:transparent;
    overflow:hidden;
    visibility:hidden;
}

.ratings__sorting-results,
.ratings__free-courses {
	font-weight: 450;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 80px;
}

.ratings__sorting-results {
	position: relative;
}

.ratings__table-header {
	display: grid;
	border-bottom: 3px solid #FFFFFF;
	height: 43px;
	flex-wrap: nowrap;
	background: #F4F4F4;
	grid-template-columns: 22% 12% 15% 9% 13% 13% 16%;
	align-items: center;
}

.ratings__table-header > span,
.ratings__table-header > div {
	font-weight: 600;
	font-size: 17px;
	padding-left: 10px;
	position: relative;
	display: flex;
	gap: 6px;
	align-items: center;
}

.ratings__sorting-results > .ratings__table-header > span:first-child,
.ratings__free-courses > .ratings__table-header > span:first-child,
.ratings__sorting-results > .ratings__table-header > div:first-child {
	padding-left: 20px;
}

.ratings__table-row {
	display: grid;
	grid-template-columns: 22% 12% 15% 9% 13% 13% 16%;
	align-items: baseline;
	width: 100%;
	z-index: 4;
}

.ratings__table-row:hover {
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.ratings__table-row:not(:last-of-type) {
	border-bottom: 3px solid #FFFFFF;
}

.ratings__table-row:not(:last-of-type):hover {
	border-bottom: none;
}

.ratings__table-row:hover {
	position: relative;
}

.ratings__table-row:first-of-type {
	z-index: 1;
}

.ratings__table-row-course {
	height: 140px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	background: #F4F4F4
}

.ratings__table-row-course:hover {
	z-index: -1;
}

.ratings__table-row-course > div {
	display: flex;
	flex-direction: row;
	gap: 30px;
	justify-content: space-between;
}

.ratings__table-row-course > div > a {
	font-weight: 450;
	font-size: 16px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #E66366;
}

.ratings__table-row-course > div > a:hover {
	opacity: 0.7;
}

.ratings__table-row-course > div > span {
	color: white;
	background: linear-gradient(90deg, #77A654 0%, #60A55A 100%);
	border-radius: 3px;
	padding: 1px 4px 2px;
}

.ratings__table-row-school {
	display: flex;
	flex-direction: column;
	gap: 5px;
	max-width: 300px;
}

.ratings__table-row-school > a {
	font-weight: 450;
	font-size: 16px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #E66366;
	width: fit-content;
}

.ratings__table-row-school > div:first-child {
	display: flex;
	gap: 5px;
}

.ratings__right-wrapper {
	max-width: 1014px;
	width: 100%;
}

.ratings__table-row-school > a:hover {
	opacity: 0.7;
}

.ratings__table-row-logo {
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-bottom: 5px;
}

.ratings__table-row-rating {
	padding-left: 20px !important;
}

.ratings__table-row-rating > span {
	padding: 1px 5px;
	border-radius: 3px;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
}

.ratings__table-row-rating > span.bg-best {
	background: linear-gradient(90deg, #77A654 0%, #60A55A 100%);
}

.ratings__table-row-rating > span.bg-good {
	background: linear-gradient(90deg, #96A84B 0%, #80A751 100%);
}

.ratings__table-row-rating > span.bg-mixed {
	background: linear-gradient(90deg, #B6AA43 0%, #9EA949 100%);
}

.ratings__table-row-rating > span.bg-bad {
	background: linear-gradient(90deg, #D5AD3B 0%, #BDAB41 100%);
}

.school-rating-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
}

.school-rating-count {
	font-weight: 500;
	font-size: 18px;
	line-height: 15px;
	color: #F5AF33;
}

.school-rating-stars {
	position: relative;
}

.school-rating-stars_empty {
	width: 105px;
	height: 20px;
	background: url(https://kurs-market.com/images/star.png);
	background-position: 0;
	background-size: auto 100%;
	background-repeat: repeat-x;
	filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(335deg) brightness(93%) contrast(86%);
}

.school-rating-active {
	position: absolute;
	left: 0;
	top: 0;
	height: 20px;
	background: url(https://kurs-market.com/images/star.png);
	background-size: auto 100%;
	background-repeat: repeat-x;
}

.ratings__table-row-price {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.ratings__table-row-price > span:last-child {
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	text-decoration-line: line-through;
	color: #777777;
}

.ratings__table-row-link > a {
	background: #E66366;
	border-radius: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	text-align: center;
	color: #FFFFFF !important;
	padding: 9px 16px 10px;
}

.ratings__table-row-link > a:hover {
	opacity: 0.7;
}

.ratings__table-row-length {
	line-height: 15px;
}

.ratings__subtitle {
	font-weight: 600;
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 20px;
}

.four-items {
	grid-template-columns: 30% 23% 23% 24%;
	align-items: center;
}

.five-items {
	grid-template-columns: 25% 25% 17% 17% 16%;
}

.ratings__table-row-features {
	font-weight: 400;
	font-size: 18px;
	line-height: 15px;
}

.ratings__free-courses > div > .ratings__table-row-features {
	font-size: 16px;
	line-height: 21px;
}

.ratings__free-courses > div > .ratings__table-row-school {
	padding-left: 15px;
}

.ratings__courses-on-topic {
	margin-bottom: 94px;
	display: none;
}

.rating__course-item-on-topic {
	display: flex;
	gap: 68px;
	margin-bottom: 46px;
}

.ratings__school-subtitle {
	font-weight: 400;
	font-size: 20px;
	line-height: 21px;
	color: #777777;
}

.ratings__short-desc {
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	margin-top: 10px;
}

.ratings__table-row-school > div > span {
	font-weight: 500;
	font-size: 20px;
	line-height: 21px;
}

.ratings__table-row.four-items {
	height: 72px;
}

.ratings__table-row.four-items > .ratings__table-row-course {
	background: none;
	padding: 0 10px 0 10px;
	height: auto;
}

.ratings__table-row.four-items > .ratings__table-row-link > a {
	border: 2px solid #E66366;
	color: #E66366;
	background: white;
}

.ratings__table-row.four-items > .ratings__table-row-link > a:hover {
	background: #E66366;
	color: white;
}

.ratings__table-row.four-items:not(:last-of-type) {
	border-bottom: 1px solid #DBDBDB;
}

.ratings__more-subtitle {
	font-weight: 600;
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 20px;
}

.ratings__more-courses-list {
	padding: 0;
	margin-bottom: 80px;
	list-style-type: none;
	display: flex;
	flex-direction: row;
	gap: 25px;
	flex-wrap: wrap;
}

.courses-list-item-body {
	position: relative;
	padding: 20px 20px 0 20px;
}

.ratings__school-title {
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	position: absolute;
	max-width: 254px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.courses-list-item-body > .ratings__school-subtitle {
	margin-top: 62px;
	margin-bottom: 5px;
	font-weight: 450;
	font-size: 16px;
	line-height: 21px;
}

.school-rating-container {
	margin-bottom: 18px;
}

.school-rating-container > a {
	margin-left: 10px;
	color: #E66366;
	text-decoration-line: underline;
	font-weight: 450;
	font-size: 16px;
	line-height: 24px;
}

.school-rating-container > a:hover {
	opacity: 0.7;
}

.courses-list-item-price {
	display: flex;
	gap: 30px;
	margin-bottom: 24px;
	font-weight: 500;
	font-size: 20px;
	line-height: 15px;
}

.courses-list-item {
	max-width: 320px;
	border: 1px solid #DBDBDB;
	border-radius: 5px;
	height: 428px;
	position: relative;
}

.courses-list-item:hover {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.courses-list-item-link {
	background: white;
	color: #E66366;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	line-height: 21px;
	border: 1px solid #E66366;
	border-radius: 0px 0px 5px 5px;
	position: absolute;
    bottom: 0;
    width: 100%;
}

.courses-list-item-link:hover {
	color: white;
	background: #E66366;
}

.courses-list-item-body > .school-rating-container > .school-rating-count {
	font-weight: 450;
	font-size: 15px;
	color: #272727;
}

.ratings__another-courses {
	margin-bottom: 180px;
}

.loader__wrapper {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	display: flex;
	justify-content: center;
	padding-top: 10vh;
	background: rgba(0, 0, 0, 0.04);
	box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.1);
	pointer-events: none;
}



@media (max-width: 768px) {
	.ratings__another-courses {
		margin-bottom: 20px;
	}

	.element-item {
		width: 100%;
		height: 231px;
	}


.loader__wrapper {
	padding-top: 5vh;
}
}

.ratings__another-courses-subtitle {
	font-weight: 600;
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 35px;
}

.ratings__another-courses-column {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.ratings__another-courses-row {
	display: flex;
	flex-direction: row;
	gap: 170px;
}

.ratings__another-courses-column > a {
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
}

.ratings__another-courses-column > a:hover {
	color: #E66366;
}


.ratings__table-row.four-items > div:not(:first-child),
.ratings__table-row.five-items > div:not(:first-child) {
	padding-left: 10px;
}

.courses-list-item-format,
.courses-list-item-about {
	line-height: 20px;
}

.courses-list-item-about {
	margin-bottom: 21px;
}

.ratings__table-row > div:not(:first-child) {
	padding-left: 10px;
}

.modificator-active {
	background: #777777 !important;
	color: #FFFFFF !important;
	display: flex;
	align-items: baseline;
	gap: 5px;
}

.modificator-active > img,
.sorting-active > img {
	display: block !important;
}

.ratings__sorting-filters > a > img {
	display: none;
}

.sorting-active {
	font-weight: 450;
	color: #272727 !important;
	display: flex;
	gap: 5px;
	align-items: baseline;
}

.rating__hint {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	font-weight: 300;
	font-size: 18px;
	line-height: 21px;
	background: #FFFFFF;
	border: 1px solid #DEDEDE;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	width: 250px;
	padding: 10px;
	z-index: 10;
	top: 25px;
	left: 72px;
}

.credit__hint {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	font-weight: 300;
	font-size: 18px;
	line-height: 21px;
	background: #FFFFFF;
	border: 1px solid #DEDEDE;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	width: 250px;
	padding: 10px;
	z-index: 10;
	top: 25px;
	left: 112px;
}

.rating__hint-btn:hover ~ .rating__hint {
	visibility: visible;
	opacity: 1;
}

.credit__hint-btn:hover ~ .credit__hint {
	visibility: visible;
	opacity: 1;
}

.ratings__table-row-credit {
	position: relative;
}

.ratings__table-row-credit > span {
	display: flex;
	gap: 5px;
}

.sort-btn {
	display: flex;
	gap: 3px;
}

.cross-img-wrapper:hover {
	opacity: 0.7;
	cursor: pointer;
}

.sort-img-wrapper {
	display: flex;
	align-items: center;
}

.cross-img-wrapper {
	display: flex;
	align-self: flex-start;
}

.cross-img-wrapper > img {
	display: none;
}

.rating-hint-btn:after {
	content: "";
	width: 8px;
	height: 8px;
	border: solid #E66166;
	border-width: 2px 0 0 2px;
	transform: translate(-40%, -40%) rotate(135deg);
}

.arrows-wrapper {
	display: flex;
	flex-direction: column;
	gap: 5px;
	pointer-events: none;
}

.arrows-wrapper a,
.arrows-wrapper img {
	pointer-events: none;
}

.arrows-wrapper > a:hover {
	opacity: 0.6;
}

.transparent-arrow {
	opacity: 0.6;
}

@media (max-width: 768px) {
	.ratings__table-header {
		display: none;
	}

	.ratings__dropdown>li:hover>a, .ratings__dropdown>li>a:hover {
		color: #333 !important;
	}

	.ratings__sorting-filters {
		flex-wrap: wrap;
		margin-right: 20px;
	}

	.ratings__wrapper {
		max-width: 320px !important;
	}

	.ratings__title {
		font-size: 25px;
		line-height: 30px;
		margin-bottom: 10px;
	}

	.ratings__title ~ p {
		margin-bottom: 10px;
	}

	.ratings__title ~ p:last-of-type {
		margin-bottom: 31px;
	}

	.ratings__modificators {
		overflow-x: scroll;
		overflow-y: hidden;
		flex-wrap: nowrap;
		max-width: unset;
		height: 40px;
		width: 95vw;
		margin-bottom: 16px;
		padding-right: 40px;
	}

	.ratings__modificators > a {
		min-width: fit-content;
		background: #F4F4F4;
	}

	.sorting__options {
		visibility: hidden;
		opacity: 0;
		background: #FFFFFF;
		box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		padding: 10px;
		margin-top: 5px;
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		position: absolute;
		z-index: 1;
		display: flex;
		flex-direction: column;
		gap: 12px;
		max-width: 190px;
	}

	label {
		margin-bottom: 0;
	}

	.sorting-mobile-btn,
	.sorting-mobile-btn:hover,
	.sorting-mobile-btn:active,
	.sorting-mobile-btn:focus {
		border: none;
		outline: none;
		background: none;
		position: relative;
	}

	.ratings__sorting {
		margin-bottom: 26px;
		align-items: baseline;
	}

	.ratings__sorting-results {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	.ratings__table-row {
		height: 216px;
		display: flex !important;
		position: relative;
		justify-content: space-between;
		flex-direction: column;
		background: rgba(255, 255, 255, 0.8);
		border: 1px solid #DBDBDB;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		padding: 22px 15px 30px;
	}

	.ratings__table-row-logo {
		font-weight: 450;
		font-size: 16px !important;
		line-height: 21px;
		color: #777777;
	}

	.ratings__more-courses-list {
		gap: 20px;
		margin-bottom: 25px;
	}

	.ratings__another-courses-row {
		gap: 15px;
		flex-direction: column;
	}

	.rating-mobile__show-more {
		font-weight: 450;
		font-size: 20px;
		line-height: 24px;
		text-decoration-line: underline;
		color: #E66366;
		display: flex;
		justify-content: center;
	}

	.rating-mobile__show-more:hover,
	.rating-mobile__show-more:active,
	.rating-mobile__show-more:focus {
		opacity: 0.7;
		color: #E66366;
		text-decoration: underline;
	}

	.ratings__free-courses,
	.ratings__sorting-results,
	.ratings__courses-on-topic {
		margin-bottom: 25px;
	}

	.ratings__another-courses,
	.ratings__courses-on-topic,
	.ratings__free-courses,
	.ratings__more {
		margin-top: 60px;
	}

	.ratings__table-row-course {
		background: none;
		padding: 0;
		height: auto;
		margin-bottom: 5px;
		position: initial;
	}

	.ratings__table-row > div:not(:first-child) {
		font-size: 20px;
	}

	.ratings__table-row-course > div {
		top: -12px;
		left: 0px;
		bottom: unset;
	}

	.ratings__table-row-course > div > p {
		font-size: 20px;
	}

	.mobile__rating-reviews-block {
		display: flex;
		gap: 11px;
		flex-direction: row;
		align-items: center;
	}

	.ratings__table-row-rating > span {
		color: #272727;
		background: none;
	}

	.related {
		display: none;
	}

	.mobile__rating-reviews-block > .ratings__table-row-rating > .mobile-rating-number {
		padding: 0 4px;
		border-radius: 3px;
		color: white;
		font-weight: 450;
		height: fit-content;
	}

	.ratings__table-row-rating > span:first-child {
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: #777777;
	}

	.mobile__rating-reviews-container {
		display: flex;
		flex-direction: column;
		gap: 3px;
		margin-bottom: 25px;
	}

	.mobile__price-link-container {
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		align-self: center;
	}

	.ratings__table-row:not(:last-of-type) {
		border-bottom: none;
	}

	.ratings__modificators::-webkit-scrollbar {
		height: 3px;
		background: #CCCCCC;
	}

	.ratings__modificators::-webkit-scrollbar-thumb {
		background: #E66366;
		height: 3px;
		width: 84px;
	}

	.ratings__title ~ p > a {
		font-weight: 450;
	}

	.nav-breadcrumbs {
		padding-left: 10px !important;
	}

	.ratings__table-row-rating {
		padding: 0 !important;
		padding-left: 10px !important;
		display: flex;
		flex-direction: row;
		gap: 7px;
	}

	.ratings__table-row-rating ~ a {
		font-size: 16px;
		line-height: 24px;
		text-decoration-line: underline;
	}

	.ratings__table-row-rating ~ a:hover {
		opacity: 0.7;
	}

	.ratings__table-row-rating > span {
		padding: 0;
	}

	.ratings__table-row-course > div > span {
		font-weight: 500;
		font-size: 18px !important;
		line-height: 22px;
		padding: 1px 10px;
		position: absolute;
		top: -13px;
		left: 0;
	}

	.mobile-rating-number {
		padding: 0 4px !important;
		border-radius: 3px !important;
		color: white !important;
		font-weight: 450 !important;
		height: fit-content;
	}

	.school-rating-container {
		display: none;
	}

	.ratings__table-row-school>div:first-child {
		display: none;
	}

	.ratings__table-row-course>div {
		display: none;
	}

	.ratings__table-row-school>a {
		font-size: 16px;
		line-height: 24px;
		text-decoration-line: underline;
		color: #272727;
		position: absolute;
		bottom: 114px;
		left: 80px;
	}

	.ratings__table-row-credit>span {
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: #777777;
		margin-top: -22px;
	}

	.ratings__table-row-price {
		order: 1;
	}

	.ratings__table-row-price div {
		font-size: 20px;
		font-weight: 450;
	}

	.ratings__table-row-link {
		position: absolute;
		bottom: 18px;
		left: 140px;
	}

	.mobile__course-price {
		line-height: 15px;
	}

	.ratings__subtitle {
		font-weight: 500;
		font-size: 25px;
		line-height: 27px;
		margin-bottom: 49px;
		text-align: center;
	}

	.ratings__free-courses > div > .ratings__table-row-school {
		padding-left: 0;
	}

	.mobile__reviews-link-container {
		display: flex;
		flex-direction: row;
		gap: 31px;
		margin-top: 25px;
		align-items: center;
	}

	.course_free .ratings__table-row {
		height: 100%;
	}

	.course_free {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	.course_free img {
		max-width: 140px;
	}

	.course_free .ratings__table-row-logo {
		flex-direction: column;
	}

	.ratings__table-row.five-items > div:not(:first-child) {
		padding-left: 0;
	}

	.mobile__reviews-link-container > a {
		font-size: 16px;
		line-height: 24px;
		text-decoration-line: underline;
	}

	.mobile__reviews-link-container > a:hover {
		opacity: 0.7;
	}

	.ratings__table-row-logo > img ~ span {
		font-weight: 450;
		font-size: 16px;
		line-height: 21px;
		color: #777777;
	}

	.rating__course-item-on-topic {
		margin-bottom: 15px;
		display: none;
	}

	.ratings__short-desc {
		border-bottom: 1px solid #DBDBDB;
		padding-bottom: 20px;
	}

	.school-rating-container {
		margin-bottom: 0px;
	}

	.mobile__container {
		display: flex;
		flex-direction: row;
		gap: 25px;
	}

	.mobile__container > a {
		color: #E66366;
		text-decoration-line: underline;
	}

	.mobile__container > a:hover {
		opacity: 0.7;
	}

	.courses-list-item-price {
		margin-top: 19px;
	}

	.ratings__table-row.four-items {
		border: none;
		box-shadow: none;
		padding: 0;
		font-weight: 450;
		font-size: 18px;
		line-height: 22px;
		align-items: flex-start;
		height: auto;
		gap: 15px;
		border-bottom: 1px solid #DBDBDB;
		padding-bottom: 15px;
	}

	.ratings__table-row.four-items:hover {
		box-shadow: none;
	}

	.ratings__right-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-top: 10px;
	}

	.ratings__table-row.four-items > .ratings__table-row-course {
		padding: 0;
	}

	.ratings__table-row.four-items > div {
		padding-left: 0 !important;
	}

	.ratings__table-row-course {
		font-weight: 450;
		font-size: 18px;
		line-height: 22px;
	}

	.ratings__table-row.four-items {
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: center;
		border-radius: 0;
	}

	.ratings__table-row.four-items > .ratings__table-row-features,
	.ratings__table-row.four-items > .ratings__table-row-length {
		font-weight: 400;
		font-size: 18px;
		line-height: 15px;
	}

	.ratings__table-row-length {
		color: #777777;
	}

	.ratings__table-row.four-items > .ratings__table-row-link > a {
		padding: 0;
		color: unset;
		border: none;
	}

	.ratings__table-row-link {
		font-weight: 450;
		font-size: 16px !important;
		line-height: 24px;
		display: flex;
		flex-direction: row;
		gap: 7px;
	}

	.ratings__right-wrapper > a {
		font-size: 16px;
	}

	.ratings__table-row-school {
		max-width: unset;
	}

	.sorting__options > div > input {
		display: none;
	}

	.ratings__table-row.four-items > div {
		width: 33%;
	}

	.ratings__table-row.four-items > .ratings__table-row-link > a:hover {
		background: none;
	}

	.carousel-item {
		transition: -webkit-transform .3s ease-in-out;
		transition: transform .3s ease-in-out;
		transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
	}

	.carousel-indicators {
		bottom: unset;
		top: -26px;
	}

	.carousel-indicators > li {
		width: 20px;
		height: 5px;
		background-color: #4B4B4B;
		border-top: none;
		border-bottom: none;
		border-radius: 5px;
	}

	.grid {
		display: grid;
		grid-gap: 20px;
	}

	.best-rating {
		font-weight: 500;
		font-size: 15px;
		line-height: 22px;
		color: #FFFFFF;
		padding: 1px 10px !important;
		background: linear-gradient(90deg, #77A654 0%, #60A55A 100%);
		border-radius: 3px;
		position: absolute;
		top: -12px;
		left: 0px;
		z-index: 5;
	}
}

@media (max-width: 1400px) {
	.ratings__wrapper {
		max-width: 1200px;
	}

	.ratings__table-row-course > div {
		gap: 5px;
	}
}

.rating__course-item-on-topic > .ratings__table-row-school > .school-rating-container {
	margin-bottom: 0;
}

.rating__course-item-on-topic {
	font-size: 18px !important;
}

.course_hidden {
	opacity: 0;
	visibility: hidden;
}

.mobile-sorting-params label {
	width: 100%;
}

.ratings__table-header div {
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #777777;
	padding-left: 20px;
}

.ratings__table-header div[data-sort-wrap="rating"] {
	line-height: 6px;
}

.ratings__more-courses-list .courses-list-item-price {
	font-size: 15px;
}

@media (max-width: 1080px) {
	.ratings__table-row:hover {
		box-shadow: none;
	}
}

@media (max-width: 1080px) and (min-width: 768px) {
	.ratings__table-header div {
		padding-left: 0;
	}

	.ratings__table-header > div {
		padding-left: 10px;
	}

	.ratings__table-header div[data-sort-wrap=rating] {
		padding-left: 0;
	}

	.ratings__table-row-school>div>span,
	.ratings__table-row-price div,
	.ratings__table-row-credit>span,
	.ratings__table-row-course p,
	.ratings__table-row-course>div>span,
	.school-rating-count {
		font-size: 16px;
	}

	.ratings__table-row-course>div>span {
		width: max-content;
	}

	.ratings__table-row-link>a {
		font-size: 14px;
		padding: 4px 8px 5px;
	}

	.ratings__table-row-course>div {
		flex-direction: column-reverse;
	}

	.ratings__table-row>div:not(:first-child) {
		padding-top: 10px;
	}

	.ratings__wrapper {
		padding: 0 10px;
	}

	.ratings__table-header {
		margin: 0 -10px;
	}

	.ratings__sorting-results>.ratings__table-header>div:first-child {
		padding-left: 10px;
	}

	.ratings__table-header div span:first-of-type {
		padding-left: 10px;
	}
}

@media (max-width: 850px) and (min-width: 768px) {
	.ratings__wrapper {
		padding: 0 6px;
	}

	.ratings__sorting-results>.ratings__table-header>div:first-child {
		padding-left: 6px;
	}

	.ratings__table-header {
		margin: 0 -6px;
	}

	.ratings__table-header>div {
		gap: 4px;
	}

	.ratings__table-row-school>div>span,
	.ratings__table-row-price div,
	.ratings__table-row-credit>span,
	.ratings__table-row-course p,
	.ratings__table-row-course>div>span,
	.school-rating-count,
	.ratings__table-row-course>div>a,
	.ratings__table-row-rating>span,
	.ratings__table-row-school>a {
		font-size: 14px;
	}

}

@media (max-width: 1400px) and (min-width: 1080px) {
	.ratings__table-row-course>div>span {
		font-size: 12px;
	}
}

*:not(.main-wrapper) {
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
  }

  @media (min-width: 1400px) {
    .container {
        max-width: 1360px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }
  }
}

ul.plate-listing {
  padding: 0;
  margin: 55px 0 0;
  display: flex;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

li.plate-item {
  flex: 0 0 47%;
  display: block;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 30px;
  position: relative;
  padding-left: 245px;
  overflow: hidden;
}
.no-places {
  position: relative;
  overflow: hidden;
}
.no-places:after {
  content: 'Нет в наличии';
  position: absolute;
  background: #da39f9;
  left: -74px;
  top: -5px;
  padding: 51px 54px 7px;
  color: #fff;
  transform: rotate(-45deg);
  z-index: 10;
}
.plate-img {
  position: absolute;
  width: 235px;
  top: 0;
  left: 0;
  height: 100%;
  background-size: cover;
  background-position: center;
  border: 20px solid #fff;
  border-radius: 38px;
}

.raiting-block {
  font-family: 'Conv_HelveticaNeueCyr-Bold';
  padding: 4px 0 3px 28px;
  background-image: url(/images/star.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 center;
  line-height: 1;
  font-size: 14px;
  letter-spacing: 1px;
}

.plate-item-top a ,.plate-item-top button {
  display: inline-block;
  height: 20px;
  width: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  margin-left: 3px;
  border: none;
  background-color: transparent;
  outline: none;
}

.plate-item-body h2 {
  font-family: 'Conv_HelveticaNeueCyr-Bold';
  margin-bottom: 3px;
  align-self: flex-start;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.plate-item-body h2 a {
  color: #444444;
  font-size: 20px;
}
.market-wrap {
  /*background-image: url(/images/pattern.png);*/
  background-position: center -15px;
  flex: 1 1 auto;
}

.pagination-hidden {
  display: none;
}

.pagination-links {
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .pagination-links {
      -moz-column-gap: 15px;
      column-gap: 15px;
  }
}

@media (max-width: 767px) {
  .page-numbers {
      margin-top: 15px;
  }
}

#reviews-block {
  overflow: hidden;
}

.open-filters {
  position: relative;
}

.market-all-filters {
  display: none!important;
}

.open-filters.is-active .market-all-filters {
  display: block!important;
}

.open-filters.is-active .select-btn {
  box-shadow: rgb(101, 130, 253) 0px 0px 0px 1px;
}

@media (max-width: 1200px) {
  .school-page__courses .market__filters {
    width: 100%;
  }
  .mobile-grow {
    flex-grow: 1;
  }
  .school-page__courses .market-all-filters {
    left: unset;
    right: 0;
  }
  .school-page__wrapper {
    overflow: hidden;
  }
}
