.cookie-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 0;
  background-color: #E66366;
  color: white;
  font-size: 19px;
  line-height: 23px;
  z-index: 99;
  display: none;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 20px;
      font-size: 14px;
    }
  }

  p {
    max-width: 595px;
  }

  button {
    border-radius: 10px;
    background: var(--white, #FFF);
    color: var(--Dark-black-surface, #141414);
    text-align: right;
    font-family: "Futura PT";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 16px;
    border: unset;
    padding: 12px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

