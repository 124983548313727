// .main-wrapper {
  .breadcrumb {
    padding: 2rem 0;
    ul {
      @include reset-list;

      display: flex;
      gap: 5px;
    }

    li {
      display: flex;
      align-self: center;
      flex-direction: row;
      transition: 0.6s cubic-bezier(0.37, 0.31, 0, 1);
      color: var(--Main-colors-Grey, #777);
      font-size: 12.126px;
      line-height: 15.158px;

      &:not(:last-child) {
        &::after {
          content: ">";
          margin-left: 5px;
        }
      }
    }

    a {
      display: block;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #E66366;
        transform: scaleX(0) translateZ(0);
        transform-origin: 100% 50%;
        transition: transform 0.6s cubic-bezier(0.37, 0.31, 0, 1);
      }

      @include hover-focus {
        &::before {
          transform: scaleX(1) translateZ(0);
          transform-origin: 0 50%;
        }
      }
    }
  }
// }

// Breadcrumb
.breadcrumb {
  overflow: hidden;
  margin: 0!important;

  &__item {
    background: unset;

    @include vp-767 {
      @include adaptiveFull('padding-left', 200, 32, 16);
      @include adaptiveFull('padding-right', 200, 32, 16);
      @include adaptiveFull('margin-left', -200, -32, -16);
      @include adaptiveFull('margin-right', -200, -32, -16);
    }
  }
}
