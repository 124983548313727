@use "mixins" as m;
@import url('https://fonts.cdnfonts.com/css/futura-pt');

:root {
  --black-text-title: #272727;
  --black-surface: #2E2E2E;
  --black-text: #4E4E4E;
  --Main-colors-Grey: #777;
  --Dark-grey-text: #8E8E8E;
  --Divider: #C8C8C8;
  --Bg: #F4F3F1;
  --red: #E66366;
  --yellow: #FB9537;

  --trans-default: all 0.3s linear;
  --font-family: 'Futura PT', sans-serif;
}

html, body {
  scroll-behavior: smooth;
  font-family: var(--font-family);
}

* {
  scroll-snap-align: start;
  scroll-margin-top: 200px;
}

// Reset
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--black-text-title);
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: unset;
  padding: 0;
  border: unset;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  outline: none;
}

button {
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

p {
  margin: 0;
}

// Container
div.main-wrapper {
  .container {
    max-width: 1440px!important;
    margin: 0 auto;
    @include adaptiveFull('padding-left', 200, 32, 16);
    @include adaptiveFull('padding-right', 200, 32, 16);
  }
}

// Titles
.title {
  &-h1 {
    @include adaptiveFull('font-size', 40, 40, 30);
    font-weight: 500;
    line-height: 34px;
    text-transform: uppercase;
    margin: 0;
  }
  &-h2 {
    @include adaptiveFull('font-size', 36, 36, 28);
    font-weight: 500;
    line-height: 34px;
    margin: 0;
  }
  &-h3 {
    font-size: 20px;
    font-weight: 450;
    line-height: 21px;
    margin: 0;
  }
}

// Btn
.btn {
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 10px;
  transition: 0.3s;
  border-radius: 6px;
  border: 1px solid var(--white, #FFF);

  @include hover-focus {
    opacity: 0.9;
    color: inherit;
  }

  &--primary {
    background: #E66366;
    border: unset;

    @include hover-focus {
      color: inherit;
      background: #CA4D4F;
    }
  }

  &__label {
  }

  &__icon {
    margin-left: 7px;
  }
}

// Link
%link {
  display: flex;
  align-self: center;
  transition: .6s cubic-bezier(.37,.31,0,1);

  @include hover-focus {
    color: #E66366!important;
  }
}

%link---with-hover-underline {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E66366;
    transform: scaleX(0) translateZ(0);
    transform-origin: 100% 50%;
    transition: transform .6s cubic-bezier(.37,.31,0,1);
  }

  @include hover-focus {
    &::before {
      transform: scaleX(1) translateZ(0);
      transform-origin: 0 50%;
    }
  }
}

.link {
  display: flex;
  align-self: center;
  transition: .6s cubic-bezier(.37,.31,0,1);

  @include hover-focus {
    color: #E66366!important;
  }

  .link__icon {
    margin-left: 5px;
    font-size: 14px;
  }
}

.link---with-hover-underline {
  .link__label {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #E66366;
      transform: scaleX(0) translateZ(0);
      transform-origin: 100% 50%;
      transition: transform .6s cubic-bezier(.37,.31,0,1);
    }

    @include hover-focus {
      &::before {
        transform: scaleX(1) translateZ(0);
        transform-origin: 0 50%;
      }
    }
  }
}

// scroll-top
.scroll-top {
  background: #E66366;
  transition: 0.3s;
}

// Custom components
.nuxt-icon.nuxt-icon--fill, .nuxt-icon.nuxt-icon--fill * {
  fill: none!important;
}

::v-deep {
  .p-breadcrumb {
    padding-left: 0;
    padding-right: 0;

    .p-icon {
      width: 10px;
      height: 10px;
    }
  }

  .p-breadcrumb li .p-menuitem-link .p-menuitem-text {
    color: var(--Main-colors-Grey, #777);
    font-size: 12.126px;
    line-height: 15.158px;
  }

  .p-breadcrumb ul,
  .p-breadcrumb ol {
    width: fit-content;
  }

  .p-breadcrumb-chevron {
    font-size: 10px;
    margin: 0 5px;
  }

  .p-menuitem-link {
    white-space: nowrap;
    @extend %link---with-hover-underline;

    .p-menuitem-text {
      @extend %link;
    }
  }

  .p-toast {
    max-width: 90vw;
  }

  .p-accordion-toggle-icon {
    background-color: var(--Primary, #E66366);
    border-radius: 50%;
    color: white;
    padding: 4px;
  }

  .p-accordion {
    border-top: 1px solid var(--Divider, #C8C8C8);

    .p-accordion-toggle-icon {
      transition: 0.3s;
    }

    .p-accordion-tab {
      border-color: var(--Divider, #C8C8C8);

      @include hover-focus {
        .p-accordion-toggle-icon {
          transform: rotate(360deg);
        }
      }
    }
  }

  .swiper-scrollbar-horizontal {
    border-radius: 20px;
    background: var(--light-grey-text, #BDBDBD);
  }
  .swiper-scrollbar-drag {
    border-radius: 20px!important;
    background: #E66366!important;
  }

  .p-dialog-title {
    @include adaptiveFull('font-size', 36, 36, 28);
    font-weight: 500;
    line-height: 34px;
  }

  .p-dialog-header {
    padding: 70px 90px 14px;

    @include vp-1279 {
      padding: 70px 60px 14px;
    }

    @include vp-767 {
      padding: 30px 30px 14px;
    }
  }

  .p-dialog-content {
    padding: 0 90px 70px;

    @include vp-1279 {
      padding: 0 60px 70px;
    }

    @include vp-767 {
      padding: 0 30px 30px;
    }
  }

  .p-dialog-header-close {
    .p-icon {
      width: 32px;
      height: 32px;
    }
  }

  .p-dialog-header-close {
    color: #272727!important;
  }

  .svg-icon {
    stroke: unset;
  }

  .svg-icon-wrapper {
    font-size: 20px;
  }

  .p-toast {
    z-index: 10000!important;

    @include vp-767 {
      top: 50%!important;
    }

    .p-toast-message.p-toast-message-success {
      background: rgb(161, 255, 189);
    }
  }

  .pi-star:before {
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00003 0.186951L8.63215 5.2101H13.9138L9.64086 8.31457L11.273 13.3377L7.00003 10.2332L2.72709 13.3377L4.35921 8.31457L0.0862665 5.2101H5.36791L7.00003 0.186951Z' fill='%23BDBDBD'/%3E%3C/svg%3E%0A");
  }

  .pi-star-fill:before {
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.92191 0.186951L8.55403 5.2101H13.8357L9.56273 8.31457L11.1949 13.3377L6.92191 10.2332L2.64897 13.3377L4.28109 8.31457L0.00814152 5.2101H5.28979L6.92191 0.186951Z' fill='%23FB9537'/%3E%3C/svg%3E%0A");
  }
}

.loader {
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  z-index: 99999999;
  background-color: #fff;

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.main-wrapper {
  // padding-top: 80px;

  // @include vp-767 {
  //   padding-top: 100px;
  // }

  // Course page
.course-page {
  background: #F4F3F1;
  // padding-bottom: 80px;

  &__wrapper {
    display: grid;
    grid-template-columns: 2.12fr 1fr;
    gap: 20px;
    padding-top: 14px;

    @include vp-1279 {
      grid-template-columns: 1fr;
    }
  }
}

// Header
.header {
  background: #FBFAF9;
  padding: 10px 0!important;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3001;
  box-shadow: none;
  max-height: none;
  height: auto;
  margin: 0!important;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;

  &.is-active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }

  .container {
    display: flex;
    align-items: center;
  }

  @include vp-1279 {
    .container {
      flex-direction: column;
      gap: 14px;
    }
  }

  @include vp-767 {
    .container {
      align-items: flex-start;
    }
  }

  &__main {
    margin-right: 40px;

    @include vp-1279 {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  &__title {
    margin: 0 0 5px;
  }

  &__menu {
    flex-grow: 1;
    flex-shrink: 0;

    @include vp-1279 {
      width: 100%;
    }
  }

  &__menu-list {
    @include reset-list;

    display: flex;
    align-items: center;
    gap: 6px;

    @include vp-767 {
      display: grid;
      grid-template-columns: repeat(3, auto);
    }
  }

  &__menu-link {
    display: flex;
    align-items: center;
    @include adaptiveFull('gap', 9, 5, 5);
    transition: .6s cubic-bezier(.37,.31,0,1);
    position: relative;
    @include adaptiveFull('font-size', 16, 14, 12);

    @include hover-focus {
      color: #E66366;

      .header__menu-link-label  {
        &::before {
          transform: scaleX(1) translateZ(0);
          transform-origin: 0 50%;
        }
      }
    }

    @include vp-767 {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  &__menu-link-label {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #E66366;
      transform: scaleX(0) translateZ(0);
      transform-origin: 100% 50%;
      transition: transform .6s cubic-bezier(.37,.31,0,1);
    }
  }

  &__menu-link-icon {
    font-size: 20px;
  }
}

// Welcome
.welcome {
  position: relative;
  overflow: hidden;
  border-radius: 17px;
  height: fit-content;

  &__wrapper {
    border-radius: 17px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.46);
    box-shadow: 5px -4px 20px 0px rgba(0, 0, 0, 0.03), -5px 4px 20px 0px rgba(0, 0, 0, 0.03);
    // backdrop-filter: blur(80px);
    padding: 40px;
    @include adaptiveFull('padding-left', 40, 40, 24);
    @include adaptiveFull('padding-right', 40, 40, 24);
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 190px;
    column-gap: 10px;

    @include vp-767 {
      grid-template-columns: 1fr;
    }
  }

  &__link {
    @include adaptiveFull('margin-bottom', 20, 20, 10);
  }

  &__title {
    @include adaptiveFull('margin-bottom', 20, 20, 10);
  }

  &__description {
    font-size: 18px;
    line-height: 23px;
  }

  &__reviews {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  &__reviews-count {
    margin-left: 9px;
    text-decoration: underline;
    @include adaptiveFull('font-size', 15, 15, 14);
  }

  &__more {
    @include vp-767 {
      order: 1;
      margin-top: 16px;
    }
  }

  &__review-card {
    display: block;
    border-radius: 10px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.34);
    backdrop-filter: blur(22.5px);
    padding: 23px 7px 0 20px;
    height: 190px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      border-radius: 10px;
      background: linear-gradient(180deg, rgba(247, 238, 238, 0.00) 0%, #F7EEEE 100%);
    }

    @include vp-767 {
      display: none;
    }
  }

  &__review-card-top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__review-card-photo {
    margin-right: 8px;
  }

  &__review-card-name {
    font-weight: 450;
  }

  &__review-card-rating {
    margin-top: 4px;

    :deep(.p-rating .p-rating-item .p-rating-icon.p-icon) {
      width: 12px;
      height: 12px;
    }
  }

  &__review-card-description {
    color: #4E4E4E;
    font-size: 13px;
    line-height: 18px;
  }

  &__subscribe {
    margin: 0 auto;
    @include adaptiveFull('font-size', 16, 16, 14);
  }

  &__info {
    @include adaptiveFull('margin-top', 60, 60, 24);
    grid-column: span 2;

    @include vp-767 {
      grid-column: auto;
    }
  }

  &__info-list {
    @include reset-list;

    max-width: 550px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    @include adaptiveFull('column-gap', 40, 40, 12);
    row-gap: 8px;

    @include vp-767 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__info-item {
    @include adaptiveFull('padding-top', 10, 10, 5);
    @include adaptiveFull('padding-bottom', 10, 10, 5);
    height: fit-content;

    &--primary {
      @include adaptiveFull('padding-left', 15, 15, 10);
      @include adaptiveFull('padding-right', 15, 15, 10);
      border-radius: 8px;
      background: #FFF;

      text-align: center;
    }
  }

  &__info-item-label {
    color: var(--Grey-777777, #777);
    @include adaptiveFull('font-size', 14, 14, 11);
    font-weight: 450;
    @include adaptiveFull('line-height', 18, 18, 13);
    margin-bottom: 4px;
  }

  &__info-item-value {
    color: var(--black-text, #4E4E4E);
    @include adaptiveFull('font-size', 16, 16, 12);
    font-weight: 600;
    @include adaptiveFull('line-height', 16, 16, 12);
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    filter: blur(80px);

    @include vp-767 {
      width: 150%;
      height: 150%;
      object-position: center;
    }
  }
}

// Course card
.course-card {
  position: sticky;
  top: 75px;
  grid-row: 1 / -1;
  grid-column-start: 2;
  border-radius: 20px;
  background: var(--black-surface, #2E2E2E);
  backdrop-filter: blur(24.450000762939453px);
  padding: 30px;
  color: var(--white, #FFF);
  display: flex;
  flex-direction: column;
  height: fit-content;

  @include vp-1279 {
    position: static;
    grid-row: auto;
    grid-column-start: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 85px;
  }

  @include vp-767 {
    display: block;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp-1279 {
      grid-column: span 2;
      order: 0;
    }
  }

  &__favorite {
    &-icon {
      font-size: 20px;

      .svg-icon {
        fill: none;
      }
    }
    &.is-active {
      .svg-icon {
        fill: currentColor;
      }
    }
  }

  &__price {
    margin-top: 24px;
    margin-bottom: 16px;

    @include vp-1279 {
      order: 1;
    }
  }

  &__price-wrapper {
    display: flex;
    align-items: center;
  }

  &__price-main {
    font-size: 28px;
    font-weight: 700;
    line-height: 16px;
  }

  &__price-without-discount {
    color: var(--Dark-grey-text, #8E8E8E);
    line-height: 120%;
    text-decoration: line-through;
    margin-left: 14px;
  }

  &__price-title {
    color: var(--Dark-grey-text, #8E8E8E);
    line-height: 120%;
    margin-top: 13px;
  }

  &__credit {
    margin-top: 20px;

    @include vp-1279 {
      order: 3;
    }
  }

  &__credit-value {
    font-size: 20px;
    line-height: 16px;
  }

  &__credit-title {
    color: var(--Dark-grey-text, #8E8E8E);
    line-height: 120%;
    margin-top: 8px;
  }

  &__info {
    margin-top: 16px;
    border-radius: 12px;
    background: var(--white, #FFF);
    display: flex;

    @include vp-1279 {
      order: 4;
    }
  }

  &__timer {
    border-radius: 12px;
    border: 1px solid #FFF;
    background: var(--Dark-black-surface, #141414);
    padding: 13px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__timer-title {
    font-weight: 450;
    line-height: 22px;
    margin-bottom: 8px;
    white-space: nowrap;

    span {
      color: var(--Primary, #E66366);
    }
  }

  &__timer-value {
    display: flex;
  }

  &__timer-value-item-value {
    font-size: 24px;
    font-weight: 500;
    line-height: 16px;
  }

  &__timer-value-item-name {
    color: var(--light-grey-text, #BDBDBD);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  &__timer-value-divider {
    margin: 0 4px;
    color: var(--light-grey-text, #BDBDBD);
    font-size: 18px;
    font-weight: 450;
    line-height: 16px;
  }

  &__discount {
    color: var(--black-text-title, #272727);
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }

  &__promocode {
    margin-top: 16px;
    position: relative;
    margin-bottom: 16px;

    @include vp-1279 {
      order: 4;
      justify-self: center;
      margin: 0;
      height: fit-content;
    }

    @include vp-767 {
      margin-top: 16px;
    }
  }

  &__promocode-btn {
    padding: 10px 13px;
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: center;

    @include vp-767 {
      width: 100%;
    }
  }

  &__promocode-btn-label {
    margin: 0 5px;
    text-decoration: underline;
  }

  &__promocode-popup {
    min-width: 280px;
    position: absolute;
    background: white;
    opacity: 0;
    pointer-events: none;
    transition: $trans-default;
    color: rgb(51, 65, 85);

    &::after {
      bottom: 99.9%;
      content: " ";
      height: 0;
      left: 30px;
      pointer-events: none;
      position: absolute;
      width: 0;
      border-width: 8px;
      margin-left: -8px;
      border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #fff;
      border-style: solid;
    }

    &.is-active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__promocode-popup-prices {
    @include reset-list;
    padding: 15px 10px 10px;
  }

  &__promocode-popup-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 450;


    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &--primary {
      margin-top: 10px;
      color: #E66366;
      font-weight: 500;
    }
  }

  &__promocode-popup-price-value {
    white-space: nowrap;
    margin-left: 15px;
  }

  &__promocode-popup-footer {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid var(--white, #FFF);
    background: #E66366;
    padding: 10px;
    color: var(--white, #FFF);
  }

  &__promocode-popup-description {
    font-size: 15px;
  }

  &__promocode-popup-btns {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  &__promocode-popup-btn {
    border-radius: 10px;
    font-weight: 450;
    padding: 10px;
    background: var(--black-text-title, #272727);
    color: var(--white, #FFF);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;

    &--white {
      background: var(--white, #FFF);
      color: var(--black-text-title, #272727);
    }

    @include hover-focus {
      opacity: 0.9;
    }
  }

  &__footer {
    margin-top: auto;

    @include vp-1279 {
      order: 2;
      grid-row: span 2;
    }
  }

  &__footer-btn {
    width: 100%;
    color: #FFF!important;
    margin-top: 8px;
  }
}
:deep(.course-card__promocode-overlaypanel) {
  top: unset!important;
  bottom: 0;
  left: 0!important;
  transform: translateY(100%);

  .p-overlaypanel-content {
    border-radius: 10px;
    backdrop-filter: blur(17px);
    padding: 0;
  }
}

// Section
.section {
  @include adaptiveFull('padding-top', 40, 40, 28);
  @include adaptiveFull('padding-bottom', 40, 40, 28);
  @include adaptiveFull('padding-right', 37, 37, 24);
  @include adaptiveFull('padding-left', 37, 37, 24);
  border-radius: 20px;
  border: 2px solid var(--white, #FFF);
  background: var(--white, #FFF);
  grid-column-end: 2;
  max-width: 680px;
  scroll-margin-top: 50px;

  &.is-active {
    .section__description {
    }

    .section__more {
      span {
        &:last-child {
          display: inline;
        }

        &:first-child {
          display: none;
        }
      }
    }

    .section__list-item {
      @include vp-767 {
        &:nth-child(n+15) {
          display: flex;
        }
      }
    }

    .section__accordion {
      :deep(.p-accordion-tab) {
        &:nth-child(n + 6) {
          display: block;
        }
      }
    }
  }

  .only-mobile {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  &__wrapper {
    @include adaptiveFull('--padding', 200, 32, 16);
    @include adaptiveFull('--padding-section', 37, 37, 24);
    max-width: calc(99vw - var(--padding) * 2 - var(--padding-section) * 2);
    overflow: hidden;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__subtitle {
    margin-bottom: 25px;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 120%;
    color: var(--black-text, #4E4E4E);
    transition: 0.3s ease;
  }

  &__more {
    color: var(--Primary, #E66366);
    font-weight: 450;
    margin-top: 10px;

    span {
      &:last-child {
        display: none;
      }
    }
  }

  &__list {
    @include reset-list;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include adaptiveFull('gap', 15, 15, 12);

    @include vp-767 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__list-item {
    display: flex;
    align-items: center;

    @include vp-767 {
      &:nth-child(n+15) {
        display: none;
      }
    }
  }

  &__list-item-icon {
    margin-right: 8px;
  }

  &__list-item-label {
    font-weight: 450;
  }

  &__accordion {
    margin: 32px 0 16px;

    :deep(.p-accordion-tab) {
      &:nth-child(n + 6) {
        display: none;
      }
    }
  }

  &__card {
    margin: 28px 0 20px;
    display: grid;
    grid-template-columns: 95px auto;
    @include adaptiveFull('gap', 24, 24, 20);
  }

  &__card-logo-wrapper {
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(244, 204, 218, 0.80) 0%, rgba(243, 200, 191, 0.80) 100%);
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 75%;
      object-fit: contain;
    }
  }

  &__card-title {
    margin: 0 0 6px;
    font-weight: 500;
    @include adaptiveFull('font-size', 28, 24, 24);
    line-height: 21px;
  }

  &__card-rating {
    border-bottom: 1px solid var(--Divider, #C8C8C8);
    width: fit-content;

    :deep(.review-rating__value) {
      font-size: 20px;
      font-weight: 450;
    }
  }

  &__card-info {
    margin-top: 7px;
    display: flex;
    gap: 16px;
    color: var(--Grey-777777, #777);

    @include vp-767 {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__card-info-item {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__slider {
    max-width: 612px;
    margin-top: 32px;
    margin-left: 0;

    @include vp-767 {
      max-width: 87.5vw;
    }
  }

  &__slide-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    overflow: hidden;
  }
}

// Teachers
.teachers {
  grid-column-end: 2;
  margin: 20px 0;
  scroll-margin-top: 80px;

  @include vp-767 {
    overflow: hidden;
    @include adaptiveFull('margin-left', -200, -32, -16);
    @include adaptiveFull('margin-right', -200, -32, -16);
  }

  &__title {
    margin-bottom: 32px;

    @include vp-767 {
      @include adaptiveFull('padding-left', 200, 32, 16);
      @include adaptiveFull('padding-right', 200, 32, 16);
    }
  }

  &__slider {
    width: 725px;
    height: 690px;
    padding: 20px;
    margin: -20px;

    &--small {
      height: 345px;

      .teachers__slide {
        height: 100%!important;
      }
    }

    @include vp-767 {
      width: 87.5vw;
      height: auto;
      padding: 0;
      margin: 0;

      @include adaptiveFull('padding-left', 200, 32, 16);
      @include adaptiveFull('padding-right', 200, 32, 16);
      overflow: visible;
    }
  }

  &__slide {
    padding: 35px;
    height: calc((100% - 20px) / 2) !important;
    transition: 0.3s;
    overflow: hidden;

    &:nth-child(4n + 1) {
      border-radius: 10px 10px 105px 10px;
      background: var(--Orange, #FFD3BA);
    }

    &:nth-child(4n + 3) {
      border-radius: 10px 10px 10px 105px;
      background: var(--black-text-title, #272727);
      color: var(--light-grey-text, #BDBDBD);
    }

    &:nth-child(4n + 2) {
      border-radius: 10px 105px 10px 10px;
      background: var(--white, #FFF);
    }

    &:nth-child(4n + 4) {
      border-radius: 105px 10px 10px 10px;
      background: var(--Pink, #FFBABC);
    }

    @include hover-focus {
      transform: scale(1.05);
    }

    @include vp-767 {
      height: auto!important;
    }
  }

  &__slide-top {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__slide-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__slide-name {
    width: min-content;
    margin-bottom: 8px;
  }

  &__slide-description {
    margin-top: 16px;
    line-height: 120%;
    max-height: 155px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #c8c8c844;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--Divider, #C8C8C8);
    }
  }

  &__slider-bottom {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp-767 {
      @include adaptiveFull('padding-left', 200, 32, 16);
      @include adaptiveFull('padding-right', 200, 32, 16);
    }
  }

  &__slider-navigation {
    display: flex;
    align-items: center;
    gap: 16px;

    :deep(.swiper-button-disabled) {
      opacity: 0.5;
    }
  }

  &__slider-button {
    :deep(.pi) {
      font-size: 36px;
    }
  }

  &__slider-scrollbar {
    width: 450px;
    height: 5px;

    @include vp-767 {
      display: none;
    }
  }

  &__slider-counter {
    display: none;

    @include vp-767 {
      display: block;
      color: var(--black-text, #4E4E4E);
      font-size: 20px;
    }
  }
}

// Reviews
.reviews {
  grid-column-end: 2;
  scroll-margin-top: 80px;

  &--loading {
    .reviews__wrapper {
      pointer-events: none;
    }

    .reviews__list {
      opacity: 0.5;
    }
    .reviews__actions {
      opacity: 0.5;
    }
  }
}
.reviews__wrapper {
  position: relative;
}
.reviews__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.reviews__title {
  margin-bottom: 32px;
}
.reviews__main {
  border-radius: 16px;
  background: #FFF;
  @include adaptiveFull('padding-top', 32, 32, 28);
  @include adaptiveFull('padding-bottom', 32, 32, 28);
  @include adaptiveFull('padding-left', 40, 40, 24);
  @include adaptiveFull('padding-right', 40, 40, 24);
  display: flex;
  gap: 30px;

  @include vp-767 {
    flex-direction: column;
  }
}
.reviews__main-total {
}
.reviews__main-rating {
  @include vp-767 {
    width: fit-content;
  }
}
.reviews__main-total-reviews {
  color: var(--Grey-777777, #777);
  font-size: 14px;
  font-weight: 450;
  margin-top: 7px;
}
.reviews__grades {
  flex-grow: 1;
}
.reviews__grade {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
.reviews__grade-bar {
  flex-grow: 1;
  height: 6px;
  background: #E8E8E8;
  margin-right: 24px;

  :deep(.p-progressbar-value) {
    background: #A5A5A5;
  }

  @include vp-767 {
    margin-right: 20px;
  }
}
.reviews__grade-rating {
}
.reviews__grade-counter {
  min-width: 86px;
  margin-left: 10px;
  color: #676767;
  font-size: 14px;

  @include vp-767 {
    font-size: 11px;
    min-width: 53px;
  }
}
.reviews__list {
  margin-top: 40px;
}
.reviews__list-tabmenu {
  display: flex;

  :deep(.p-tabmenu-ink-bar) {
    background: #E66366;
    height: 4px;
  }
}
.reviews__list-tabmenu-item {
  background: unset;
  font-size: 20px;
  font-weight: 450;
  padding: 6px 0;
  padding-right: 24px;
  display: flex;
  border-bottom: 4px solid #C5C4CA;

  &.is-active {
    border-bottom: 4px solid #E66366;
  }
}
.reviews__list-tabmenu-item-label {
}
.reviews__list-tabmenu-item-counter {
  color: #E66366;
  font-size: 13px;
  font-weight: 450;
  margin-left: 4px
}
.reviews__list-sort {
  margin-top: 16px;
  display: flex;
  align-items: center;

  :deep(.p-tabmenu-nav) {
    background: unset;
    border: unset;
  }

  :deep(.p-tabmenu-ink-bar) {
    display: none;
  }
}
.reviews__list-sort-title {
  font-size: 18px;
  font-weight: 450;
  margin-right: 16px;

  @include vp-767 {
    font-size: 14px;
  }
}
.reviews__list-sort-tabmenu {

}
.reviews__list-sort-tabmenu-item {
  border-radius: 8px;
  background: unset;
  border: unset;
  min-height: 34px;
  padding: 5px 14px;
  font-size: 16px;
  font-weight: 400;
  color: var(--Grey-777777, #777);

  &.is-active {
    background: #E66366;
    color: white;
  }

  @include vp-767 {
    font-size: 14px;
  }
}
.reviews__list-sort-tabmenu-item-label {
  @include vp-767 {
    white-space: wrap;
  }
}
.reviews__list-items {
  margin-top: 24px;
}
.reviews__list-item {
  padding: 28px 25px;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: var(--white, #FFF);
  display: grid;
  grid-template-columns: min-content auto;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.is-active {
    .reviews__list-item-text  {
      -webkit-line-clamp: unset;
    }

    .reviews__list-item-text-more {
      span {
        &:last-child {
          display: inline;
        }

        &:first-child {
          display: none;
        }
      }
    }
  }

  @include vp-767 {
    grid-template-columns: 1fr;
  }
}
.reviews__list-item-main {
  width: max-content;
  flex-shrink: 0;
  margin-right: 12px;

  @include vp-767 {
    margin-bottom: 16px;
  }
}
.reviews__list-item-name {
  margin-bottom: 8px;
  min-width: 115px;
}
.reviews__list-item-rating {
}
.reviews__list-item-date {
  color: var(--Grey-777777, #777);
  font-size: 14px;
  margin-top: 16px;
}
.reviews__list-item-text-wrapper {
}
.reviews__list-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 120%;
  color: var(--black-text, #4E4E4E);
}
.reviews__list-item-text-more {
  color: var(--Primary, #E66366);
  font-weight: 450;
  margin-top: 10px;

  span {
    &:last-child {
      display: none;
    }
  }
}
.reviews__list-item-footer {
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  @include vp-767 {
    grid-column: auto;
  }
}
.reviews__list-item-link {
  color: var(--Grey-777777, #777);
  font-weight: 450;
}
.reviews__list-item-like-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.reviews__list-item-like-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.reviews__list-item-like-btn-icon {
  font-size: 16px;
}
.reviews__list-item-like-btn-counter {
  color: var(--black-text, #4E4E4E);
  font-size: 15px;
  font-weight: 450;
}
.reviews__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;
}
.reviews__actions-btn {
  border-color: #E66366;
  color: #E66366;
  min-width: 180px;

  @include hover-focus {
    color: #E66366;
  }

  &.btn--primary {
    color: white;

    @include hover-focus {
      color: white;
    }
  }

  @include vp-767 {
    min-width: unset;
    flex-grow: 1;
  }
}
.reviews__last-refresh {
  text-align: center;
  margin: 20px 0 0;
  color: var(--Grey-777777, #777);
  font-size: 14px;

  span {
    color: var(--black-text, #4E4E4E);
    font-size: 16px;
    font-weight: 450;
  }
}

// Courses more
.courses-more {
  display: block;
  grid-column-end: 2;
  margin: 0 0 20px;
  color: inherit;
  text-decoration: unset;

  @include vp-767 {
    overflow: hidden;
    @include adaptiveFull('margin-left', -200, -32, -16);
    @include adaptiveFull('margin-right', -200, -32, -16);
    @include adaptiveFull('padding-left', 200, 32, 16);
    @include adaptiveFull('padding-right', 200, 32, 16);
  }
}
.courses-more__title {
  margin-bottom: 30px;
  text-align: left;
}
.courses-more__slider {
  max-width: 682px;
  margin: 0;

  @include vp-767 {
    width: 87.5vw;
    height: auto;

    overflow: visible;
  }
}
.course__slide {
  border-radius: 18.243px;
  background: #FFF;
  height: auto;
  display: flex;
  flex-direction: column;
}
.course__slide-logo-wrapper {
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(180deg, #F4CCDA 0%, #F3C8BF 100%);
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.course__slide-logo {
  width: 100px;
}
.course__slide-main {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.course__slide-title {
  margin-bottom: 6px;
}
.course__slide-rating-reviews {
  display: flex;
  border-bottom: 1px solid var(--Divider, #C8C8C8);
}
.course__slide-reviews-rating {
  margin-right: 9px;
}
.course__slide-rating-reviews-counter {
  color: var(--black-text, #4E4E4E);
  font-size: 14px;
}
.course__slide-info {
  margin-top: 6px;
  margin-bottom: 14px;
}
.course__slide-info-item {
  display: flex;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
.course__slide-info-item-name {
  color: var(--Grey-777777, #777);
}
.course__slide-info-item-value {
  color: var(--Gray-1, #333);
  font-weight: 450;
  margin-left: 6px;
}
.course__slide-btn {
  border-radius: 6px;
  border: 1px solid #E66366;
  color: #E66366;
  margin-top: auto;
}
.courses-more__slider-bottom {
}
.courses-more__slider-navigation {
}
.courses-more__slider-button {
}
.courses-more__slider-button--prev {
}
.courses-more__slider-button--next {
}
.courses-more__slider-scrollbar {
}
.courses-more__slider-counter {
}
.courses-more {
  &__slider-bottom {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp-767 {
      @include adaptiveFull('padding-left', 200, 32, 16);
      @include adaptiveFull('padding-right', 200, 32, 16);
    }
  }

  &__slider-navigation {
    display: flex;
    align-items: center;
    gap: 16px;

    :deep(.swiper-button-disabled) {
      opacity: 0.5;
    }
  }

  &__slider-button {
    :deep(.pi) {
      font-size: 36px;
    }
  }

  &__slider-scrollbar {
    width: 450px;
    height: 5px;

    @include vp-767 {
      display: none;
    }
  }

  &__slider-counter {
    display: none;

    @include vp-767 {
      display: block;
      color: var(--black-text, #4E4E4E);
      font-size: 20px;
    }
  }
}

// Review form
.review-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 15px;
  width: 850px;
  max-width: 70vw;

  * {
    grid-column: span 2;
  }
}
.review-form__modal {

}
.review-form__title {
  margin: 0 0 14px;
}
.review-form__descrition {
  color: var(--Grey-777777, #777);
  font-size: 14px;
}
.review-form__input {
  &--sm {
    grid-column: span 1;

    @include vp-767 {
      grid-column: span 2;
    }
  }
}
.review-form__label {
  font-weight: 450;
}
.review-form__rating {
  display: inline-block;
  margin-left: 16px;
}
.review-form__textarea {
  display: block;
  margin-top: 10px;
  width: 100%;
}
.review-form__input-main {
  display: block;
  width: 100%;
}
.review-form__submit {
  width: 182px;
  color: white;
  margin: 15px auto 0;

  @include hover-focus {
    color: white;
  }
}

.section__accordion {
}
.section__accordion-element {
  border-top: 1px solid var(--Divider, #C8C8C8);
  padding: 14px 26px;

  &:nth-child(n + 6) {
    display: none;
  }

  .section.is-active & {
    &:nth-child(n + 6) {
      display: block;
    }
  }
}
.section__accordion-element-btn {
  color: var(--black-text-title, #272727);
  font-size: 20px;
  font-weight: 450;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &::after {
    content: url(../img/svg/plus.svg);
    transition: $trans-default;
    width: 24px;
    height: 24px;
  }

  .section__accordion-element.is-active & {
    &::after {
      transform: rotate(180deg);
      content: url(../img/svg/minus.svg);
    }
  }
}
.section__accordion-element-content {
  color: var(--black-text, #4E4E4E);
  font-size: 16px;
  line-height: 120%;

  &::before {
    content: '';
    display: block;
    height: 20px;
  }
}

.swiper-scrollbar-drag {
  background: var(--red, #e66366) !important;
  border-radius: 20px !important;
}
.teachers__slider-scrollbar {
  background: var(--light-grey-text, #bdbdbd);
  border-radius: 20px;
}
.pi-arrow-left {
  &::before {
    display: block;
    content: url('../img/sprite/arrow.svg');
    transform: rotate(180deg);
    height: 48px;
  }
}
.pi-arrow-right {
  &::before {
    display: block;
    content: url('../img/sprite/arrow.svg');
    height: 48px;
  }
}

progress {
  height: 6px;
  width: 100%;
  border-radius: 19px;
  background: #E8E8E8;
}

progress::-moz-progress-bar {
  border: none;
  border-radius: 19px;
  background: #E8E8E8;
}

progress::-webkit-progress-bar {
  border: none;
  border-radius: 19px;
  background: #E8E8E8;
}

progress::-webkit-progress-value {
  border-radius: 19px;
  background: #A5A5A5;
}

@include vp-767 {
  .welcome,
  .section,
  .course-card {
    max-width: 92dvw;
    overflow: hidden;
  }
}

}

