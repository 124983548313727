.header__menu-link-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--svg-icon-color);
}
.svg-icon {
  overflow: hidden;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  fill: currentColor;
  stroke: inherit;
  fill: none;
}
