.rating {
	// width: 265px;
  display: flex;
  align-items: center;

  &--1 {
    span:nth-child(-n+1) {
      color: var(--Yellow, #FB9537);
    }
  }
  &--2 {
    span:nth-child(-n+2) {
      color: var(--Yellow, #FB9537);
    }
  }
  &--3 {
    span:nth-child(-n+3) {
      color: var(--Yellow, #FB9537);
    }
  }
  &--4 {
    span:nth-child(-n+4) {
      color: var(--Yellow, #FB9537);
    }
  }
  &--5 {
    span:nth-child(-n+5) {
      color: var(--Yellow, #FB9537);
    }
  }

  &--large {
    flex-wrap: wrap;

    .rating__value {
      min-width: 100%;
      margin: 0;
      font-weight: 500;
      @include adaptiveFull('font-size', 51, 51, 50);
      line-height: 1.3;
    }
  }
}
.rating span {
	padding: 0;
	font-size: 14px;
	margin: 0 1px;
	line-height: 1;
	color: lightgrey;
}
.rating > span:before {
	content: '★';
}
.rating__value {
  @include adaptiveFull('font-size', 15, 15, 14);
  margin-right: 8px;
}
